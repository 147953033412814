import React, { useEffect, useState } from "react";
import NoContentCard from "../../../atoms/NoContentCard";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import moment from "moment";
import { getData, postData } from "../../../../Services";
import { useCookies } from "react-cookie";
import { Heading } from "../../../atoms/Heading";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import { expitationTime } from "../../../../helpers/expirationTime";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import "./styles.scss";

const Invites = () => {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [cookies, setCookie] = useCookies(["t", "bid", "iso"]);
  const screenWidth = useScreenWidth();

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await getData({
        endpoint: "businessDashboard/getAllInvites",
        params: { email: atob(cookies.email) },
        token: cookies.t,
      });
      const data = response?.data;
      if (data) {
        setTableData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAccept = async (data) => {
    const response = await postData({
      endpoint: "businessAuth/inviteAcceptation",
      data: {},
      params: {
        user_id: data?._id,
        mode: data?.type,
        business_user_id: data?.business_id,
        status: "Active",
        expiry: data?.expiry ? data?.expiry : null,
        token: data?.token,
      },
    });
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.statusCode === 200
    ) {
      toast.success("Invite Accepted Successfully", {
        style: confimationStyles,
        duration: 1000,
      });

      if (response?.data?.data?.data?.employee_id) {
        setCookie("eid", response?.data?.data?.data?.employee_id, {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
      }
      if (response?.data?.data?.data?.client_id) {
        setCookie("cid", response?.data?.data?.data?.client_id, {
          path: "/",
          expires: expitationTime(),
          // secure: true,
        });
      }
      window.location.reload();
      fetchData();
    } else {
      toast.error("Something Went Wrong,Try Again Later", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const handleReject = async (data) => {
    const response = await postData({
      endpoint: "businessAuth/inviteAcceptation",
      data: {},
      params: {
        user_id: data?._id,
        mode: data?.type,
        business_user_id: data?.business_id,
        status: "Rejected",
        expiry: data?.expiry
          ? new Date(data?.expiry).toISOString().replace("Z", "+00:00")
          : null,
        token: data?.token,
      },
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.statusCode === 200
    ) {
      toast.success("Invite Accepted Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
      fetchData();
    } else {
      toast.error("Something Went Wrong,Try Again Later", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const columns = [
    {
      field: "label",
      headerName: "Business Name",
      flex: 1,
      sortable: true,
      renderCell: (params) => <span>{params.row.label || "Unknown"}</span>,
    },
    {
      field: "type",
      headerName: "User Type",
      flex: 1,
      sortable: true,
      renderCell: (params) => <span>{params.row.type}</span>,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      sortable: true,
      renderCell: (params) => (
        <span>{moment(params.row.createdAt).format("YYYY-MM-DD")}</span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <div className=" h-100 d-flex gap-3 align-items-center">
          <button
            className="btn btn-success"
            onClick={() => handleAccept(params?.row)}
          >
            Accept
          </button>
          <button
            className="btn btn-danger"
            onClick={() => handleReject(params?.row)}
          >
            Reject
          </button>
        </div>
      ),
    },
  ];

  const CustomNoRowsOverlay = React.memo(() => {
    return loader ? (
      <div
        className="w-100 d-flex align-items-center justify-content-center"
        style={{ height: "70vh" }}
      >
        <span
          className="spinner-border spinner-border-lr ms-2"
          style={{ color: "#3b424a", width: "50px", height: "50px" }}
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    ) : (
      <NoContentCard title="No Invites Found" />
    );
  });

  return (
    <div>
      <Heading className="h5 mt-1 lh-1 mb-0">My Invites</Heading>
      {screenWidth > 900 ? (
        <CustomDatagrid
          getRowId={(row) => row?.business_id + row?.type + row?.createdAt}
          rows={tableData || []}
          columns={columns}
          // tHeight="50vh"
          loader={loader}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
        />
      ) : tableData && tableData?.length > 0 ? (
        <CardView
          data={tableData || []}
          onAccept={handleAccept}
          onReject={handleReject}
        />
      ) : (
        <CustomNoRowsOverlay />
      )}
    </div>
  );
};

const CardView = ({ data, onAccept, onReject }) => {
  return (
    <div className="card-container-in mt-3">
      {data.map((item) => (
        <div
          className="card-in"
          key={item.business_id + item.type + item.createdAt}
        >
          <p>
            <strong>Business Name:</strong> {item.label || "Unknown"}
          </p>
          <p>
            <strong>User Type:</strong> {`${item.type} User`}
          </p>
          <p>
            <strong>Created At:</strong>{" "}
            {moment(item.createdAt).format("YYYY-MM-DD")}
          </p>
          <div className="card-actions-in">
            <button className="btn btn-success" onClick={() => onAccept(item)}>
              Accept
            </button>
            <button className="btn btn-danger" onClick={() => onReject(item)}>
              Reject
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Invites;
