import React, { useEffect, useMemo, useState } from "react";
import Tabstrip from "../../../../atoms/tabstrip";
import { Box } from "@mui/material";
import { Heading } from "../../../../atoms/Heading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EmployeesTable from ".";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../Services";
import Search from "../../../../atoms/Search";
import CustomButton from "../../../../atoms/CustomButton";
import Upload from "../../../../icons/Upload";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import toast from "react-hot-toast";
import { downloadReport } from "../../../../../Services/downloadService";
import { useSelector } from "react-redux";
import CustomDateRangePicker from "../../../../atoms/DateRangePicker";

const ViewEmployeeTabs = () => {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["Employee Invoices"];
  const [activeTab, setActiveTab] = useState("");
  const { id, isDraft } = useParams();
  const [count, setCount] = useState();
  const [searchData, setSearchData] = useState();

  const [cookies] = useCookies(["t", "bid", "iso"]);
  const [filters, setFilters] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [date, setDate] = useState([null, null]);
  const isOwner = cookies.iso;

  const getCountsTabs = async () => {
    try {
      const res = await getData({
        endpoint: "bills/countStatus",
        token: cookies.t,
        params: {
          type: "Employee",
          search: searchData,
          business_id: cookies.bid,
          categories: filters?.["Category"],
          start_date: date?.start_date?.replace(".000", ""),
          end_date: date?.end_date?.replace(".000", ""),
        },
      });
      setCount(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (state && state.customState) {
      if (state.customState.type) {
        setActiveTab(state.customState.type);
      }
      if (state.customState.filters) {
        setFilters(state.customState.filters);
      }
      if (state.customState.date) {
        setDate(state.customState.date);
      }
    } else setActiveTab("New/Pending");
    getCountsTabs();
  }, []);

  useEffect(() => {
    const getCategoryList = async () => {
      const res = await getData({
        endpoint: "category/getCategoryList",
        token: cookies.t,
        params: { business_id: cookies.bid },
      });
      setCategoryData(res);
    };
    getCategoryList();
  }, []);

  const data = useMemo(
    () => [
      {
        id: 1,
        label: "New/Pending",
        badge: count?.["New/Pending"] || 0,
        view: (
          <EmployeesTable
            type="New/Pending"
            searchData={searchData}
            getCountsTabs={getCountsTabs}
            filters={filters}
            date={date}
          />
        ),
      },
      {
        id: 2,
        label: "Booked",
        badge: count?.Booked || 0,
        view: (
          <EmployeesTable
            type="Booked"
            searchData={searchData}
            getCountsTabs={getCountsTabs}
            filters={filters}
            date={date}
          />
        ),
      },
      {
        id: 3,
        label: "Raised Queries",
        badge: count?.["Query Raised"] || 0,
        view: (
          <>
            <EmployeesTable
              type="Query Raised"
              searchData={searchData}
              getCountsTabs={getCountsTabs}
              filters={filters}
              date={date}
            />
          </>
        ),
      },
      {
        id: 4,
        label: "Rejected",
        badge: count?.Rejected || 0,
        view: (
          <>
            <EmployeesTable
              type="Rejected"
              searchData={searchData}
              getCountsTabs={getCountsTabs}
              filters={filters}
              date={date}
            />
          </>
        ),
      },
    ],
    [searchData, count, filters, activeTab, date]
  );

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };
  const handleAllowId = () => {
    if (location?.state?.edit === "Client") {
      return 1;
    } else if (location?.state?.edit === "Supplier") {
      return 2;
    } else if (localStorage?.state?.edit === "Obligor") {
      return 3;
    } else {
      return 4;
    }
  };
  const filterData = {
    Category: categoryData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "bills/exportCbills",
      token: cookies.t,
      fileName: "Employee Bills",
      params: {
        type: activeTab === "Raised Queries" ? "Query Raised" : activeTab,
        search: searchData,
        business_id: cookies.bid,
        mode: "Invoice",
        categories: filters?.["Category"],
      },
    });

    if (res) {
      toast.success("Employee Bills Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingStyle">Employee Invoices</Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />
          <CustomDateRangePicker setDate={setDate} date={date} />
          {((roleData && roleData?.export) || isOwner) && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}

          <FilterComponent
            filterData={filterData}
            selectedFilters={filters}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
            mode="Invoice"
          />
        </div>
      </div>
      <Tabstrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={data}
        allowedTabId={location?.state?.edit ? handleAllowId : ""}
        areTabsDisabled={location?.state?.edit ? true : false}
        count={count}
      />
      <Box sx={{ width: "100%" }}>
        <Box>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};

export default ViewEmployeeTabs;
