import { Outlet } from "react-router-dom";
import Sidebar from "../../molecules/Sidebar";
import styles from "./style.module.scss";
import { useState } from "react";
import { dashboardRoutes } from "../../../routes/routes";
import Navbar from "../../molecules/navbar";
import useScreenWidth from "../../../hooks/useScreenwidth";
import { useCookies } from "react-cookie";

const DashboardLayout = () => {
  const [isActive, setIsActive] = useState(true);
  const [cookie, setCookie] = useCookies();
  const screenWidth = useScreenWidth();
  return (
    <>
      {screenWidth < 1100 &&
      cookie?.mode &&
      atob(cookie?.mode) === "Business" ? (
        <>
          <Navbar mode={"Employee"} />
          <div className={styles.screen_not_supported}>
            Business Mode was not supported in Mobile and Desktop, Use Desktop
            Mode to continue
          </div>
        </>
      ) : (
        <>
          {screenWidth > 900 ? (
            <div className={styles.dashboard_container}>
              <Sidebar isActive={isActive} setIsActive={setIsActive} />
              <div className={styles.main_content_container}>
                <div className={styles.content}>
                  <Outlet />
                  {dashboardRoutes}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.dashboard_container}>
              <Navbar mode={"Employee"} />
              <div className={styles.main_content_container}>
                <div className={styles.content}>
                  <Outlet />
                  {dashboardRoutes}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default DashboardLayout;
