import { memo, useCallback, useEffect, useState } from "react";
import { Heading } from "../../../../atoms/Heading";
import Search from "../../../../atoms/Search";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
// import NoContentCard from "../../../../atoms/NoContentCard";
import { deleteData, getData, patchData } from "../../../../../Services";
import View from "../../../../icons/View";
import EditBlue from "../../../../icons/EditBlue";
import CustomButton from "../../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../../atoms/Pagination";
import Delete from "../../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Modal from "../../../../atoms/modals/DeleteModal";
import Edit from "../../../../icons/EditBlue";
import CustomMenu from "../../../../molecules/CustomMenu";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
// import { Rate_TypeData } from "../../../../../../utils/staticData";
import EyeIcon from "../../../../icons/eye.icon";
import Upload from "../../../../icons/Upload";
import { downloadReport } from "../../../../../Services/downloadService";
import moment from "moment";
import NoContentCard from "../../../../atoms/NoContentCard";
import { useSelector } from "react-redux";
import NormalModal from "../../../../atoms/modals/Modal";
import MyInput from "../../../../atoms/MyInput";
import NormalMyInput from "../../../../atoms/NormalTextInput/normalInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import {
  invoiceStatus,
  statusInvites,
} from "../../../../../helpers/staticData";

const EmployeesTable = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["t", "bid", "ibu", "mode", "iso"]);
  const isOwner = cookies.iso;
  const [employeeData, setEmployeeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["Employee Invoices"];
  const [loader, setLoader] = useState(true);

  const GetemployeeData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "bills",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,

          business_id: cookies.bid,
          emp_type: "Employee",
          mode: "Invoice",
        },
      });
      setEmployeeData(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    GetemployeeData();
  }, [itemsPerPage, currentPage]);

  const columns = [
    {
      field: "created",
      headerName: "Employee User",
      flex: 1.2,
      renderCell: (params) => {
        return (
          <>
            {params?.row.clientData && (
              <div>{params.row.clientData?.[0]?.name}</div>
            )}
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Category",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.categoryData && (
              <div>{params?.row?.categoryData?.category}</div>
            )}
          </>
        );
      },
    },
    {
      field: "type",
      headerName: "Sub Category",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.type && (
              <div>{params?.row?.type !== "null" ? params?.row?.type : ""}</div>
            )}
          </>
        );
      },
    },
    {
      field: "currecy",
      headerName: "Currency",
      flex: 0.7,
      renderCell: (params) => {
        return <>{params?.row?.currency && <div>{params.row.currency}</div>}</>;
      },
    },
    {
      field: "price",
      headerName: "Price",
      flex: 0.7,
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
  ];

  // const getMenuList = () => {
  //   if (roleData && roleData?.["add/edit"] && roleData?.delete) {
  //     return menu;
  //   } else if (roleData && roleData?.delete) {
  //     return [menu[1]];
  //   } else if (roleData && roleData?.["add/edit"]) {
  //     return [menu[0]];
  //   } else return [];
  // };

  const CustomNoRowsOverlay = memo(() => {
    return loader ? (
      <>
        {" "}
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#3b424a", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </>
    ) : (
      <>
        <NoContentCard
          title="No Invoice added yet!"
          tag="Add Bill"
          type="Pricing"
          classes="d-flex align-items-center"
          // subtitle="Currently no invoice created yet click on a add bill button to create bill"
        />
      </>
    );
  });

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    // "Rate Type": Rate_TypeData,
  };

  return loader ? (
    <div
      className="w-100  d-flex align-items-center justify-content-center"
      style={{ height: "70vh" }}
    >
      <span
        className="spinner-border spinner-border-lr ms-2"
        style={{ color: "#3b424a", width: "40px", height: "40px" }}
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  ) : (
    <>
      <CustomDatagrid
        rows={employeeData?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        // height={() => 20}
        tHeight="350px"
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        // className={style.custom_grid_styles}
        onCellClick={(data) => {
          if (
            data?.field !== "actions" &&
            data?.field !== "__check__" &&
            ((roleData && roleData?.["view"]) || isOwner)
          ) {
            navigate(
              `/dashboard/employeeInvoice/viewInvoice/Employee/${data?.id}`
              //   {
              //     state: { filters: filters, date: date },
              //   }
            );
          }
        }}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={employeeData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
        options={["5", "10", "15"]}
      />
    </>
  );
};
export default EmployeesTable;
