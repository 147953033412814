import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useParams } from "react-router-dom";
import { getData, patchData } from "../../../../../Services";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
// import Breadcrumb from "../../../../molecules/Breadcrumb";
import moment from "moment";
import Breadcrumb from "../../../../atoms/Breadcrumb";
import { getExtension } from "../../../../../Services/getExtensionOfLink";
import { date } from "yup";
import MyDropdown from "../../../../atoms/MyDropdown";
import NormalMyInput from "../../../../atoms/NormalTextInput/normalInput";
import { invoiceStatus } from "../../../../../helpers/staticData";
import CustomButton from "../../../../atoms/CustomButton";
import NormalModal from "../../../../atoms/modals/Modal";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/components/toast";
import { decodeCookieValue } from "../../../../../helpers/cookieHelper";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

export const LabelDisplay = ({
  label,
  value,
  className,
  modalStyles = false,
  isDropdown,
  data,
}) => {
  const { id, type } = useParams();
  const [statusModal, setStatusModal] = useState({
    isActive: false,
    remark: data?.remark,
    reason: data?.reason,
    type: data?.status,
  });
  const [cookies, setCookie] = useCookies(["t", "bid", "ibu", "mode", "iso"]);
  const handleSubmit = async () => {
    try {
      const res = await patchData({
        endpoint: `${type === "Employee" ? "bills" : "cbills"}/normalupdate`,
        token: cookies.t,
        data: {
          status: statusModal.type,
          remark: statusModal.remark,
          reason: statusModal.reason,
          ...(type === "Client"
            ? {
                audit_name:
                  capitalizeLetter(decodeCookieValue(cookies.un)) || "",
              }
            : {}),
          ...(type === "Client" ? { audit_date: new Date() } : {}),
        },
        params: {
          id: data?._id,
        },
      });

      if (res) {
        toast.success("Status Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        setStatusModal({
          ...statusModal,
          isActive: false,
          // remark: "",
          // reason: "",
          // type: "",
        });
        window.location.reload();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }
    } catch (error) {}
  };
  return (
    <>
      {isDropdown !== "Dropdown" ? (
        <div
          className={`d-flex ${className}`}
          style={{ width: modalStyles ? "90%" : "100%" }}
        >
          {label && (
            <label className={style.label_view} style={{ width: "30%" }}>
              {label}
            </label>
          )}
          <div style={{ width: "15%" }}>:</div>
          <div className={style.label_description} style={{ width: "50%" }}>
            {value && value !== "null" && value !== "undefined" ? value : "--"}
          </div>
        </div>
      ) : (
        <div
          className={`d-flex align-items-center ${className}`}
          style={{ width: modalStyles ? "90%" : "100%" }}
        >
          {label && (
            <label
              className={`${style.label_view} mb-0`}
              style={{ width: "30%" }}
            >
              {label}
            </label>
          )}
          <div style={{ width: "15%" }}>:</div>
          <div style={{ width: "50%" }}>
            <MyDropdown
              data={invoiceStatus}
              selectedValue={statusModal?.type ? statusModal?.type : value}
              onChange={(option) => {
                setStatusModal({
                  ...statusModal,
                  isActive: true,
                  type: option,
                });
              }}
              type={"Country"}
            />
          </div>
        </div>
      )}
      <NormalModal
        isActive={statusModal.isActive}
        onClose={() => {
          setStatusModal({
            isActive: false,
            // remark: "",
            // reason: "",
            // type: "",
          });
        }}
        title={"Status"}
      >
        <MyDropdown
          placeholder="Status"
          data={invoiceStatus}
          onChange={(option) => {
            setStatusModal({ ...statusModal, type: option });
          }}
          selectedValue={statusModal.type}
          isDisabled={true}
          name="status"
          width="100%"
        />
        <NormalMyInput
          type="text"
          customholder="Enter Remarks"
          className="mt-3"
          width="100%"
          onChange={(e) =>
            setStatusModal({ ...statusModal, remark: e.target.value })
          }
          value={statusModal.remark}
          info="The Remark is for your reference purpose"
        />
        {(statusModal.type === "Rejected" ||
          statusModal.type === "Query Raised") && (
          <NormalMyInput
            type="text"
            className="mt-2 mb-2"
            customholder="Enter Reason"
            width="100%"
            value={statusModal.reason}
            onChange={(e) =>
              setStatusModal({ ...statusModal, reason: e.target.value })
            }
            info="The Reason will be displayed to the client"
          />
        )}
        <div className="d-flex justify-content-end gap-2">
          <CustomButton
            iconRequired={false}
            type="btn-smlg btn-outline-primary"
            buttonType="submit"
            handleClick={() => {
              setStatusModal({
                isActive: false,
                // remark: "",
                // reason: "",
                // type: "",
              });
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            iconRequired={false}
            type="btn-primary"
            buttonType="submit"
            handleClick={() => handleSubmit()}
          >
            Save
          </CustomButton>
        </div>
      </NormalModal>
    </>
  );
};

const ViewBillInvoice = () => {
  const { id, type } = useParams();
  const [counterData, setCounterData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [cookies] = useCookies(["t"]);
  const location = useLocation();
  const state = location.state;
  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: `${type === "Client" ? "cbills" : "bills"}/getBillByID`,
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setCounterData(res);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) {
      getCounterDataById();
    }
  }, [id]);

  useEffect(() => {
    if (counterData?.bill_url?.length > 0) {
      setSelectedFile(counterData.bill_url[0]);
    }
  }, [counterData]);

  return (
    <>
      <Breadcrumb
        labels={["Invoices", "View Invoice"]}
        stepsToRemove={2}
        {...(state
          ? {
              customState: {
                type: counterData?.status,
                filters: state.filters,
                date: state.date,
              },
            }
          : {})}
      />
      <Heading className="addHeaderStyle fs-20">View Bill</Heading>
      <div className="d-flex w-100">
        <div
          className=" d-flex flex-column flex-wrap mb-3"
          style={{ columnGap: "0%", rowGap: "10px", width: "30%" }}
        >
          {counterData?.companyName && (
            <LabelDisplay
              label="Client Name"
              value={counterData?.companyName}
            />
          )}

          {counterData?.clientName && (
            <LabelDisplay label="Name" value={counterData?.clientName} />
          )}
          <LabelDisplay label="Title" value={counterData?.title} />
          <LabelDisplay label="Category" value={counterData?.category_name} />
          <LabelDisplay label="Sub Category" value={counterData?.type} />
          <LabelDisplay label="Currency" value={counterData?.currency} />
          <LabelDisplay label="Price" value={counterData?.price} />
          {counterData?.createdAt && (
            <LabelDisplay
              label="Created On"
              value={moment(counterData?.createdAt).format("YYYY-MM-DD")}
            />
          )}
          {counterData?.updatedAt && (
            <LabelDisplay
              label="Updated On"
              value={moment(counterData?.updatedAt).format("YYYY-MM-DD")}
            />
          )}
          {counterData?.status && (
            <LabelDisplay
              label="Status"
              value={counterData?.status}
              isDropdown="Dropdown"
              data={counterData}
            />
          )}
          {counterData?.audit_name && (
            <LabelDisplay label="Audited By" value={counterData?.audit_name} />
          )}
          {counterData?.audit_date && (
            <LabelDisplay
              label="Audited On"
              value={moment(counterData?.audit_date).format("YYYY-MM-DD")}
            />
          )}
          {counterData?.remark && (
            <LabelDisplay label="Remarks" value={counterData?.remark} />
          )}
          {counterData?.reason &&
            (counterData?.status === "Rejected" ||
              counterData?.status === "Query Raised") && (
              <LabelDisplay label="Reason" value={counterData?.reason} />
            )}
          <LabelDisplay label="Description" value={counterData?.description} />
        </div>
        {counterData?.bill_url && counterData?.bill_url.length > 0 && (
          <div>
            <label className={style.label_view}>Bill Files</label>
            <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
              {counterData?.bill_url?.map((url, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedFile(url)}
                  style={{
                    padding: "8px 12px",
                    background: "#146ef5",
                    color: "white",
                    border: selectedFile === url ? "1px solid white" : "none",
                    outline:
                      selectedFile === url ? "1px solid #146ef5" : "none",
                    borderRadius: "4px",
                  }}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            {selectedFile &&
              (getExtension(selectedFile) === "docx" ||
              getExtension(selectedFile) === "doc" ||
              getExtension(selectedFile) === "xlsx" ||
              getExtension(selectedFile) === "xls" ||
              getExtension(selectedFile) === "txt" ||
              getExtension(selectedFile) === "ppt" ? (
                <DocViewer
                  documents={[
                    {
                      uri: selectedFile,
                      fileType: getExtension(selectedFile),
                    },
                  ]}
                  pluginRenderers={DocViewerRenderers}
                  style={{
                    height: "700px",
                    width: "700px",
                    overflow: "hidden",
                  }}
                />
              ) : getExtension(selectedFile) !== "pdf" ? (
                <>
                  <img
                    src={selectedFile}
                    title="Preview"
                    className={style.view_selected_image}
                  />
                </>
              ) : (
                <iframe
                  width={700}
                  height={700}
                  style={{
                    border: "none",
                    overflow: "hidden",
                  }}
                  src={selectedFile}
                  title="Preview"
                  className={style.view_selected_image}
                />
              ))}
          </div>
        )}
      </div>
    </>
  );
};
export default ViewBillInvoice;
