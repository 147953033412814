import React, { useCallback, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { getData, patchData, postData } from "../../../../../Services";
import { statusData } from "../../../../../helpers/staticData";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import MyInput from "../../../../atoms/MyInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import { emailRegex } from "../../../../../helpers/expirationTime";
import { useSelector } from "react-redux";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import { useLocation, useNavigate } from "react-router-dom";
import WarningModal from "../../../../atoms/modals/warningModal";

const ClientForm = ({
  onClose,
  initialValues,
  id,
  refetchData,
  setEditData,
}) => {
  const [cookies] = useCookies(["t", "bid", "iso"]);
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["Client Management"];
  const [emailError, setEmailError] = useState(null);

  const [warning, setWarning] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isBreadcum, setIsBredcum] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [anchorClicked, setAnchorClicked] = useState({
    isActive: false,
    event: null,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [navigatePath, setNavigatePath] = useState(null);

  const initialData = {
    name: initialValues?.name || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const res = await postData({
      endpoint: "client/create",
      token: cookies.t,
      params: {
        name: capitalizeLetter(values.name),
        business_id: cookies?.bid,
      },
    });
    if (res?.data?.statusCode && res?.data) {
      toast.success("Client Added Successfully", {
        style: confimationStyles,
        duration: 1000,
      });

      resetForm();
      refetchData();
    } else {
      toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      onClose();
    }
  };

  const handleEdit = async (values, { resetForm }) => {
    try {
      const res = await patchData({
        endpoint: "client/update",
        token: cookies.t,
        data: values,
        params: {
          id: initialValues._id,
          name: capitalizeLetter(values.name),
          business_id: cookies?.bid,
        },
      });

      if (res) {
        toast.success("Client Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        resetForm();
        setEditData();
        refetchData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {}
  };

  const checkName = async (newVal) => {
    const response = await getData({
      endpoint: "client/checkName",
      token: cookies.t,
      params: { name: newVal, id: cookies.bid },
    });
    if (response.status && response.data) {
      setEmailError("Client Name Already Exists");
    } else {
      setEmailError(null);
    }
  };

  const handleBeforeUnload = (e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = "";
      return "";
    }
  };

  const handlePopState = (e) => {
    if (isDirty) {
      e.preventDefault();
      setWarning(true);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (isDirty && !isBreadcum && !isClicked) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", handlePopState);
      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("popstate", handlePopState);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isDirty]);

  useEffect(() => {
    function handleClickOutSide(event) {
      const href = event.target.closest("[href]");
      if (href) {
        if (isDirty) {
          setWarning(true);
          setAnchorClicked({
            isActive: true,
            event: event,
          });
        }
        event.preventDefault();
      } else {
        console.log("Clicked outside.");
      }
    }
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [location, isDirty]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={initialValues ? handleEdit : handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
        isSubmitting,
        resetForm,
        setFieldTouched,
        touched,
      }) => {
        if (dirty !== isDirty) {
          setIsDirty(dirty);
        }
        return (
          <>
            <Form>
              <div className="row mt-3">
                <div className="col-3">
                  <MyInput
                    type="text"
                    id="name"
                    name="name"
                    totalWidth={true}
                    customholder="Enter Client Name"
                    required
                    customBlur={(e) => {
                      if (initialValues?.name !== values.name) {
                        checkName(values.name);
                      }
                    }}
                    blurError={emailError}
                  />
                </div>

                {((roleData && roleData?.["add/edit"]) || isOwner) && (
                  <div className={`col-3 mb-3`}>
                    {initialValues ? (
                      <button
                        type="submit"
                        className="btn-smlg btn-primary w-50"
                        disabled={
                          !isValid || isSubmitting || emailError !== null
                        }
                      >
                        Update Client
                        {isSubmitting && (
                          <span
                            className="spinner-border spinner-border-sm ms-2 text-light"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    ) : (
                      <div className="d-flex gap-4 ">
                        <button
                          type="button"
                          className="btn-smlg btn-outline-primary w-25 flex-1"
                          disabled={isSubmitting}
                          onClick={() => resetForm()}
                        >
                          Reset
                        </button>

                        <button
                          type="submit"
                          className="btn-smlg btn-primary w-25 flex-1"
                          disabled={isSubmitting || emailError !== null}
                        >
                          Create Client
                          {isSubmitting && (
                            <span
                              className="spinner-border spinner-border-sm ms-2 text-light"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Form>
            <WarningModal
              isActive={warning}
              type=""
              title={"Changes Unsaved"}
              description="Are you need to leave the changes unsaved"
              onClose={
                anchorClicked
                  ? () => {
                      setWarning(false);
                      setAnchorClicked({
                        isActive: false,
                        event: null,
                      });
                    }
                  : () => {
                      setWarning(false);
                      window.history.pushState(
                        null,
                        null,
                        window.location.pathname
                      );
                    }
              }
              onClick={
                anchorClicked.isActive
                  ? () => {
                      const href = anchorClicked.event.target
                        .closest("[href]")
                        .getAttribute("href");
                      if (href) {
                        if (href === "https://wesoftek.com/") {
                          window.open(href, "_blank");
                        } else {
                          navigate(href, { replace: true });
                        }
                      }
                      setWarning(false);
                      setAnchorClicked({
                        isActive: false,
                        event: null,
                      });
                      setIsDirty(false);
                    }
                  : (e) => {
                      setIsClicked(true);
                      navigate(-1);
                      setWarning(false);
                      setIsDirty(false);
                    }
              }
            ></WarningModal>
          </>
        );
      }}
    </Formik>
  );
};

export default ClientForm;
