import { memo, useCallback, useEffect, useState } from "react";
import { Heading } from "../../../../atoms/Heading";
import Search from "../../../../atoms/Search";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import style from "./index.module.scss";
// import NoContentCard from "../../../../atoms/NoContentCard";
import { deleteData, getData, patchData } from "../../../../../Services";
import View from "../../../../icons/View";
import EditBlue from "../../../../icons/EditBlue";
import CustomButton from "../../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../../atoms/Pagination";
import Delete from "../../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Modal from "../../../../atoms/modals/DeleteModal";
import Edit from "../../../../icons/EditBlue";
import CustomMenu from "../../../../molecules/CustomMenu";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
// import { Rate_TypeData } from "../../../../../../utils/staticData";
import EyeIcon from "../../../../icons/eye.icon";
import Upload from "../../../../icons/Upload";
import { downloadReport } from "../../../../../Services/downloadService";
import moment from "moment";
import NoContentCard from "../../../../atoms/NoContentCard";
import { useSelector } from "react-redux";
import NormalModal from "../../../../atoms/modals/Modal";
import MyInput from "../../../../atoms/MyInput";
import NormalMyInput from "../../../../atoms/NormalTextInput/normalInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import { invoiceStatus } from "../../../../../helpers/staticData";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import { decodeCookieValue } from "../../../../../helpers/cookieHelper";
import QueryIcon from "../../../../icons/query";
import BookIcon from "../../../../icons/book";
import RejectIcon from "../../../../icons/reject";
import PendingIcon from "../../../../icons/pending";

const ClientTable = ({ type, searchData, getCountsTabs, filters, date }) => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([
    "t",
    "bid",
    "ibu",
    "mode",
    "iso",
    "group_id",
  ]);
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["Client Invoices"];
  const [employeeData, setEmployeeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loader, setLoader] = useState(true);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [statusModal, setStatusModal] = useState({
    isActive: false,
    remark: "",
    reason: "",
    type: "",
  });

  const handleChangeStatus = async (type) => {
    try {
      const res = await patchData({
        endpoint: "cbills/normalupdate",
        token: cookies.t,
        data: {
          status: type,
          audit_name: capitalizeLetter(decodeCookieValue(cookies.un)) || "",
          audit_date: new Date(),
        },
        params: {
          id: selectedRow._id,
        },
      });

      if (res) {
        toast.success("Bill Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        GetemployeeData();
        getCountsTabs();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }
    } catch (error) {}
  };

  const menu = [
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`edit/${selectedRow?._id}`);
      },
    },
    {
      key: "view",
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        navigate(`viewInvoice/Client/${selectedRow?._id}`, {
          state: { filters: filters, date: date },
        });
      },
    },
    {
      icon: <PendingIcon />,
      label: "New/Pending",
      onClick: () => {
        setAnchorEl(null);
        setStatusModal({
          remark: selectedRow.remark ? selectedRow.remark : "",
          reason: selectedRow.reason ? selectedRow.reason : "",
          isActive: true,
          type: "New/Pending",
        });
      },
    },
    {
      icon: <QueryIcon />,
      label: "Raise Query",
      onClick: () => {
        setAnchorEl(null);
        setStatusModal({
          remark: selectedRow.remark ? selectedRow.remark : "",
          reason: selectedRow.reason ? selectedRow.reason : "",
          isActive: true,
          type: "Query Raised",
        });
      },
    },
    {
      icon: <BookIcon />,
      label: "Book",
      onClick: () => {
        setAnchorEl(null);
        setStatusModal({
          remark: selectedRow.remark ? selectedRow.remark : "",
          reason: selectedRow.reason ? selectedRow.reason : "",
          isActive: true,
          type: "Booked",
        });
      },
    },
    {
      icon: <RejectIcon />,
      label: "Reject",
      onClick: () => {
        setAnchorEl(null);
        setStatusModal({
          remark: selectedRow.remark ? selectedRow.remark : "",
          reason: selectedRow.reason ? selectedRow.reason : "",
          isActive: true,
          type: "Rejected",
        });
      },
    },
  ];

  const GetemployeeData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "cbills",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          group_id: cookies.group_id ? cookies.group_id : "",
          business_id: cookies.bid,
          client_names: filters?.["Client Name"],
          categories: filters?.["Category"],
          type: type,
          emp_type: "Client",
          mode: "Invoice",
          start_date: date?.start_date?.replace(".000", ""),
          end_date: date?.end_date?.replace(".000", ""),
        },
      });
      setEmployeeData(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    getCountsTabs();
    GetemployeeData();
  }, [itemsPerPage, currentPage, searchData, filters, type, date]);

  const handleEdit = (data) => {
    navigate(`/dashboard/setting/price/add/${data?._id}`);
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    console.log("Row :", row);

    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row.clientComapny && (
              <div>{params.row.clientComapny?.[0]?.name}</div>
            )}
          </>
        );
      },
    },
    {
      field: "created",
      headerName: "Client User",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row.clientData && <div>{params.row.clientData?.name}</div>}
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Category",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.categoryData && (
              <div>{params?.row?.categoryData?.category}</div>
            )}
          </>
        );
      },
    },
    {
      field: "type",
      headerName: "Sub Category",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.type && (
              <div>{params?.row?.type !== "null" ? params?.row?.type : ""}</div>
            )}
          </>
        );
      },
    },
    {
      field: "currecy",
      headerName: "Currency",
      flex: 1,
      renderCell: (params) => {
        return <>{params?.row?.currency && <div>{params.row.currency}</div>}</>;
      },
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div>
              <span
                type="button"
                onClick={(event) => handleStatusMenu(event, params.row)}
              >
                <HorizontalMenu />
              </span>
            </div>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];

  const getMenuList = () => {
    if (
      type === "New/Pending" &&
      ((roleData && roleData?.["add/edit"]) || isOwner)
    ) {
      return [menu[0], menu[1], menu[3], menu[4], menu[5]];
    } else if (
      type === "Query Raised" &&
      ((roleData && roleData?.["add/edit"]) || isOwner)
    ) {
      return [menu[0], menu[1], menu[2], menu[4], menu[5]];
    } else if (
      type === "Rejected" &&
      ((roleData && roleData?.["add/edit"]) || isOwner)
    ) {
      return [menu[0], menu[1], menu[2], menu[3], menu[4]];
    } else if (
      type === "Booked" &&
      ((roleData && roleData?.["add/edit"]) || isOwner)
    ) {
      return [menu[0], menu[1], menu[2], menu[3], menu[5]];
    } else return [];
  };

  // const getMenuList = () => {
  //   if (roleData && roleData?.["add/edit"] && roleData?.delete) {
  //     return menu;
  //   } else if (roleData && roleData?.delete) {
  //     return [menu[1]];
  //   } else if (roleData && roleData?.["add/edit"]) {
  //     return [menu[0]];
  //   } else return [];
  // };

  const CustomNoRowsOverlay = memo(() => {
    return loader ? (
      <>
        {" "}
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#3b424a", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </>
    ) : (
      <>
        {!!(searchData || Object.keys(filters).length > 0) ? (
          <>
            <NoContentCard
              title="No Invoices Found"
              tag="Create Bill"
              type="Pricing"
            />
          </>
        ) : (
          <>
            <NoContentCard
              title="No Invoice added yet!"
              tag="Create Client Invoice"
              type="Pricing"
              classes="d-flex align-items-center"
              {...((roleData && roleData?.["add/edit"]) || isOwner
                ? {
                    handleClick: () => {
                      navigate("/dashboard/clientInvoice/add");
                    },
                    subtitle:
                      "Currently no client Invoice created yet click on a create client Invoice button to create client Invoice",
                  }
                : {})}
            />
          </>
        )}
      </>
    );
  });
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "cbills/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Bill Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetemployeeData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "cbills/export",
      token: cookies.t,
      fileName: "Prices",
      params: {
        search: searchData,
        rate_type: filters?.["Rate Type"],
      },
    });

    if (res) {
      toast.success("Prices Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await patchData({
        endpoint: "cbills/normalupdate",
        token: cookies.t,
        data: {
          status: statusModal.type,
          remark: statusModal.remark,
          reason: statusModal.reason,
          audit_name: capitalizeLetter(decodeCookieValue(cookies.un)) || "",
          audit_date: new Date(),
        },
        params: {
          id: selectedRow._id,
        },
      });

      if (res) {
        toast.success("Bill Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        setStatusModal({
          isActive: false,
          remark: "",
          reason: "",
          type: "",
        });
        GetemployeeData();
        getCountsTabs();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }
    } catch (error) {}
  };

  return loader ? (
    <div
      className="w-100  d-flex align-items-center justify-content-center"
      style={{ height: "70vh" }}
    >
      <span
        className="spinner-border spinner-border-lr ms-2"
        style={{ color: "#3b424a", width: "40px", height: "40px" }}
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  ) : (
    <>
      <CustomDatagrid
        rows={employeeData?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        className={style.custom_grid_styles}
        loader={loader}
        onCellClick={(data) => {
          if (
            data?.field !== "actions" &&
            data?.field !== "__check__" &&
            ((roleData && roleData?.["view"]) || isOwner)
          ) {
            navigate(
              `/dashboard/clientInvoice/viewInvoice/Client/${data?.id}`,
              {
                state: { filters: filters, date: date },
              }
            );
          }
        }}
      />
      {/* {employeeData?.[0]?.pageDetails?.count > 0 && ( */}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={employeeData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
      {/* )} */}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Bill"
      />
      <NormalModal
        isActive={statusModal.isActive}
        onClose={() => {
          setStatusModal({
            isActive: false,
            remark: "",
            reason: "",
            type: "",
          });
        }}
        title={"Status"}
      >
        <MyDropdown
          placeholder="Status"
          data={invoiceStatus}
          onChange={(option) => {
            setStatusModal({ ...statusModal, type: option });
          }}
          selectedValue={statusModal.type}
          isDisabled={true}
          name="status"
          width="100%"
        />
        <NormalMyInput
          type="text"
          customholder="Enter Remarks"
          className="mt-3"
          value={statusModal.remark}
          width="100%"
          onChange={(e) =>
            setStatusModal({ ...statusModal, remark: e.target.value })
          }
          info="The Remark is for your reference purpose"
        />
        {(statusModal.type === "Rejected" ||
          statusModal.type === "Query Raised") && (
          <NormalMyInput
            type="text"
            className="mt-2 mb-2"
            customholder="Enter Reason"
            width="100%"
            value={statusModal.reason}
            onChange={(e) =>
              setStatusModal({ ...statusModal, reason: e.target.value })
            }
            info="The Reason will be displayed to the client"
          />
        )}
        <div className="d-flex justify-content-end gap-2">
          <CustomButton
            iconRequired={false}
            type="btn-smlg btn-outline-primary"
            buttonType="submit"
            handleClick={() => {
              setStatusModal({
                isActive: false,
                remark: "",
                reason: "",
                type: "",
              });
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            iconRequired={false}
            type="btn-primary"
            buttonType="submit"
            handleClick={() => handleSubmit()}
          >
            Save
          </CustomButton>
        </div>
      </NormalModal>
    </>
  );
};
export default ClientTable;
