import React, { useEffect, useState } from "react";
import { Heading } from "../../../../atoms/Heading";
import ArrowDown from "../../../../icons/ArrowDown";
import PieChart from "./Chart";
import "./styles.scss";
import { getData } from "../../../../../Services/index";
import { useCookies } from "react-cookie";
import { currencySymbol } from "../../../../../helpers/currencyData";

const Charts = ({ title, type, date, filters, currency }) => {
  const [selected, setSelected] = useState("Client");
  const options = ["Client", "Obligor", "Supplier"];
  const [chartData, setChartData] = useState([]);
  const [cookies] = useCookies(["t"]);

  const fetchInvestmentData = async () => {
    const start_date = new Date(date?.start_date);
    const end_date = new Date(date?.end_date);
    const finalStart = new Date(
      Date.UTC(
        start_date.getUTCFullYear(),
        start_date.getUTCMonth(),
        start_date.getUTCDate(),
        0, // 00:00:00 UTC
        0,
        0,
        0
      )
    ).toISOString();

    const finalEnd = new Date(
      Date.UTC(
        end_date.getUTCFullYear(),
        end_date.getUTCMonth(),
        end_date.getUTCDate(),
        23, // 23:59:59.999 UTC
        59,
        59,
        999
      )
    ).toISOString();
    const res = await getData({
      endpoint: "businessDashboard/getClientSummary",
      token: cookies.t,
      params: {
        business_id: cookies.bid,
        start_date: finalStart,
        end_date: finalEnd,
        client_names: filters?.["Clients"],
        clients: filters?.["Client Users"],
        currency: currency ? currency?.toLowerCase() : null,
        group_id: cookies.group_id ? cookies.group_id : "",
      },
    });

    console.log("res", res);
    if (res && res?.data) {
      setChartData(res?.data);
    } else {
      setChartData({});
    }
  };

  const fetchData = async () => {
    const res = await getData({
      endpoint: "businessDashboard/getEmployeeSummary",
      token: cookies.t,
      params: {
        business_id: cookies.bid,
        start_date: date?.start_date,
        end_date: date?.end_date,
        currency: currency ? currency?.toLowerCase() : null,
        employees: filters?.["Employee Users"],
      },
    });

    setChartData(res?.data);
  };

  useEffect(() => {
    if (type === "Employee") {
      fetchData();
    }
  }, [selected, date, filters, currency]);

  useEffect(() => {
    if (type === "Client") {
      fetchInvestmentData();
    }
  }, [date, filters, currency]);

  return (
    <div className="dashboard-charts">
      <div className="d-flex justify-content-between">
        <Heading className={"HeadingSubStyle"}>{title}</Heading>
        {/* <>
        {type !== "investment" && (
          <div className="dropdown">
            <div
              className="dropdown-box"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {selected}
              {/* <i className="ms-1 d-flex"> */}
        {/* <ArrowDown /> */}
        {/* </i> */}
        {/* </div> */}
        {/* <ul className="dropdown-menu">
              {options?.map((option) => (
                <li key={option}>
                  <button
                    className="dropdown-item"
                    onClick={() => setSelected(option)}
                  > */}
        {/* <span>{option}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div> */}
        {/* )} */}
        {/* </> */}
      </div>

      <PieChart data={chartData} type={type} unit={currencySymbol[currency]} />
    </div>
  );
};

export default Charts;
