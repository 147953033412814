import React, { useCallback, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { getData, patchData, postData } from "../../../../../Services";
import { statusData, statusData2 } from "../../../../../helpers/staticData";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import MyInput from "../../../../atoms/MyInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import { emailRegex } from "../../../../../helpers/expirationTime";
import { useSelector } from "react-redux";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import { useLocation, useNavigate } from "react-router-dom";
import WarningModal from "../../../../atoms/modals/warningModal";

const UserForm = ({
  onClose,
  initialValues,
  id,
  refetchData,
  setEditData,
  activeTab,
  setActiveTab,
}) => {
  const [cookies] = useCookies(["t", "bid", "iso"]);
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["User Management"];
  const [rolesData, setRolesData] = useState(false);
  const [roleGroupData, setRoleGroupData] = useState();
  const [emailError, setEmailError] = useState("");
  const [isEmailChecked, setIsEmailChecked] = useState(true);

  const [warning, setWarning] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isTabClicked, setIsTabClicked] = useState({
    isActive: false,
    tab: null,
  });
  const [isClicked, setIsClicked] = useState(false);
  const [anchorClicked, setAnchorClicked] = useState({
    isActive: false,
    event: null,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const initialData = {
    name: initialValues?.name || "",
    email: initialValues?.email || "",
    role_id: initialValues?.business_details?.role_id || "",
    role_group_id: initialValues?.business_details?.role_group_id || null,
    business_id: cookies?.bid || "",
    status: initialValues?.business_details?.status || "Pending",
  };

  const getRolesData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "/roleBusiness/getRolesList",
        token: cookies.t,
        params: {
          business_id: cookies.bid,
        },
      });

      if (res) {
        setRolesData(res);
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t]);

  const getRolesGroupData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "/rolegroupBusiness/getRolesList",
        token: cookies.t,
        params: {
          business_id: cookies.bid,
        },
      });

      if (res) {
        setRoleGroupData(res);
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t]);

  useEffect(() => {
    getRolesData();
    getRolesGroupData();
  }, [getRolesData]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email")
      .required("Email is Required"),
    role_id: Yup.string().required("Role is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const res = await postData({
      endpoint: "businessAuth/createUser",
      token: cookies.t,
      data: {
        ...values,
        email: values.email.toLowerCase(),
        name: capitalizeLetter(values.name),
      },
    });
    if (res?.data?.statusCode && res?.data) {
      toast.success("User Added Successfully", {
        style: confimationStyles,
        duration: 1000,
      });

      resetForm();
      refetchData();
    } else {
      toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      onClose();
    }
  };

  const handleResend = async (newVal) => {
    const response = await getData({
      endpoint: "businessAuth/resendInvite",
      params: { email: newVal, business_id: cookies?.bid, type: "Business" },
      token: cookies.t,
    });
    if (response && response.statusCode) {
    } else {
      toast.error("An Error Occured While Resending Invite Email", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const handleEdit = async (values, { resetForm }) => {
    try {
      const res = await patchData({
        endpoint: "businessAuth/updateUser",
        token: cookies.t,
        params: {
          id: initialValues?._id,
        },
        data: {
          ...values,
          email: values.email.toLowerCase(),
          name: capitalizeLetter(values.name),
        },
      });

      if (res.statusCode) {
        toast.success("User Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        if (
          initialValues.email !== values.email.toLowerCase() &&
          (initialValues?.business_details?.status === "Pending" ||
            initialValues?.business_details?.status === "Invited")
        ) {
          await handleResend(values.email.toLowerCase());
        }
        resetForm();
        setEditData();
        refetchData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {}
  };

  const checkEmail = async (newVal) => {
    const response = await getData({
      endpoint: "businessAuth/checkEmail",
      token: cookies.t,
      params: { email: newVal, business_id: cookies.bid, type: "Business" },
    });
    if (response.status && response.data) {
      setEmailError("Email Already Exists");
      setIsEmailChecked(true);
    } else {
      setEmailError("");
      setIsEmailChecked(false);
    }
  };

  const handleBeforeUnload = (e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = "";
      return "";
    }
  };

  const handlePopState = (e) => {
    if (isDirty) {
      e.preventDefault();
      setWarning(true);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (isDirty && !isClicked) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", handlePopState);
      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("popstate", handlePopState);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isDirty]);

  useEffect(() => {
    function handleClickOutSide(event) {
      const button = event.target.closest("button");
      const tabElement = button?.querySelector("span[data-label]");
      if (
        tabElement &&
        isDirty &&
        (tabElement?.textContent === "Role Management" ||
          tabElement?.textContent === "Client Group Management")
      ) {
        setWarning(true);
        setIsTabClicked({
          isActive: true,
          tab: tabElement?.textContent,
        });
        event.preventDefault();
      }

      const href = event.target.closest("[href]");
      if (href) {
        if (isDirty) {
          setWarning(true);
          setAnchorClicked({
            isActive: true,
            event: event,
          });
        }
        event.preventDefault();
      } else {
        console.log("Clicked outside.");
      }
    }
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [location, isDirty]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={initialValues ? handleEdit : handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
        isSubmitting,
        resetForm,
        setFieldTouched,
        touched,
      }) => {
        if (dirty !== isDirty) {
          setIsDirty(dirty);
        }
        return (
          <>
            <Form autoComplete="off">
              <div className="row mt-3">
                <div className="col-3">
                  <MyInput
                    type="text"
                    id="name"
                    name="name"
                    totalWidth={true}
                    customholder="Enter Name"
                    required
                  />
                </div>

                <div className="col-3">
                  <MyInput
                    type="email"
                    id="email"
                    name="email"
                    totalWidth={true}
                    customholder="Enter Email"
                    customChange={(option) => {
                      if (
                        initialValues?.email !== option &&
                        option.length > 5
                      ) {
                        checkEmail(option);
                      }
                    }}
                    blurError={emailError}
                    required
                    disabled={initialValues ? true : false}
                    allowSpecial
                  />
                </div>

                <div className="col-3">
                  <MyDropdown
                    data={roleGroupData}
                    placeholder="Select Client Group"
                    onChange={(option) => {
                      setFieldValue("role_group_id", option);
                    }}
                    selectedValue={values?.role_group_id}
                    isError={errors.role_group_id}
                    touched={touched.role_group_id}
                    handleBlur={(e) => {
                      handleBlur(e);
                      setFieldTouched("role_group_id", true);
                    }}
                    errors={errors.role_group_id}
                  />
                </div>
                <div className="col-3">
                  <MyDropdown
                    data={rolesData}
                    placeholder="Select Role"
                    onChange={(option) => {
                      setFieldValue("role_id", option);
                    }}
                    selectedValue={values?.role_id}
                    isError={errors.role_id}
                    touched={touched.role_id}
                    handleBlur={(e) => {
                      handleBlur(e);
                      setFieldTouched("role_id", true);
                    }}
                    errors={errors.role_id}
                    required
                  />
                </div>

                {initialValues &&
                  values &&
                  values?.status !== "Pending" &&
                  values?.status !== "Invited" &&
                  values?.status !== "Cancelled" && (
                    <div className="col-3 mt-3">
                      <MyDropdown
                        data={statusData2}
                        placeholder="Select Status"
                        onChange={(option) => {
                          setFieldValue("status", option);
                        }}
                        selectedValue={values?.status}
                      />
                    </div>
                  )}
                {((roleData && roleData?.["add/edit"]) || isOwner) && (
                  <div className={`col-3 mt-3`}>
                    {initialValues ? (
                      <button
                        type="submit"
                        className="btn-smlg btn-primary w-50"
                        disabled={
                          !isValid ||
                          isSubmitting ||
                          (initialValues?.email !== values.email
                            ? emailError
                            : false) ||
                          (initialValues?.email !== values.email
                            ? isEmailChecked
                            : false)
                        }
                      >
                        Update
                        {isSubmitting && (
                          <span
                            className="spinner-border spinner-border-sm ms-2 text-light"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    ) : (
                      <div className="d-flex gap-4 ">
                        <button
                          type="button"
                          className="btn-smlg btn-outline-primary w-25 flex-1"
                          disabled={isSubmitting}
                          onClick={() => resetForm()}
                        >
                          Reset
                        </button>

                        <button
                          type="submit"
                          className="btn-smlg btn-primary w-25 flex-1"
                          disabled={
                            isSubmitting || emailError || isEmailChecked
                          }
                        >
                          Add
                          {isSubmitting && (
                            <span
                              className="spinner-border spinner-border-sm ms-2 text-light"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Form>
            <WarningModal
              isActive={warning}
              type=""
              title={"Changes Unsaved"}
              description="Are you need to leave the changes unsaved"
              onClose={
                isTabClicked.isActive
                  ? () => {
                      setWarning(false);
                      setIsTabClicked({
                        isActive: false,
                        tab: null,
                      });
                    }
                  : anchorClicked
                  ? () => {
                      setWarning(false);
                      setAnchorClicked({
                        isActive: false,
                        event: null,
                      });
                    }
                  : () => {
                      setWarning(false);
                      window.history.pushState(
                        null,
                        null,
                        window.location.pathname
                      );
                    }
              }
              onClick={
                isTabClicked.isActive
                  ? () => {
                      setWarning(false);
                      if (isTabClicked.tab) {
                        setActiveTab(isTabClicked.tab);
                      }
                      setIsTabClicked({
                        isActive: false,
                        tab: null,
                      });
                    }
                  : anchorClicked.isActive
                  ? () => {
                      const href = anchorClicked.event.target
                        .closest("[href]")
                        .getAttribute("href");
                      if (href) {
                        if (href === "https://wesoftek.com/") {
                          window.open(href, "_blank");
                        } else {
                          navigate(href, { replace: true });
                        }
                      }
                      setWarning(false);
                      setAnchorClicked({
                        isActive: false,
                        event: null,
                      });
                      setIsDirty(false);
                    }
                  : (e) => {
                      setIsClicked(true);
                      navigate(-1);
                      setWarning(false);
                      setIsDirty(false);
                    }
              }
            ></WarningModal>
          </>
        );
      }}
    </Formik>
  );
};

export default UserForm;
