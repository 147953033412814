import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Error from "../../../../atoms/Error";
import MyDropdown from "../../../../atoms/MyDropdown/index";
import MyInput from "../../../../atoms/MyInput/index";
import { countryCodeData } from "../../../../../helpers/countryCodes";
import {
  businessTypes,
  employeeTypes,
  statusData2,
} from "../../../../../helpers/staticData";
import CustomButton from "../../../../atoms/CustomButton";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import { replaceSpacesInImageUrl } from "../../../../../helpers/replaceSpaceInImageUrl";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import { useCookies } from "react-cookie";
import {
  getData,
  postFormData,
  patchFormData,
  postData,
  patchData,
} from "../../../../../Services/index";
import dayjs from "dayjs";
import { statusData } from "../../../../../helpers/staticData";
// import { checkEmailExists } from "../../../../../services/basicGets";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import { Heading } from "../../../../atoms/Heading";
import MyTextArea from "../../../../atoms/MyTextArea";
import Breadcrumb from "../../../../atoms/Breadcrumb";
import WarningModal from "../../../../atoms/modals/warningModal";

const ImageSelectorStyle = {
  borderRadius: "10px",
  border: "1px solid #EFEFEF",
  padding: "10px",
  width: "200px",
  objectFit: "contain",
};

const AddClients = ({ type = "add" }) => {
  const { id } = useParams();

  const [cookies, setCookie] = useCookies(["t", "bid"]);
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [emailError, setEmailError] = useState("");
  const [isEmailChecked, setIsEmailChecked] = useState(true);
  const [clientList, setClientList] = useState();
  const navigate = useNavigate();

  const [warning, setWarning] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isBreadcum, setIsBredcum] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [anchorClicked, setAnchorClicked] = useState({
    isActive: false,
    event: null,
  });
  const [isTabClicked, setIsTabClicked] = useState({
    isActive: false,
    tab: null,
  });
  const location = useLocation();
  const [navigatePath, setNavigatePath] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "cusers/getEmployeeByID",
        token: cookies.t,
        params: { id, business_id: cookies?.bid },
      });
      setInitialValues(res?.data);
    };

    const getClientsList = async () => {
      const res = await getData({
        endpoint: "client/getClientList",
        token: cookies.t,
        params: {
          business_id: cookies.bid,
        },
      });
      setClientList(res);
    };

    if (type === "edit") {
      fetchData();
    }
    getClientsList();
  }, []);

  const initialData = {
    name: initialValues?.name || null,
    email: initialValues?.email || null,
    primary_cc: initialValues?.business_details?.[0]?.primary_cc || "+91",
    primary_number:
      initialValues?.business_details?.[0]?.primary_number || null,
    status: initialValues?.business_details?.[0]?.status || "Pending",
    client_id: initialValues?.business_details?.[0]?.client_id || "",
    designation: initialValues?.business_details?.[0]?.designation || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("User Name is required"),
    client_id: Yup.string().required("Client is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    primary_cc: Yup.string().required("Country code is required"),
    primary_number: Yup.string().when("primary_cc", {
      is: "+91",
      then: () =>
        Yup.string()
          .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
          .required("Primary Phone Number is required"),
      otherwise: () =>
        Yup.string()
          .matches(/^[0-9]{7,15}$/, "Please Enter a valid Phone Number")
          .required("Primary Phone Number is required"),
    }),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);

    try {
      const res = await postData({
        endpoint: "cusers/create",
        token: cookies.t,
        data: {
          ...values,
          name: capitalizeLetter(values.name),
          business_name: capitalizeLetter(values.business_name),
          business_id: cookies.bid,
          email: values.email.toLowerCase(),
          roleGroupId: cookies.group_id ? cookies.group_id : "",
        },
      });
      if (res) {
        toast.success("Client User Created Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate("/dashboard/clients", { replace: true });
        setWarning(false);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };
  const handleResend = async (newVal) => {
    const response = await getData({
      endpoint: "businessAuth/resendInvite",
      params: { email: newVal, business_id: cookies?.bid, type: "Client" },
      token: cookies.t,
    });
    if (response && response.statusCode) {
    } else {
      toast.error("An Error Occured While Resending Invite Email", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const handleEdit = async (values) => {
    const data = {
      ...values,
      name: capitalizeLetter(values.name),
      business_name: capitalizeLetter(values.business_name),
      business_id: cookies.bid,
      email: values.email.toLowerCase(),
    };

    try {
      const res = await patchData({
        endpoint: "cusers/update",
        token: cookies.t,
        data: data,
        params: { id: id },
      });
      if (res) {
        toast.success("Client User Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        if (
          initialValues.email !== values.email.toLowerCase() &&
          (initialValues?.business_details?.[0]?.status === "Pending" ||
            initialValues?.business_details?.[0]?.status === "Invited")
        ) {
          await handleResend(values.email.toLowerCase());
        }
        navigate("/dashboard/clients", { replace: true });
        setWarning(false);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const checkEmail = async (newVal) => {
    const response = await getData({
      endpoint: "businessAuth/checkEmail",
      token: cookies.t,
      params: { email: newVal, business_id: cookies.bid, type: "Client" },
    });
    if (response.status && response.data) {
      setEmailError("Email Already Exists");
      setIsEmailChecked(true);
    } else {
      setEmailError("");
      setIsEmailChecked(false);
    }
  };

  const handleBeforeUnload = (e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = "";
      return "";
    }
  };

  const handlePopState = (e) => {
    if (isDirty) {
      e.preventDefault();
      setWarning(true);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (isDirty && !isBreadcum && !isClicked) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", handlePopState);
      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("popstate", handlePopState);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isDirty]);

  useEffect(() => {
    function handleClickOutSide(event) {
      const href = event.target.closest("[href]");
      const button = event.target.closest("button")?.textContent;

      if (button === "Cancel") {
        if (isDirty) {
          setWarning(true);
          setIsTabClicked({
            isActive: true,
            tab: button,
          });
        }
        event.preventDefault();
      }

      if (href) {
        if (isDirty) {
          setWarning(true);
          setAnchorClicked({
            isActive: true,
            event: event,
          });
        }
        event.preventDefault();
      } else {
        console.log("Clicked outside.");
      }
    }
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [location, isDirty]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        validateForm,
      }) => {
        if (dirty !== isDirty) {
          setIsDirty(dirty);
        }
        return (
          <>
            <Breadcrumb
              labels={[
                "Client Users",
                `${
                  type === "add" ? "Create Client User" : "Update Client User"
                }`,
              ]}
              stepsToRemove={type === "add" ? 0 : 1}
              setNavigatePath={setNavigatePath}
              needToConfirm={isDirty}
              handlePopState={handlePopState}
              setWarning={setWarning}
              setIsBredcum={setIsBredcum}
            />
            <Heading className="h5 mb-3 mt-3">
              {type === "add" ? "Create Client User" : "Update Client User"}
            </Heading>
            <hr />
            <div
              className="d-flex flex-wrap w-100 mb-3"
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <MyDropdown
                placeholder="Select Client"
                data={clientList}
                onChange={(option) => {
                  setFieldValue("client_id", option);
                }}
                handleBlur={(e) => {
                  setFieldTouched("client_id", true);
                  handleBlur(e);
                }}
                selectedValue={values?.client_id}
                name="client_id"
                touched={touched.client_id}
                errors={errors.client_id}
                width="32.426%"
                required
              />

              <MyInput
                type="text"
                name="name"
                customholder="User Name"
                width="32.426%"
                required
              />

              <MyInput
                type="text"
                name="email"
                customholder="Email"
                width="32.426%"
                customChange={(option) => {
                  if (initialValues?.email !== option && option.length > 5) {
                    checkEmail(option);
                  }
                }}
                disabled={type === "edit"}
                blurError={emailError}
                required
                allowSpecial
              />

              <MyInput
                type="text"
                name="designation"
                customholder="Designation"
                width="32.426%"
              />

              <div className="d-flex" style={{ width: "32.426%", gap: "4%" }}>
                <MyDropdown
                  data={countryCodeData}
                  selectedValue={values.primary_cc}
                  name="primary_cc"
                  width="20%"
                  type={"Country"}
                  onChange={(option) => {
                    setFieldValue("primary_cc", option);
                  }}
                  selectedType="Country"
                />

                <MyInput
                  type="number"
                  name="primary_number"
                  customholder="Enter Primary Phone Number"
                  width="76%"
                  onChange={(e) => {
                    let numericInput = e.target.value.replace(/[^0-9]/g, "");
                    if (values.primary_cc === "+91") {
                      // Enforce a maximum length of 10 digits
                      if (numericInput.length > 10) {
                        numericInput = numericInput.slice(0, 10);
                      }
                      setFieldValue("primary_number", numericInput);
                    } else {
                      setFieldValue("primary_number", numericInput);
                    }
                  }}
                  required
                />
              </div>

              {type === "edit" &&
                values &&
                values?.status !== "Pending" &&
                values?.status !== "Invited" &&
                values?.status !== "Cancelled" && (
                  <MyDropdown
                    placeholder="Status"
                    data={statusData2}
                    onChange={(option) => {
                      setFieldValue("status", option);
                    }}
                    handleBlur={(e) => {
                      setFieldTouched("status", true);
                      handleBlur(e);
                    }}
                    selectedValue={values?.status}
                    name="status"
                    touched={touched.status}
                    errors={errors.status}
                    width="32.426%"
                  />
                )}
            </div>

            <div className="d-flex gap-3 mt-1">
              {console.log(
                "Isvalid: ",
                !isValid,
                !dirty,
                isSaving,
                emailError !== null
              )}
              {type === "add" ? (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleSubmit(values)}
                  disabled={
                    !isValid ||
                    !dirty ||
                    isSaving ||
                    emailError ||
                    isEmailChecked
                  }
                >
                  Create Client User
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              ) : (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleEdit(values)}
                  disabled={
                    !isValid ||
                    isSaving ||
                    (initialValues?.email !== values.email
                      ? emailError
                      : false) ||
                    (initialValues?.email !== values.email
                      ? isEmailChecked
                      : false)
                  }
                >
                  Update Client User
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              )}

              <CustomButton
                text="Cancel"
                iconRequired={false}
                handleClick={() => navigate(-1, { replace: true })}
              />
            </div>

            <WarningModal
              isActive={warning}
              type=""
              title={"Changes Unsaved"}
              description="Are you need to leave the changes unsaved"
              onClose={() => {
                setWarning(false);

                if (isTabClicked.isActive) {
                  setIsTabClicked({
                    isActive: false,
                    tab: null,
                  });
                } else if (isBreadcum) {
                  setNavigatePath(null);
                  setIsBredcum(false);
                } else if (anchorClicked?.isActive) {
                  setAnchorClicked({
                    isActive: false,
                    event: null,
                  });
                } else {
                  window.history.pushState(
                    null,
                    null,
                    window.location.pathname
                  );
                }
              }}
              onClick={
                isTabClicked.isActive
                  ? () => {
                      setWarning(false);
                      if (isTabClicked && isTabClicked.tab === "Cancel") {
                        navigate("/dashboard/clients", { replace: true });
                      }
                      setIsTabClicked({
                        isActive: false,
                        tab: null,
                      });
                    }
                  : isBreadcum
                  ? (e) => {
                      setIsClicked(true);
                      navigate(navigatePath, { replace: true });
                      setWarning(false);
                      setIsDirty(false);
                    }
                  : anchorClicked.isActive
                  ? () => {
                      const href = anchorClicked.event.target
                        .closest("[href]")
                        .getAttribute("href");
                      if (href) {
                        if (href === "https://wesoftek.com/") {
                          window.open(href, "_blank");
                        } else {
                          navigate(href, { replace: true });
                        }
                      }
                      setWarning(false);
                      setAnchorClicked({
                        isActive: false,
                        event: null,
                      });
                      setIsDirty(false);
                    }
                  : (e) => {
                      setIsClicked(true);
                      setWarning(false);
                      setIsDirty(false);
                      navigate("/dashboard/clients", { replace: true });
                    }
              }
            ></WarningModal>
          </>
        );
      }}
    </Formik>
  );
};

export default AddClients;
