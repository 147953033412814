import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Error from "../../../../atoms/Error";
import MyDropdown from "../../../../atoms/MyDropdown/index";
import MyInput from "../../../../atoms/MyInput/index";
import { countryCodeData } from "../../../../../helpers/countryCodes";
import {
  businessTypes,
  employeeTypes,
} from "../../../../../helpers/staticData";
import CustomButton from "../../../../atoms/CustomButton";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import { replaceSpacesInImageUrl } from "../../../../../helpers/replaceSpaceInImageUrl";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import { useCookies } from "react-cookie";
import {
  getData,
  postFormData,
  patchFormData,
  postData,
  patchData,
} from "../../../../../Services/index";
import dayjs from "dayjs";
import { statusData } from "../../../../../helpers/staticData";
// import { checkEmailExists } from "../../../../../services/basicGets";
import {
  capitalizeLetter,
  Obj2FormImage,
} from "../../../../../helpers/capatalizeLetters";
import { Heading } from "../../../../atoms/Heading";
import MyTextArea from "../../../../atoms/MyTextArea";
import Breadcrumb from "../../../../atoms/Breadcrumb";
import Modal from "../../../../atoms/modals/Modal";
import style from "./index.module.scss";
import Close from "../../../../icons/Close";
import EyeIcon from "../../../../icons/eye.icon";
import { getExtension } from "../../../../../Services/getExtensionOfLink";
import { currencyData } from "../../../../../helpers/currencyData";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import DownloadIcon from "../../../../icons/download";
import { extractFileName } from "../../../../../helpers/nameExtractor";
import WarningModal from "../../../../atoms/modals/warningModal";

const ImageSelectorStyle = {
  borderRadius: "10px",
  border: "1px solid #EFEFEF",
  padding: "10px",
  width: "200px",
  objectFit: "contain",
};

const AddBill = ({ type = "add" }) => {
  const { id } = useParams();

  const [cookies] = useCookies(["t", "bid", "eid", "mode", "cid"]);
  const getMode = cookies && cookies?.mode ? atob(cookies.mode) : "";
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewURL, setIsViewURL] = useState(false);
  const [fileUrls, setFileUrls] = useState([]);
  const [iframeSrc, setIframeSrc] = useState("");

  const [warning, setWarning] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isBreadcum, setIsBredcum] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [anchorClicked, setAnchorClicked] = useState({
    isActive: false,
    event: null,
  });
  const [isTabClicked, setIsTabClicked] = useState({
    isActive: false,
    tab: null,
  });
  const location = useLocation();
  const [navigatePath, setNavigatePath] = useState(null);
  const currentLocation = location.pathname;

  const handleBeforeUnload = (e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = "";
      return "";
    }
  };

  const handlePopState = (e) => {
    if (isDirty) {
      e.preventDefault();
      setWarning(true);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (isDirty && !isBreadcum && !isClicked) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", handlePopState);
      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("popstate", handlePopState);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isDirty]);

  useEffect(() => {
    function handleClickOutSide(event) {
      const href = event.target.closest("[href]");
      const button = event.target.closest("button")?.textContent;

      if (button === "Cancel") {
        if (isDirty) {
          setWarning(true);
          setIsTabClicked({
            isActive: true,
            tab: button,
          });
        }
        event.preventDefault();
      }

      if (href) {
        if (isDirty) {
          setWarning(true);
          setAnchorClicked({
            isActive: true,
            event: event,
          });
        }
        event.preventDefault();
      } else {
        console.log("Clicked outside.");
      }
    }
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [location, isDirty]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: `${getMode === "Client" ? "cbills" : "bills"}/getBillByID`,
        token: cookies.t,
        params: { id },
      });
      setInitialValues(res);
      setFileUrls(
        res.bill_url.map((url) => ({ url, name: extractFileName(url) }))
      );
    };
    const getCategoryList = async () => {
      const res = await getData({
        endpoint: "category/getCategoryList",
        token: cookies.t,
        params: { business_id: cookies.bid },
      });
      setCategoryData(res);
    };

    if (type === "edit") {
      fetchData();
    }
    getCategoryList();
  }, []);

  const initialData = {
    name: initialValues?.name || null,
    type: initialValues?.type || "",
    price: initialValues?.price || null,
    title: initialValues?.title || "",
    currency: initialValues?.currency || null,
    description: initialValues?.description || null,
    status: initialValues?.status || "New/Pending",
    business_id: cookies.bid,
    [getMode !== "Client" ? "employee_id" : "client_id"]:
      getMode !== "Client" ? cookies.eid : cookies.cid,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Category is required"),
    currency: Yup.string().required("Currency is required"),
    price: Yup.string().required("Price is required"),
    type: Yup.string().when("name", (value) => {
      if (value && subCategoryData && subCategoryData?.length > 0) {
        return Yup.string().required("Sub Categroy is required");
      }
      return Yup.string().notRequired();
    }),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);

    const formData = new FormData();

    Object.keys(values).forEach((key) => formData.append(key, values[key]));

    files.forEach((file) => formData.append("files", file));

    try {
      const res = await postFormData({
        endpoint: `${getMode === "Client" ? "cbills" : "bills"}/create`,
        token: cookies.t,
        data: formData,
      });
      if (res) {
        toast.success("Bill Created Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate("/dashboard/bills", { replace: true });
        setWarning(false);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    const data = {
      ...values,
      name: capitalizeLetter(values.name),
      business_name: capitalizeLetter(values.business_name),
      bill_url:
        fileUrls && fileUrls.length > 0 ? fileUrls.map((file) => file.url) : [],
    };

    const formData = new FormData();

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    files.forEach((file) => formData.append("files", file));

    try {
      const res = await patchFormData({
        endpoint: `${getMode === "Client" ? "cbills" : "bills"}/update`,
        token: cookies.t,
        data: formData,
        params: { id: id },
      });
      if (res) {
        toast.success("Bill Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate("/dashboard/bills", { replace: true });
        setWarning(false);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const handleRemoveUrlFile = (fileUrl) => {
    setFileUrls((prevUrls) =>
      prevUrls.filter((url) => {
        return url.url !== fileUrl;
      })
    );
  };

  const handleViewFile = (file) => {
    setSelectedFile(file);
    setIsModalOpen(true);
  };

  // const handleDownloadFile = (file) => {

  //   const fileUrl = file.url || file;
  //   console.log("FileUrl :",fileUrl);

  //   if (!fileUrl) {
  //     alert("File URL is not available.");
  //     return;
  //   }

  //   const link = document.createElement("a");
  //   link.href = fileUrl;
  //   link.download = file.name || file?.split("/").pop() || "download";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  // const handleDownloadFile = async (file) => {
  //   const fileUrl = file.url || file;
  //   console.log("FileUrl:", fileUrl);

  //   if (!fileUrl) {
  //     alert("File URL is not available.");
  //     return;
  //   }

  //   try {
  //     const response = await fetch(fileUrl, { method: "GET" });
  //     if (!response.ok) {
  //       throw new Error(`Error fetching file: ${response.statusText}`);
  //     }

  //     const blob = await response.blob();
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.download = file.name || "download";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(url); // Clean up
  //   } catch (error) {
  //     console.error("Download failed:", error.message);
  //     alert("Failed to download the file. Please try again later.");
  //   }
  // };

  // const handleDownloadFile = () => {
  //   const fileUrl = "https://example.com/sample-file.xlsx";
  //   const link = document.createElement("a");
  //   link.href = fileUrl;
  //   link.download = "sample-file.xlsx";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  // const handleDownloadFile = (file) => {
  //   const fileUrl = file.url || file;
  //   console.log("FileUrl:", fileUrl);

  //   if (!fileUrl) {
  //     alert("File URL is not available.");
  //     return;
  //   }

  //   // Use window.open as a fallback
  //   window.open(fileUrl, "_blank");
  // };
  // const handleDownloadFile = (file) => {
  //   let a = document.createElement("iframe");
  //   a.src = URL.createObjectURL(file);
  //   a.title = "Varun";
  //   document.body.appendChild(a);
  //   URL.revokeObjectURL(a.src);
  // };

  // const handleDownloadFile = (file) => {
  //   setIframeSrc(file.url || file);
  // };

  const handleDownloadFile = (file) => {
    const blobUrl = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = file.name || "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  };

  const handleDownloadFileURL = (file) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = file.name || "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleViewFileURL = (file) => {
    setSelectedFile(file);
    setIsModalOpen(true);
    setIsViewURL(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
    setIsViewURL(false);
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        validateForm,
      }) => {
        if (
          dirty !== isDirty ||
          files?.length > 0 ||
          initialValues?.bill_url?.length !== fileUrls?.length
        ) {
          setIsDirty(
            initialValues?.bill_url?.length !== fileUrls?.length
              ? true
              : files?.length > 0
              ? true
              : dirty
          );
        }
        return (
          <>
            <Breadcrumb
              labels={[
                "Bills",
                `${type === "add" ? "Create Bill" : "Update Bill"}`,
              ]}
              stepsToRemove={type === "add" ? 0 : 1}
              setNavigatePath={setNavigatePath}
              needToConfirm={isDirty}
              handlePopState={handlePopState}
              setWarning={setWarning}
              setIsBredcum={setIsBredcum}
            />
            <Heading className="h5 mb-1 mt-3">
              {type === "add" ? "Create Bill" : "Update Bill"}
            </Heading>
            <hr />
            <div
              className="d-flex flex-wrap w-100 mb-2"
              style={{ columnGap: "1.36%", rowGap: "16px" }}
            >
              <MyInput
                type="text"
                name="title"
                customholder="Title"
                width={screenWidth < 900 ? "100%" : "32.426%"}
              />

              <MyDropdown
                data={categoryData}
                selectedValue={values.name}
                name="name"
                placeholder={"Select Expense Category"}
                width={screenWidth < 900 ? "100%" : "32.426%"}
                required
                touched={touched.name}
                errors={errors.name}
                isError={errors.name}
                onChange={async (option) => {
                  setFieldValue("name", option);
                  const res = await getData({
                    endpoint: "category/getSubCategoryList",
                    token: cookies.t,
                    params: { id: option },
                  });
                  if (res) setSubCategoryData(res);
                  else setSubCategoryData([]);
                }}
                handleBlur={(e) => {
                  handleBlur(e);
                  setFieldTouched("name", true);
                }}
              />

              {subCategoryData && subCategoryData.length > 0 && (
                <MyDropdown
                  data={subCategoryData}
                  selectedValue={values.type}
                  name="type"
                  placeholder={"Select Sub Expense Category"}
                  width={screenWidth < 900 ? "100%" : "32.426%"}
                  onChange={async (option) => {
                    setFieldValue("type", option);
                  }}
                  required
                  touched={touched.type}
                  errors={errors.type}
                  isError={errors.type}
                  handleBlur={(e) => {
                    handleBlur(e);
                    setFieldTouched("type", true);
                  }}
                />
              )}

              {/* <MyInput
              type="text"
              name="type"
              customholder="Bill Type"
              width="32.426%"
            /> */}

              <MyDropdown
                data={currencyData}
                selectedValue={values.currency}
                name="currency"
                placeholder={"Select Currency"}
                width={screenWidth < 900 ? "100%" : "32.426%"}
                onChange={async (option) => {
                  setFieldValue("currency", option);
                }}
                required
                touched={touched.currency}
                errors={errors.currency}
                isError={errors.currency}
                handleBlur={(e) => {
                  handleBlur(e);
                  setFieldTouched("currency", true);
                }}
              />

              <MyInput
                type="text"
                name="price"
                customholder="Price"
                width={screenWidth < 900 ? "100%" : "32.426%"}
                onInput={(e) => {
                  const value = e.target.value;
                  e.target.value = value
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*?)\..*/g, "$1");
                }}
                allowSpecial
                required
              />

              <MyTextArea
                type="text"
                name="description"
                customholder="Description"
                style={{ width: screenWidth < 900 ? "100%" : "32.426%" }}
              />
            </div>
            <div>
              <Heading className="h5 mb-3 mt-3">
                Upload Supporting Documents
              </Heading>
              <hr />
              <div
                className="d-flex flex-wrap w-100 mb-2"
                style={{ columnGap: "1.36%", rowGap: "16px" }}
              >
                <div style={{ width: screenWidth < 900 ? "100%" : "32.426%" }}>
                  <ImageSelector
                    placeholder="Upload Supporting Documents"
                    handleFile={async (file) => {
                      setFiles((prevFiles) => [...file]);
                    }}
                    multiple
                    touched={touched.client_supporting_docs}
                    errors={errors.client_supporting_docs}
                    selectedFiles={files}
                    handleRemoveFile={handleRemoveFile}
                  />
                </div>

                {/* {fileUrls.map((file, index) => (
                <div
                  key={index}
                  style={{
                    width: screenWidth < 900 ? "100%" : "32.426%",
                    position: "relative",
                  }}
                >
                  <div className={style.imagesNameStyle}>
                    <span className={style.imagesName}>{file.name}</span>
                    <div
                      className={style.closeIcon}
                      onClick={() => handleViewFileURL(file.url)} // View the file (open the URL)
                      style={{
                        position: "absolute",
                        top: "8px",
                        right: "30px",
                        cursor: "pointer",
                      }}
                    >
                      <EyeIcon />
                    </div>
                    <div
                      className={style.closeIcon}
                      onClick={() => handleRemoveUrlFile(file.url)} // Remove the file from bill_url list
                      style={{
                        position: "absolute",
                        top: "8px",
                        right: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Close />
                    </div>
                  </div>
                </div>
              ))} */}
                {fileUrls.map((file, index) => {
                  const isDownloadableUrl = [
                    "pdf",
                    "doc",
                    "docx",
                    "xls",
                    "xlsx",
                    "ppt",
                    "pptx",
                    "txt",
                  ].includes(getExtension(file?.url));

                  return (
                    <div
                      key={index}
                      style={{
                        width: screenWidth < 900 ? "100%" : "32.426%",
                        position: "relative",
                      }}
                    >
                      <div className={style.imagesNameStyle}>
                        <span className={style.imagesName}>{file.name}</span>
                        <div
                          className={style.closeIcon}
                          onClick={() =>
                            isDownloadableUrl
                              ? handleDownloadFileURL(file.url)
                              : handleViewFileURL(file.url)
                          }
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "30px",
                            cursor: "pointer",
                          }}
                        >
                          {isDownloadableUrl ? <DownloadIcon /> : <EyeIcon />}
                        </div>
                        <div
                          className={style.closeIcon}
                          onClick={() => handleRemoveUrlFile(file.url)} // Remove the file from fileUrls list
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <Close />
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* {files.map((file, index) => (
                <div
                  key={index}
                  style={{
                    width: screenWidth < 900 ? "100%" : "32.426%",
                    position: "relative",
                  }}
                >
                  <div className={style.imagesNameStyle}>
                    <span className={style.imagesName}>
                      {file.name || file?.split("/").pop()}
                    </span>
                    <div
                      className={style.closeIcon}
                      onClick={() => handleViewFile(file)}
                      style={{
                        position: "absolute",
                        top: "8px",
                        right: "30px",
                        cursor: "pointer",
                      }}
                    >
                      <EyeIcon />
                    </div>
                    <div
                      className={style.closeIcon}
                      onClick={() => handleRemoveImage(file)}
                      style={{
                        position: "absolute",
                        top: "8px",
                        right: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Close />
                    </div>
                  </div>
                </div>
              ))} */}
                {files.map((file, index) => {
                  const fileType = file.type || file?.split(".").pop(); // Determine file type or extension
                  const isDownloadableType = [
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.ms-excel",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  ].includes(fileType);

                  return (
                    <div
                      key={index}
                      style={{
                        width: screenWidth < 900 ? "100%" : "32.426%",
                        position: "relative",
                      }}
                    >
                      <div className={style.imagesNameStyle}>
                        <span className={style.imagesName}>
                          {file.name || file?.split("/").pop()}
                        </span>
                        <div
                          className={style.closeIcon}
                          onClick={() =>
                            isDownloadableType
                              ? handleDownloadFile(file)
                              : handleViewFile(file)
                          }
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "30px",
                            cursor: "pointer",
                          }}
                        >
                          {isDownloadableType ? <DownloadIcon /> : <EyeIcon />}
                        </div>
                        <div
                          className={style.closeIcon}
                          onClick={() => handleRemoveFile(file)}
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <Close />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="d-flex gap-3 mt-4">
              {type === "add" ? (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleSubmit(values)}
                  disabled={!isValid || !dirty || isSaving}
                >
                  Create Bill
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              ) : (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleEdit(values)}
                  disabled={!isValid || isSaving || emailError}
                >
                  Update Bill
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              )}

              <CustomButton
                text="Cancel"
                iconRequired={false}
                handleClick={() => navigate(-1, { replace: true })}
              />
            </div>
            {isModalOpen && selectedFile && (
              <Modal onClose={handleCloseModal} isActive={isModalOpen}>
                {!isViewURL ? (
                  selectedFile.type.startsWith("image/") ? (
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Preview"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <iframe
                      src={URL.createObjectURL(selectedFile)}
                      title="Preview"
                      style={{ width: "100%", height: "500px" }}
                    />
                  )
                ) : getExtension(selectedFile) !== "pdf" ? (
                  <div>
                    <img
                      src={selectedFile}
                      title="Preview"
                      className={style.view_selected_image}
                    />
                  </div>
                ) : (
                  <iframe
                    src={selectedFile}
                    title="Preview"
                    style={{ width: "100%", height: "500px" }}
                  />
                )}
              </Modal>
            )}

            {/* 
          {iframeSrc && (
            <div>
              <iframe src={URL.createObjectURL(iframeSrc)}></iframe>
            </div>
          )} 
           */}

            <WarningModal
              isActive={warning}
              type=""
              title={"Changes Unsaved"}
              description="Are you need to leave the changes unsaved"
              onClose={() => {
                setWarning(false);

                if (isTabClicked.isActive) {
                  setIsTabClicked({
                    isActive: false,
                    tab: null,
                  });
                } else if (isBreadcum) {
                  setNavigatePath(null);
                  setIsBredcum(false);
                } else if (anchorClicked?.isActive) {
                  setAnchorClicked({
                    isActive: false,
                    event: null,
                  });
                } else {
                  window.history.pushState(
                    null,
                    null,
                    window.location.pathname
                  );
                }
              }}
              onClick={
                isTabClicked.isActive
                  ? () => {
                      setWarning(false);
                      if (isTabClicked && isTabClicked.tab === "Cancel") {
                        navigate("/dashboard/bills", { replace: true });
                      }
                      setIsTabClicked({
                        isActive: false,
                        tab: null,
                      });
                    }
                  : isBreadcum
                  ? (e) => {
                      setIsClicked(true);
                      navigate(navigatePath, { replace: true });
                      setWarning(false);
                      setIsDirty(false);
                    }
                  : anchorClicked.isActive
                  ? () => {
                      const href = anchorClicked.event.target
                        .closest("[href]")
                        .getAttribute("href");
                      if (href) {
                        if (href === "https://wesoftek.com/") {
                          window.open(href, "_blank");
                        } else {
                          navigate(href, { replace: true });
                        }
                      }
                      setWarning(false);
                      setAnchorClicked({
                        isActive: false,
                        event: null,
                      });
                      setIsDirty(false);
                    }
                  : (e) => {
                      setIsClicked(true);
                      setWarning(false);
                      setIsDirty(false);
                      navigate("/dashboard/bills", { replace: true });
                    }
              }
            ></WarningModal>
          </>
        );
      }}
    </Formik>
  );
};

export default AddBill;
