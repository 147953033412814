import { memo, useCallback, useEffect, useState } from "react";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import style from "./index.module.scss";
// import NoContentCard from "../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../Services";
import View from "../../../icons/View";
import EditBlue from "../../../icons/EditBlue";
import CustomButton from "../../../atoms/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import Delete from "../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import Modal from "../../../atoms/modals/DeleteModal";
import Edit from "../../../icons/EditBlue";
import CustomMenu from "../../../molecules/CustomMenu";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import FilterComponent from "../../../molecules/filters/FilterComponent";
// import { Rate_TypeData } from "../../../../../utils/staticData";
import EyeIcon from "../../../icons/eye.icon";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../Services/downloadService";
import moment from "moment";
import NoContentCard from "../../../atoms/NoContentCard";
import CardList from "./cardList";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import CustomDateRangePicker from "../../../atoms/DateRangePicker";
import CalenderIcon from "../../../icons/calender";
import DatePickerModal from "../../../atoms/DatePopOver";

const Bills = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const screenWidth = useScreenWidth();
  const [cookies] = useCookies(["t", "bid", "eid", "mode", "cid"]);
  const getMode = cookies && cookies?.mode ? atob(cookies.mode) : "";
  const [employeeData, setEmployeeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loader, setLoader] = useState(true);
  const [initalRender, setInitialRender] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [categoryData, setCategoryData] = useState([]);
  const [filters, setFilters] = useState({});
  const [activeRequestId, setActiveRequestId] = useState(null);
  const [date, setDate] = useState({ start_date: null, end_date: null });

  const handleDateChange = (selectedDates) => {
    if (selectedDates && selectedDates?.[0] && selectedDates?.[1])
      setDate({
        start_date: selectedDates?.[0],
        end_date: selectedDates?.[1],
      });
  };

  const menu = [
    {
      key: "view",
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        navigate(`view/${selectedRow?._id}`);
      },
    },
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`edit/${selectedRow?._id}`);
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.name,
          modalId: selectedRow._id,
        });
        setAnchorEl(null);
      },
    },
  ];

  useEffect(() => {
    const getCategoryList = async () => {
      const res = await getData({
        endpoint: "category/getCategoryList",
        token: cookies.t,
        params: { business_id: cookies.bid },
      });
      setCategoryData(res);
    };
    if (state && state.customState) {
      if (state.customState.filters) {
        setFilters(state.customState.filters);
        // GetemployeeData();
        navigate("/dashboard/bills", { state: null });
      }
    }
    setInitialRender(true);
    getCategoryList();
  }, []);

  const GetemployeeData = async () => {
    let finalStart, finalEnd;
    if (date && date?.start_date && date?.end_date) {
      const start_date = new Date(date?.start_date);
      const end_date = new Date(date?.end_date);
      finalStart = new Date(
        Date.UTC(
          start_date.getUTCFullYear(),
          start_date.getUTCMonth(),
          start_date.getUTCDate(),
          0, // 00:00:00 UTC
          0,
          0,
          0
        )
      ).toISOString();

      finalEnd = new Date(
        Date.UTC(
          end_date.getUTCFullYear(),
          end_date.getUTCMonth(),
          end_date.getUTCDate(),
          23, // 23:59:59.999 UTC
          59,
          59,
          999
        )
      ).toISOString();
    }
    setLoader(true);

    try {
      const res = await getData({
        endpoint: `${getMode === "Client" ? "cbills" : "bills"}`,
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          categories: filters?.["Category"],
          bill_status: filters?.["Status"],

          business_id: cookies.bid,
          start_date: finalStart ? finalStart : null,
          end_date: finalEnd ? finalEnd : null,
          [getMode !== "Client" ? "employee_id" : "client_id"]:
            getMode !== "Client" ? cookies.eid : cookies.cid,
        },
      });
      setEmployeeData(initalRender ? res?.data : null);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
      // setInitialRender(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    if (
      initalRender &&
      (itemsPerPage || currentPage || searchData || filters)
    ) {
      GetemployeeData();
    }
  }, [itemsPerPage, currentPage, searchData, filters, initalRender, date]);

  const handleEdit = (data) => {
    navigate(`/dashboard/setting/price/add/${data?._id}`);
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Category",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.categoryData && (
              <div>{params?.row?.categoryData?.category}</div>
            )}
          </>
        );
      },
    },
    {
      field: "type",
      headerName: "Sub Category",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return <>{params?.row?.type && <div>{params?.row?.type}</div>}</>;
      },
    },
    {
      field: "currecy",
      headerName: "Currency",
      flex: 1,
      renderCell: (params) => {
        return <>{params?.row?.currency && <div>{params.row.currency}</div>}</>;
      },
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      sortable: true,
    },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("YYYY-MM-DD")}</span>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row.status === "New/Pending" ? (
              <span className="text-warning fw-bold">Pending</span>
            ) : params?.row.status === "Booked" ? (
              <span className="text-success fw-bold">Closed</span>
            ) : params?.row.status === "Rejected" ? (
              <span className="text-error fw-bold">Rejected</span>
            ) : (
              <span className="text-dark fw-bold">Raised Query</span>
            )}
          </>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={menu}
            />
          </>
        );
      },
    },
  ];

  // const getMenuList = () => {
  //   if (roleData && roleData?.["add/edit"] && roleData?.delete) {
  //     return menu;
  //   } else if (roleData && roleData?.delete) {
  //     return [menu[1]];
  //   } else if (roleData && roleData?.["add/edit"]) {
  //     return [menu[0]];
  //   } else return [];
  // };

  const CustomNoRowsOverlay = memo(() => {
    return loader ? (
      <>
        {" "}
        <div
          className="w-100  d-flex align-items-center justify-content-center"
          style={{ height: "70vh" }}
        >
          <span
            className="spinner-border spinner-border-lr ms-2"
            style={{ color: "#3b424a", width: "50px", height: "50px" }}
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </>
    ) : (
      <>
        {!!(searchData || Object.keys(filters).length > 0) ? (
          <>
            <NoContentCard
              title="No Bills Found"
              tag="Create Bill"
              type="Pricing"
            />
          </>
        ) : (
          <>
            <NoContentCard
              title="No Bills created yet!"
              tag="Create Bill"
              type="Pricing"
              classes="d-flex align-items-center"
              subtitle="Currently no bill created yet click on a create bill button to create bill"
              handleClick={() => {
                navigate("add");
              }}
            />{" "}
          </>
        )}
      </>
    );
  });
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: `${getMode === "Client" ? "cbills" : "bills"}/delete`,
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Bill Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetemployeeData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    Status: [
      { label: "Pending", value: "New/Pending" },
      { label: "Closed", value: "Booked" },
      { label: "Raised Query", value: "Query Raised" },
      { label: "Rejected", value: "Rejected" },
    ],
    Category: categoryData,
  };

  return (
    <>
      <div
        className={`headingSearchWrap ${
          screenWidth < 768 ? "flex-wrap gap-2 w-100" : ""
        }`}
      >
        <div
          className={`${
            screenWidth < 768 ? "d-flex justify-content-between w-100" : ""
          }`}
        >
          <Heading className="HeadingStyle">Bills</Heading>
          <div className="none-button-responsive-add">
            <CustomButton
              text="Create Bill"
              type="btn-primary"
              handleClick={() => {
                navigate("add");
              }}
            />
          </div>
        </div>
        <div
          className={`searchButton ${
            screenWidth < 768 ? "w-100 d-flex justify-content-between mb-3" : ""
          }`}
        >
          {/* <Search
            onSearchEmit={(data) => {
              setSearchData(data);
              setCurrentPage(1);
            }}
          /> */}

          <FilterComponent
            filterData={filterData}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
            selectedFilters={filters}
            mode="Invoice"
            headingMobile={screenWidth < 600 ? true : false}
            positionLeft={screenWidth < 900 ? true : false}
          />
          {screenWidth > 750 ? (
            <CustomDateRangePicker setDate={setDate} date={date} />
          ) : (
            <div>
              <button
                style={{ background: "none", border: "none", outline: "none" }}
                onClick={() => setModalOpen(true)}
              >
                <CalenderIcon />
              </button>

              <DatePickerModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onDateChange={handleDateChange}
              />
            </div>
          )}
          <div className="none-button-responsive">
            <CustomButton
              text="Create Bill"
              type="btn-primary"
              handleClick={() => {
                navigate("add");
              }}
            />
          </div>
        </div>
      </div>
      {screenWidth > 900 ? (
        <CustomDatagrid
          rows={employeeData?.[0]?.roleData || []}
          getRowId={(row) => row?._id}
          columns={columns}
          loader={loader}
          height={() => 50}
          hoverColor="ffffff"
          CustomNoRowsOverlay={CustomNoRowsOverlay}
          onCellClick={(data) => {
            if (data?.field !== "actions" && data?.field !== "__check__") {
              navigate(`/dashboard/bills/view/${data?.id}`, {
                state: filters,
              });
            }
          }}
        />
      ) : employeeData && employeeData?.[0]?.roleData?.length > 0 ? (
        <CardList
          rows={employeeData?.[0]?.roleData || []}
          handleStatusMenu={handleStatusMenu}
          anchorEl={anchorEl}
          selectedRow={selectedRow}
          handleClose={handleClose}
          menu={menu}
        />
      ) : (
        <CustomNoRowsOverlay />
      )}
      {employeeData?.[0]?.pageDetails?.count > 0 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={employeeData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Bill"
      />
    </>
  );
};
export default Bills;
