import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import style from "./index.module.scss";
import TextInput from "../../../atoms/TextInput";
import MyDropdown from "../../../atoms/MyDropdown";
import { getData, patchData } from "../../../../Services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../assets/styles/components/toast";
import { Heading } from "../../../atoms/Heading";
import CustomButton from "../../../atoms/CustomButton";
import ChangePassword from "./change-password";
import MyInput from "../../../atoms/MyInput";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import { capitalizeLetter } from "../../../../helpers/capatalizeLetters";
import { expitationTime } from "../../../../helpers/expirationTime";
const EditProfile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const screenWidth = useScreenWidth();
  const [emailError, setEmailError] = useState(null);
  const [isEmailChecked, setIsEmailChecked] = useState(true);
  const [cookies, setCookie] = useCookies(["cid", "t"]);

  const initialValues = {
    name: atob(cookies?.un) || "",
    email: atob(cookies.email) || "",
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required"),
  });
  const handleSubmit = async (values) => {
    try {
      const res = await patchData({
        endpoint: "businessAuth/editProfile",
        token: cookies.t,
        params: {
          name: capitalizeLetter(values.name),
          email: atob(cookies?.email),
          updateEmail: values?.email?.toLowerCase(),
        },
      });
      if (res) {
        toast.success("Profile Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });

        if (res?.data?.data?.name) {
          setCookie("un", btoa(res?.data?.data?.name), {
            path: "/",
            expires: expitationTime(),
            // secure: true,
          });
        }
        // if (res?.data?.data?.email) {
        //   setCookie("email", btoa(res?.data?.data?.email), {
        //     path: "/",
        //     expires: expitationTime(),
        //     // secure: true,
        //   });
        // }
        window.location.reload();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const checkEmail = async (newVal) => {
    const response = await getData({
      endpoint: "businessAuth/checkEditEmail",
      token: cookies.t,
      params: { email: newVal },
    });
    if (response.status && response.data) {
      setEmailError("Email Already Exists");
      setIsEmailChecked(true);
    } else {
      setEmailError("");
      setIsEmailChecked(false);
    }
  };
  return (
    <>
      <Heading className="HeadingStyle mb-1">Edit Profile</Heading>
      <p className="mb-2">Change your profile details</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          setFieldValue,
          handleChange,
          handleBlur,
          touched,
          errors,
          values,
        }) => (
          <div className="edit-profile-div">
            <div className="row mt-3">
              <div className={`${screenWidth < 700 ? "w-100" : "col-6"}`}>
                <TextInput type="text" name="name" customholder="Name" />
              </div>
            </div>
            <div className="row mt-3">
              <div className={`${screenWidth < 700 ? "w-100" : "col-6"}`}>
                <MyInput
                  type="email"
                  id="email"
                  name="email"
                  totalWidth={true}
                  customholder="Enter Email"
                  customChange={(option) => {
                    if (initialValues?.email !== option && option.length > 5) {
                      checkEmail(option);
                    }
                  }}
                  blurError={emailError}
                  required
                  allowSpecial
                  disabled={true}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className={`${screenWidth < 700 ? "w-100" : "col-6"}`}>
                <div className="d-flex gap-3 justify-content-end">
                  <CustomButton
                    handleClick={() => navigate(-1)}
                    size="btn-lg"
                    text="Back"
                  />

                  <CustomButton
                    type="btn-primary"
                    handleClick={() => handleSubmit(values)}
                    size="btn-lg"
                    text="Update"
                    buttonType="submit"
                    disabled={emailError}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
export default EditProfile;
