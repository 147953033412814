import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Form from "./Form";
import { Heading } from "../../../atoms/Heading";

const Verification = () => {
  const navigate = useNavigate();
  const [allowBack, setAllowBack] = useState(false);
  // Back btn Handling ---------------------------------------------------------------------------------------------
  const onButtonEvent = (e) => {
    e.preventDefault();
    navigate("/auth/verify");
  };

  useEffect(() => {
    if (!allowBack) {
      window.history.pushState(null, "", window.location.pathname);
      window.addEventListener("popstate", onButtonEvent);
      return () => {
        window.removeEventListener("popstate", onButtonEvent);
      };
    }
  }, []);

  return (
    <div>
      <Heading className="h2">OTP</Heading>
      <div className="fs-16 fw-4 mb-2">
        Please enter the verification code sent to your email.
      </div>
      <Form allowBack={allowBack} setAllowBack={setAllowBack} />
    </div>
  );
};

export default Verification;
