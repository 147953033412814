const PendingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path
        d="m16.912,6.548c-.691-.52-1.563-.679-2.396-.439l-1.316.381c-.456.132-.863.367-1.2.674-.337-.307-.744-.542-1.2-.674l-1.316-.381c-.833-.239-1.704-.081-2.396.439-.691.52-1.088,1.313-1.088,2.179v7.594l6,1.72,6-1.72v-7.594c0-.865-.396-1.659-1.088-2.179Zm-5.912,9.125l-3-.86v-6.086c0-.23.105-.442.29-.58.184-.139.414-.182.637-.117l1.316.381c.445.129.757.543.757,1.006v6.256Zm5-.86l-3,.86v-6.256c0-.463.312-.877.757-1.006l1.316-.381c.221-.064.453-.021.637.117.185.138.29.35.29.58v6.086ZM12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,22c-5.514,0-10-4.486-10-10S6.486,2,12,2s10,4.486,10,10-4.486,10-10,10Z"
        fill="#3B424A"
      />
    </svg>
  );
};
export default PendingIcon;
