import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Lock from "../../icons/Lock";
import UpwardArrow from "../../icons/UpwardArrow";
import Bottomarrow from "../../icons/Bottomarrow";

const CustomNavLink = ({
  to,
  label,
  icon,
  isLocked,
  alternatePath,
  index,
  count,
  handleSideBar,
  isSideBarActive,
  setIsSideBarActive,
  isExpanded, // New prop to indicate if this submenu is expanded
  onExpand, // New prop to handle expanding this submenu
  children, // New prop for nested links
}) => {
  const location = useLocation();
  // const isExpanded = expandedMenu === to; // Check if this menu is expanded

  const isActive = () => {
    return to === "/dashboard"
      ? location.pathname === "/dashboard"
      : location.pathname.startsWith(to);
  };

  const handleExpandClick = () => {
    onExpand();
  };

  // Check if any child link is active
  const isChildActive = () => {
    if (children) {
      return React.Children.toArray(children).some((child) => {
        return location.pathname.startsWith(child.props.to);
      });
    }
    return false;
  };

  // Determine if the current link or any of its children are active
  const linkIsActive = isActive();

  const childActive = isChildActive();

  const applyActiveClass = () => {
    if (isSideBarActive) {
      return linkIsActive;
    } else {
      return childActive || (linkIsActive && icon);
    }
  };

  return (
    <>
      <li key={index} className={`${applyActiveClass() && "active-side"}`}>
        <div onClick={handleSideBar} className="sidebar-link-container">
          <NavLink
            to={isLocked ? alternatePath : to}
            className={`${linkIsActive && "sidebar-active-link"} sidebar-link`}
            onClick={(e) => {
              if (children) {
                e.preventDefault();
                handleExpandClick();
                if (!isSideBarActive && setIsSideBarActive) {
                  setIsSideBarActive(true);
                  handleExpandClick();
                }
              }
            }}
          >
            <div className="d-flex align-items-center">
              <i>{icon}</i>
              <span style={{ whiteSpace: "nowrap" }}>
                {isSideBarActive && label}
              </span>
              <span
                className={
                  label === "My Invites" && count > 0 ? "count-sidebar" : ""
                }
              >
                {label === "My Invites" && count > 0 ? count : ""}
              </span>
            </div>
            {children && isSideBarActive && (
              <div
                onClick={(e) => {
                  // e.stopPropagation(); // Prevents the parent click handler from being triggered
                  // handleExpandClick(); // Toggles the submenu
                }}
                // className="arrow-icon"
              >
                {isExpanded ? <UpwardArrow /> : <Bottomarrow />}
              </div>
            )}
            {isLocked && <Lock />}
          </NavLink>
        </div>
      </li>
      {isExpanded && isSideBarActive && (
        <div className="mt-2 sub-menu">{children}</div>
      )}
    </>
  );
};

export default CustomNavLink;
