import { Heading } from "../../atoms/Heading";

const TermsAndConditions = () => {
  return (
    <div className="p-2">
      <Heading className="HeadingStyle">TERMS AND CONDITIONS (T&amp;C)</Heading>
      <p>
        Welcome to E-Accountant, a centralized and intuitive platform tailored
        to help businesses efficiently track, manage, and approve employee
        expenses. E-Accountant provides a centralized solution that enables
        businesses to track, manage, and approve employee expenses while
        offering tools to manage client billing, invoicing, and expense
        categorization efficiently.
      </p>
      <p>
        With advanced features like user role management, invoice tracking, and
        insightful analytics, E-Accountant bridges the gap between businesses
        and financial transparency, fostering operational efficiency. For
        businesses, E-Accountant offers tools to onboard employees, manage
        expense categories, approve or reject claims, and generate financial
        reports in real-time. Clients can seamlessly upload bills, categorize
        expenses, and track their financial transactions, ensuring clarity and
        accountability.
      </p>
      <p>
        Both businesses and clients benefit from tailored dashboards,
        role-specific access, and streamlined workflows that improve
        collaboration and compliance. Through its web and mobile applications,
        E-Accountant is designed to support businesses, employees, and clients
        in achieving their financial goals while maintaining transparency and
        compliance with applicable regulations.
      </p>
      <p>
        By accessing or using E-Accountant, you agree to the terms and
        conditions outlined in this Agreement, which govern your use of our
        services. We are committed to providing a secure, seamless, and
        efficient platform tailored to your needs. By accessing or using the
        E-Accountant platform, you agree to comply with and be bound by these
        Terms and Conditions. If you do not agree to these terms, please refrain
        from using the platform. Only individuals who can form legally binding
        contracts under applicable law are eligible to use the platform.
      </p>
      <h3
        style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}
      >
        1. SCOPE
      </h3>
      <p>
        <strong>1.1.</strong> E-Accountant, its subsidiaries, affiliates, and
        related companies (collectively "E-Accountant") have created, own, and
        operate the E-Accountant platform, including its associated websites and
        mobile applications (each individually referred to hereunder as an
        "E-Accountant Platform", "Platform" and collectively, the "E-Accountant
        Platforms").
      </p>
      <p>
        <strong>1.2.</strong> The E-Accountant Platforms allow you ("You" or
        "Your") and other business professionals, employees, finance experts,
        accountants, users, and other interested parties (each, a "User" and
        collectively, "Users") to engage in a variety of activities, such as
        tracking, managing, and approving expenses, accessing tools for invoice
        and expense management, obtaining support for E-Accountant’s services,
        sharing feedback or suggestions for platform improvement, reviewing and
        analyzing financial data, and engaging in conversations and activities
        related to financial management.
      </p>
      <p>
        <strong>1.3.</strong> The E-Accountant Platforms include proprietary
        information, materials, and resources created and published by
        E-Accountant (“E-Accountant Materials”) and may also include content
        created by Users or third parties (“User Content”), such as text,
        images, photographs, graphics, audio and video, data, code, and software
        (collectively referred to as “Content”). Both E-Accountant Materials and
        User Content are collectively referred to herein as “Platform Content.”
      </p>
      <strong>
        {" "}
        By accessing or using the E-Accountant Platforms, you acknowledge that
        You have read, understood, and agree to abide by these Terms and
        Conditions, including the provisions outlined in this Scope.
      </strong>
      <h3
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        2. USER CONTENT/LICENSE TO E-ACCOUNTANT
      </h3>
      <p>
        <strong>2.1.</strong> E-Accountant does not claim ownership of the
        content that You post, upload, input, or submit to any E-Accountant
        Platform ("User Content"). By posting, transmitting, or uploading any
        User Content, including but not limited to expense data, invoices,
        bills, documentation, feedback, suggestions, ideas, or other materials
        to the E-Accountant Platform, You grant E-Accountant a perpetual,
        irrevocable (subject to applicable data protection laws), non-exclusive,
        worldwide, fully-paid-up, and royalty-free license to use such User
        Content without restrictions of any kind, and without any payment or
        further consideration to You or any third party.
      </p>
      <p>
        <strong>2.2.</strong> This license includes, but is not limited to, the
        right to:
      </p>
      <ul>
        <li>
          Reproduce, prepare derivative works, combine with other works, alter,
          translate, distribute, display, perform, license, and sublicense the
          User Content.
        </li>
        <li>
          Make, offer to sell, sell, lease, or otherwise distribute any User
          Content or products derived from it.
        </li>
        <li>
          Practice any method or functionality embodied in such User Content.
        </li>
      </ul>
      <p>
        <strong>2.3.</strong> By submitting User Content, you represent and
        warrant that You have the necessary rights, ownership, or authority to
        grant the license described above. You also affirm that the User Content
        does not infringe upon the rights of any third party.
      </p>
      <p>
        <strong>2.4.</strong> E-Accountant reserves the right to decide whether
        to publish or post User Content submitted by You. Even if E-Accountant
        elects to post or publish Your User Content, E-Accountant retains the
        sole discretion to remove it at any time, without prior notice or
        liability.
      </p>
      <p>
        <strong>2.5.</strong> You acknowledge that the relationship between You
        and E-Accountant is not confidential, fiduciary, or otherwise a special
        relationship. E-Accountant has the right, but not the obligation, to use
        Your name, likeness, biography, and other information provided by You in
        connection with any use of the User Content You submit. E-Accountant may
        continue to use this information even after the termination of Your
        account or access to the E-Accountant Platform, solely for purposes
        related to identifying the source of previously submitted User Content.
      </p>
      <p>
        <strong>2.6.</strong> Nothing in this section restricts or prohibits
        E-Accountant from creating or obtaining content or submissions similar
        to or competitive with the User Content You submit.
      </p>

      <div style={{ marginBottom: "20px" }}>
        <h3
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}
        >
          3. PERMISSIBLE USE OF E-ACCOUNTANT PLATFORM AND CONTENT
        </h3>
        <p style={{ marginBottom: "10px" }}>
          The E-Accountant Platform, including all E-Accountant Materials and
          User Content (collectively, "Platform Content"), is provided for Your
          lawful use in managing and reviewing expenses, invoices, and related
          financial activities. You agree to use the Platform solely for its
          intended purpose and in compliance with these Terms.
        </p>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
          3.1. Non-Commercial and Lawful Use:
        </h4>
        <p style={{ marginBottom: "10px" }}>
          <strong>3.1.1.</strong> Your use of the Platform Content must not be
          intended for or directed towards commercial advantage or monetary
          compensation outside the scope of the Platform's functionalities.
        </p>
        <p style={{ marginBottom: "10px" }}>
          <strong>3.1.2.</strong> You may not copy, modify, distribute,
          transmit, display, perform, publish, license, create derivative works
          from, transfer, rent, lease, or sell the Platform Content.
        </p>
        <p style={{ marginBottom: "10px" }}>
          <strong>3.1.3.</strong> You may, however, download, store, and display
          Platform Content on Your device solely to view, use, or print such
          content for informational purposes, provided that:
        </p>
        <ul style={{ marginBottom: "10px" }}>
          <li>
            The Platform Content is used for non-commercial, lawful purposes and
            is not copied, posted on any network, or otherwise published,
            broadcast, or distributed in any form or media; and
          </li>
          <li>
            The Platform Content is not modified or altered, including the
            removal of copyright or proprietary notices.
          </li>
        </ul>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
          3.2. Prohibited Activities:
        </h4>
        <p style={{ marginBottom: "10px" }}>
          You agree not to use the E-Accountant Platform in any manner that
          could damage, disable, overburden, or impair the Platform or interfere
          with another User’s use and enjoyment. This includes but is not
          limited to:
        </p>
        <ul style={{ marginBottom: "10px" }}>
          <li>
            <strong>3.2.1.</strong> Attempting to gain unauthorized access to
            accounts, systems, or networks connected to the Platform through
            hacking, password mining, or other means.
          </li>
          <li>
            <strong>3.2.2.</strong> Obtaining or attempting to obtain materials
            or information not intentionally made available through the
            Platform.
          </li>
          <li>
            <strong>3.2.3.</strong> Publishing, uploading, posting, emailing,
            transmitting, or otherwise making available any content that:
            <ul style={{ marginBottom: "10px" }}>
              <li>You do not have the right to make available;</li>
              <li>
                Is unlawful, harmful, vulgar, obscene, hateful, or otherwise
                objectionable;
              </li>
              <li>Infringes the intellectual property rights of any party;</li>
              <li>
                Includes unsolicited advertising, spam, or any other form of
                solicitation; or
              </li>
              <li>
                Contains malicious software such as viruses, Trojan horses,
                worms, or any other code designed to disrupt the Platform’s
                functionality or compromise its security.
              </li>
            </ul>
          </li>
          <li>
            <strong>3.2.4.</strong> Engaging in defamation, harassment, abuse,
            stalking, threats, or any violations of others' privacy, publicity,
            or business integrity rights.
          </li>
          <li>
            <strong>3.2.5.</strong> Impersonating any individual or entity or
            misrepresenting Your affiliation with a person or organization.
          </li>
          <li>
            <strong>3.2.6.</strong> Interfering with the servers, networks, or
            compliance requirements connected to the Platform.
          </li>
        </ul>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
          3.3. Accuracy of Invoices and Expenses:
        </h4>
        <p style={{ marginBottom: "10px" }}>
          <strong>3.3.1.</strong> You agree not to submit fake or fraudulent
          invoices, expense claims, or other financial data through the
          Platform.
        </p>
        <p style={{ marginBottom: "10px" }}>
          <strong>3.3.2.</strong> All submitted expenses must be truthful,
          verifiable, and correspond to actual payments made.
        </p>
        <p style={{ marginBottom: "10px" }}>
          <strong>3.3.3.</strong> E-Accountant reserves the right to audit or
          verify the authenticity of submitted invoices and expenses. Any
          discrepancies or fraudulent submissions may result in account
          suspension, termination, or legal action.
        </p>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <h3
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}
        >
          4. TERMINATION OF USE
        </h3>
        <p style={{ marginBottom: "10px" }}>
          E-Accountant may, at its sole discretion, at any time, discontinue
          providing or limit access to the E-Accountant Platform, any areas
          within the Platform, or any Platform Content (including E-Accountant
          Materials or User Content) provided through the Platform. You agree
          that E-Accountant may, at its sole discretion, terminate or limit Your
          access to, or use of, any or all parts of the Platform or Platform
          Content for any reason, including but not limited to a breach of these
          Terms.
        </p>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
          4.1. Termination by E-Accountant:
        </h4>
        <p style={{ marginBottom: "10px" }}>
          E-Accountant may, without prior notice, suspend or terminate Your
          account, delete any or all User Content submitted by You, and refuse
          You any current or future access to the Platform if You are in
          material breach of these Terms. Such suspension or termination will be
          at E-Accountant's sole discretion and without liability to You or any
          third party. Grounds for termination may include, but are not limited
          to, submission of fraudulent or fake invoices or expense claims,
          unauthorized use of the Platform or its content, or violation of
          applicable laws or regulations.
        </p>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
          4.2. Termination by User:
        </h4>
        <p style={{ marginBottom: "10px" }}>
          If You wish to terminate Your account, you may submit a request via
          email to [info@wechartered.com]. Upon receiving Your termination
          request, E-Accountant will process the account closure within a
          reasonable timeframe, subject to the resolution of any outstanding
          matters, such as unpaid fees, fraudulent activity, or ongoing
          investigations.
        </p>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
          4.3. Retention of Data:
        </h4>
        <p style={{ marginBottom: "10px" }}>
          In the event of account termination, whether initiated by You or
          E-Accountant, E-Accountant reserves the right to retain certain User
          Content or account data as necessary to comply with applicable legal,
          regulatory, or audit obligations and to preserve the integrity of
          financial or transactional records. Additionally, E-Accountant may
          continue to display personally identifying information associated with
          User Content previously submitted by You for purposes such as
          identifying the source of the content or maintaining historical
          records of the Platform, in accordance with these Terms and applicable
          laws.
        </p>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
          4.4. Consequences of Termination:
        </h4>
        <p style={{ marginBottom: "10px" }}>
          Upon termination of Your account, all rights granted to You to access
          or use the Platform will immediately cease, and any licenses granted
          under these Terms will be automatically revoked. Termination does not
          absolve You of any obligations to pay outstanding fees or to resolve
          any disputes or liabilities arising prior to termination. All such
          obligations will remain enforceable and binding, notwithstanding the
          termination of Your account.
        </p>
      </div>

      {/* Section 5 - Confidential Information */}
      <div style={{ marginBottom: "20px" }}>
        <h3
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}
        >
          5. CONFIDENTIAL INFORMATION
        </h3>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>5.1.</h4>
        <p style={{ marginBottom: "10px" }}>
          Both E-Accountant and You agree to respect and maintain the
          confidentiality of all information disclosed by the other party that
          is clearly marked as confidential or reasonably identifiable as
          confidential based on its nature and the manner of disclosure
          ("Confidential Information"). Confidential Information may include
          trade secrets, intellectual property, financial data, or any other
          information that the disclosing party protects against unrestricted
          disclosure.
        </p>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>
          5.2. Obligations Regarding Confidential Information:
        </h4>
        <p style={{ marginBottom: "10px" }}>Both parties agree to:</p>
        <ul style={{ marginBottom: "10px", paddingLeft: "20px" }}>
          <li>
            5.2.1. Take all reasonable steps to keep the Confidential
            Information of the other party strictly confidential.
          </li>
          <li>
            5.2.2. Use the Confidential Information only for purposes explicitly
            authorized at the time of disclosure.
          </li>
          <li>
            5.2.3. Not disclose the Confidential Information to any third party
            without the prior written consent of the disclosing party.
          </li>
          <li>
            5.2.4. Reproduce Confidential Information only as necessary for the
            authorized purpose and ensure that any reproductions include all
            original proprietary notices or legends.
          </li>
        </ul>
      </div>

      {/* Section 6 - Intellectual Property Rights */}
      <div style={{ marginBottom: "20px" }}>
        <h3
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}
        >
          6. INTELLECTUAL PROPERTY RIGHTS
        </h3>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>6.1.</h4>
        <p style={{ marginBottom: "10px" }}>
          All rights, title, and interest in and to any content, data, or
          materials that You upload, submit, or provide to the E-Accountant
          Platform, including but not limited to expense records, invoices,
          reports, and other User Content (collectively, "Your Content"), shall
          remain Your exclusive property. By submitting Your Content, you
          represent and warrant that You own all necessary rights to Your
          Content or have obtained all required permissions to use and share it
          on the Platform.
        </p>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>6.2.</h4>
        <p style={{ marginBottom: "10px" }}>
          E-Accountant retains all rights, title, and interest in and to the
          E-Accountant Platform, including but not limited to its software,
          design, functionality, trademarks, service marks, copyrights, trade
          secrets, and any proprietary content provided by E-Accountant
          ("E-Accountant Materials"). Nothing in these Terms shall transfer or
          assign ownership of the E-Accountant Materials or Platform to You.
        </p>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>6.3.</h4>
        <p style={{ marginBottom: "10px" }}>
          By uploading or submitting Your Content to the Platform, you grant
          E-Accountant a non-exclusive, worldwide, royalty-free, transferable,
          and sublicensable license to use, process, store, display, distribute,
          and otherwise make Your Content available solely for the purposes of
          providing and improving the Platform’s functionality and related
          services. This license is limited to the duration of Your use of the
          Platform and terminates upon deletion of Your Content or account,
          except as necessary to comply with legal or regulatory obligations.
        </p>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>6.4.</h4>
        <p style={{ marginBottom: "10px" }}>
          Subject to Your compliance with these Terms, E-Accountant grants You a
          limited, non-exclusive, non-transferable, revocable license to access
          and use the E-Accountant Platform and E-Accountant Materials solely
          for Your personal or business purposes as permitted under these Terms.
          This license does not grant You the right to:
        </p>
        <ul style={{ marginBottom: "10px", paddingLeft: "20px" }}>
          <li>
            6.4.1. Copy, modify, distribute, display, or perform any
            E-Accountant Materials.
          </li>
          <li>
            6.4.2. Create derivative works from the E-Accountant Platform or
            Materials.
          </li>
          <li>
            6.4.3. Use the E-Accountant Platform or Materials for any purpose
            not expressly authorized by these Terms.
          </li>
        </ul>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>6.5.</h4>
        <p style={{ marginBottom: "10px" }}>Both parties acknowledge that:</p>
        <ul style={{ marginBottom: "10px", paddingLeft: "20px" }}>
          <li>
            6.5.1. Your Content remains Your intellectual property, and
            E-Accountant will not claim ownership of it.
          </li>
          <li>
            6.5.2. The E-Accountant Platform, including all related software,
            features, and content, is the sole property of E-Accountant.
          </li>
        </ul>
        <h4 style={{ fontSize: "16px", fontWeight: "bold" }}>6.6.</h4>
        <p style={{ marginBottom: "10px" }}>
          E-Accountant reserves all rights not expressly granted to You under
          these Terms. Similarly, you retain all rights not expressly granted to
          E-Accountant with respect to Your Content.
        </p>
      </div>

      <h3
        style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}
      >
        7. RESPONSIBILITY FOR CONTENT AND LINKS
      </h3>
      <p>
        <strong>7.1. Responsibility for Content:</strong> To the fullest extent
        permitted by applicable law, your use of Content on the E-Accountant
        Platform is at Your sole risk. All Content, whether publicly posted or
        privately transmitted, is the sole responsibility of the individual or
        entity providing it. E- Accountant does not endorse or guarantee the
        accuracy, reliability, completeness, usefulness, quality, or
        non-infringement of any Content provided by third parties on the
        Platform. E- Accountant reserves the right, at its sole discretion, to
        remove any Content posted on the Platform. If You believe material on
        the Platform infringes Your copyright, please notify E- Accountant by
        providing the following written information:
      </p>
      <p>
        If You believe material on the Platform infringes Your copyright, please
        notify E- Accountant by providing the following written information:{" "}
        <br />
        • An electronic or physical signature of the person authorized to act on
        behalf of the copyright owner.
        <br />
        • A description of the copyrighted work claimed to be infringed (or a
        representative list, in the case of multiple infringements).
        <br />
        • A description of the material claimed to be infringing and its
        location on the Platform.
        <br />
        • Your address, telephone number, and email address.
        <br />
        • A statement by You, made in good faith, that the use of the material
        is not authorized by the copyright owner, its agent, or the law.
        <br />
        • A statement by You, made under penalty of perjury, that the
        information in Your notification is accurate and that You are the
        copyright owner or authorized to act on behalf of the copyright owner.
        <br />
        Notifications should be sent to: <br />
        By Mail: Legal Department, [Unit No. 105, 1st Floor, IRIS-Tech Park,
        Sector-48, Sohna Road, Gurgaon, Haryana, India, 122018]
        <br />
        By Email: info@wechartered.com
      </p>

      <p>
        <strong>7.2. Links to Third-Party Websites:</strong> The E-Accountant
        Platform may contain links to external websites or resources provided by
        third parties. E-Accountant does not control or monitor the content or
        functionality of these linked websites and does not endorse or assume
        any responsibility for their content, products, or services.
        E-Accountant shall not be liable for any damages or losses, direct or
        indirect, arising from Your use of or reliance on any software, content,
        goods, or services accessed through such linked websites. You
        acknowledge that Your use of any third-party website is subject to the
        terms and conditions and privacy policies of that website.
      </p>

      <p>
        <strong>7.3. Limitation of Liability:</strong> E-Accountant is not
        responsible for any changes or updates to third-party websites or for
        any issues arising from Your interaction with third-party content. You
        agree to hold E-Accountant harmless from any liability related to
        third-party websites accessed via the Platform.
      </p>

      <h3
        style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}
      >
        8. INDEMNITY
      </h3>
      <p>
        <strong>8.1. </strong> You agree to indemnify, defend, and hold harmless
        E-Accountant, its affiliates, officers, directors, employees, agents,
        licensors, and service providers (collectively, the "Indemnified
        Parties") from and against any and all claims, demands, losses,
        liabilities, damages, costs, and expenses (including reasonable
        attorneys' fees) arising out of or related to: <br />
        8.1.1. Your Use of the Platform: Any use of the E-Accountant Platform,
        E-Accountant Materials, or services that violates these Terms. <br />
        8.1.2. Your Content: Any claim that Your Content infringes or
        misappropriates the intellectual property rights, confidentiality
        rights, or privacy rights of any third party. <br />
        8.1.3. Violation of Applicable Laws: Any breach by You of applicable
        laws, regulations, or the rights of a third party in connection with
        Your use of the Platform. <br />
        8.1.4. Misrepresentation or Fraud: Submission of fraudulent invoices,
        expense claims, or other misrepresentations made by You on or through
        the Platform.{" "}
      </p>

      <p>
        <strong>8.2. </strong> E-Accountant reserves the right, at its own
        expense, to assume the exclusive defense and control of any matter
        otherwise subject to indemnification by You, in which event You agree to
        cooperate with E-Accountant in asserting any available defenses. This
        indemnity obligation will survive the termination or expiration of these
        Terms and Your use of the Platform.
      </p>

      <h3
        style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}
      >
        9. WARRANTY AND DISCLAIMER
      </h3>
      <p>
        <strong>9.1. Warranty for Services:</strong> E-Accountant warrants that
        it shall:
        <br /> (i) Provide the services in a professional and workmanlike
        manner, in compliance with applicable laws and regulations. <br />
        (ii) Not materially decrease the overall functionality of the Platform
        during the term of the applicable agreement.
      </p>

      <p>
        <strong>9.2. User Responsibility and Data:</strong> <br />
        9.2.1. E-Accountant shall not be liable for any claims, losses, or
        damages caused by errors or omissions in data, information, or content
        provided by You, third parties, or other Users in connection with the
        use of the Platform. <br />
        9.2.2. E-Accountant will not be responsible for claims, losses, or
        damages arising from lost or corrupted data, provided that E-Accountant
        will use commercially reasonable efforts to restore such data from its
        latest backup if available. Furthermore, E-Accountant shall not be
        liable for any misuse of the Platform or violations of acceptable use
        policies by Users, including but not limited to fraudulent submissions,
        misuse of corporate resources, or violations of expense policies. <br />
        9.2.3. The Platform may provide insights, analysis, or recommendations
        (&quot;Content&quot;) using automated tools, including machine learning
        or artificial intelligence technologies. Such Content is for
        informational purposes only, may be incomplete or inaccurate, and is not
        a substitute for independent professional advice. E-Accountant disclaims
        all liability for reliance on such Content.
      </p>
      <p>
        <strong>9.3. General Disclaimer</strong> <br />
        E-Accountant makes no representations or warranties of any kind, whether
        express, implied, statutory, or otherwise. Specifically, E-Accountant
        disclaims:
        <br />
        9.3.1. Any warranty that the Platform will be error-free, uninterrupted,
        or that all errors will be corrected. <br />
        9.3.2. Any warranties of merchantability, accuracy, fitness for a
        particular purpose, or non-infringement. <br />
        9.3.3. Any warranties arising from course of dealing, course of
        performance, or usage of trade. <br /> 9.3.4. Any warranties or
        assurances regarding professional advice, including tax or financial
        advice, obtained through the Platform.
      </p>

      <h3
        style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}
      >
        10. GOVERNING LAW AND DISPUTES
      </h3>
      <p>
        <strong>10.1. Governing Law:</strong> This Agreement and all matters
        related to it shall be governed by and construed in accordance with the
        laws of the Republic of India, without regard to its conflict of law
        provisions. Any disputes arising under or in connection with this
        Agreement shall be subject to the exclusive jurisdiction of the courts
        located in [Insert City], India. The parties irrevocably submit to the
        jurisdiction of such courts.
      </p>

      <p>
        <strong>10.2. Informal Resolution:</strong> The parties shall endeavor
        to resolve any disputes, controversies, or claims arising out of or
        relating to this Agreement through good faith negotiations. If the
        dispute is not resolved within 30 days of the initial notice, either
        party may pursue formal resolution as set forth below.
      </p>

      <p>
        <strong>10.3. Agreement to Arbitrate:</strong> Any claims or disputes
        arising out of or relating to this Agreement, including its breach,
        termination, or validity, shall be settled through final and binding
        arbitration in accordance with the Arbitration and Conciliation Act,
        1996, as amended. The arbitration shall be conducted by a sole
        arbitrator mutually agreed upon by the parties, or if no agreement is
        reached, appointed in accordance with the Act. The arbitration
        proceedings shall take place in Gurgaon, Haryana, India, and the
        language of the arbitration shall be English.Each party shall bear its
        own costs in connection with the arbitration, and the arbitrator&#39;s
        decision shall be final and binding on the parties.
      </p>

      <h3
        style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}
      >
        11. GENERAL
      </h3>
      <p>
        <strong>11.1. Waiver:</strong> The failure of either party to enforce
        any provision of this Agreement shall not constitute a waiver of that
        provision or any other provision. Any waiver must be in writing and
        signed by the waiving party to be enforceable.
      </p>

      <p>
        <strong>11.2. Notices:</strong> Any notices required under this
        Agreement shall be sent to the other party: (i) by email to the address
        provided by the recipient party; or (ii) by registered post or courier
        to the address of the recipient party specified in the Agreement.Notices
        sent via email shall be deemed delivered on the date of transmission,
        provided no delivery failure notice is received.
      </p>

      <p>
        <strong>11.3. Severability:</strong> If any provision of this Agreement
        is held to be invalid or unenforceable under applicable law, the
        remaining provisions shall remain valid and enforceable.The invalid
        provision shall be replaced with a provision that best achieves the
        original intent of the parties.
      </p>

      <p>
        <strong>11.4. Force Majeure:</strong> Neither party shall be held liable
        for any delay or failure in performance caused by events beyond its
        reasonable control, including but not limited to acts of God, natural
        disasters, governmental actions, labor strikes, cyberattacks, or other
        unforeseeable events ("Force Majeure Events").The affected party shall
        notify the other party of the Force Majeure Event and use commercially
        reasonable efforts to resume performance as soon as possible.
      </p>

      <p>
        <strong>11.5. Compliance with Laws:</strong> Each party agrees to comply
        with all applicable laws, including but not limited to anti-corruption
        laws, data protection laws, and applicable export control regulations in
        connection with its obligations under this Agreement.
      </p>

      <p>
        <strong>11.6. Relationship Between the Parties:</strong> This Agreement
        does not create any partnership, joint venture, or agency relationship
        between the parties.Neither party has the authority to bind the other or
        incur obligations on its behalf without prior written consent.
      </p>

      <p>
        <strong>11.7. Assignment:</strong> Neither party may assign or transfer
        this Agreement, in whole or in part, without the prior written consent
        of the other, except in the case of a merger, acquisition, or sale of
        all or substantially all of its assets.Any attempted assignment in
        violation of this section shall be void.
      </p>

      <p>
        <strong>11.8. Entire Agreement:</strong> This Agreement, along with any
        annexures, addenda, or related service orders, constitutes the entire
        agreement between the parties concerning its subject matter and
        supersedes all prior agreements or understandings, written or oral.No
        modifications or amendments to this Agreement shall be effective unless
        made in writing and signed by authorized representatives of both
        parties.
      </p>
    </div>
  );
};

export default TermsAndConditions;
