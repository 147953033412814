import React, { useState } from "react";
import moment from "moment";
import "./Card.css"; // Optional CSS for styling
import CustomMenu from "../../../molecules/CustomMenu";
import HorizontalMenu from "../../../icons/HorizontalMenu";

const CardView = ({
  row,
  handleStatusMenu,
  menu,
  anchorEl,
  selectedRow,
  handleClose,
}) => {
  return (
    <div className="card-container">
      <div className="card-header">
        <span
          type="button"
          onClick={(event) => handleStatusMenu(event, row)}
          className="card-action-icon"
        >
          <HorizontalMenu />
        </span>
      </div>

      <div className="card-body">
        <div className="card-item">
          <span className="card-label">Category:</span>
          <span className="card-value">
            {row?.categoryData?.category || ""}
          </span>
        </div>
        <div className="card-item">
          <span className="card-label">Sub Category:</span>
          <span className="card-value">{row?.type || ""}</span>
        </div>
        <div className="card-item">
          <span className="card-label">Currency:</span>
          <span className="card-value">{row?.currency || ""}</span>
        </div>
        <div className="card-item">
          <span className="card-label">Price:</span>
          <span className="card-value">{row?.price || ""}</span>
        </div>
        <div className="card-item">
          <span className="card-label">Created On:</span>
          <span className="card-value">
            {moment(row?.createdAt).format("YYYY-MM-DD")}
          </span>
        </div>
        <div className="card-item">
          <span className="card-label">Status:</span>
          <span className={`card-value ${getStatusClass(row?.status)}`}>
            {getStatusLabel(row?.status)}
          </span>
        </div>
      </div>

      <CustomMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && selectedRow === row}
        onClose={handleClose}
        items={menu}
      />
    </div>
  );
};

const getStatusLabel = (status) => {
  switch (status) {
    case "New/Pending":
      return "Pending";
    case "Booked":
      return "Closed";
    case "Rejected":
      return "Rejected";
    default:
      return "Raised Query";
  }
};

const getStatusClass = (status) => {
  switch (status) {
    case "New/Pending":
      return "text-warning";
    case "Booked":
      return "text-success";
    case "Rejected":
      return "text-error";
    default:
      return "text-dark";
  }
};

export default CardView;
