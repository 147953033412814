import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useParams } from "react-router-dom";
import { getData } from "../../../../../Services";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
// import Breadcrumb from "../../../../molecules/Breadcrumb";
import moment from "moment";
import Breadcrumb from "../../../../atoms/Breadcrumb";
import { getExtension } from "../../../../../Services/getExtensionOfLink";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import FileViewer from "react-file-viewer";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

export const LabelDisplay = ({
  label,
  value,
  className,
  modalStyles = false,
}) => {
  return (
    <div
      className={`d-flex ${className}`}
      style={{ width: modalStyles ? "90%" : "100%" }}
    >
      {label && (
        <label className={style.label_view} style={{ width: "30%" }}>
          {label}
        </label>
      )}{" "}
      <div style={{ width: "15%" }}>:</div>
      <div className={style.label_description} style={{ width: "50%" }}>
        {value}
      </div>
    </div>
  );
};

const ViewBill = ({ type = "" }) => {
  const { id } = useParams();
  const [counterData, setCounterData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [cookies] = useCookies(["t", "cid", "eid"]);
  const location = useLocation();
  const state = location.state;
  const screenWidth = useScreenWidth();
  const getMode = cookies && cookies?.mode ? atob(cookies.mode) : "";
  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: `${getMode === "Client" ? "cbills" : "bills"}/getBillByID`,
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setCounterData(res);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id && !selectedFile) {
      getCounterDataById();
    }
    if (counterData?.bill_url?.length > 0) {
      setSelectedFile(counterData.bill_url[0]);
    }
  }, [id]);

  useEffect(() => {
    if (counterData?.bill_url?.length > 0) {
      setSelectedFile(counterData.bill_url[0]);
    }
  }, [id, counterData]);

  return (
    <>
      <Breadcrumb
        labels={["Bill", "View Bill"]}
        excludeLastPath
        customState={{ filters: state }}
      />
      <Heading className="addHeaderStyle fs-20">View Bill</Heading>
      <div
        className={`d-flex w-100 ${screenWidth < 900 ? " flex-column" : ""}`}
      >
        <div
          className="d-flex flex-column flex-wrap mb-3"
          style={{
            columnGap: "0%",
            rowGap: "10px",
            width: screenWidth < 900 ? "100%" : "30%",
          }}
        >
          <LabelDisplay label="Category" value={counterData?.category_name} />
          <LabelDisplay label="Sub Category" value={counterData?.type} />
          <LabelDisplay label="Price" value={counterData?.price} />
          <LabelDisplay label="Description" value={counterData?.description} />
          {counterData.status && (
            <LabelDisplay
              label="Status"
              value={
                counterData?.status === "New/Pending"
                  ? "Pending"
                  : counterData?.status === "Booked"
                  ? "Closed"
                  : counterData?.status === "Rejected"
                  ? "Rejected"
                  : "Raised Query"
              }
            />
          )}
          {counterData.reason &&
            (counterData?.status === "Rejected" ||
              counterData?.status === "Query Raised") && (
              <LabelDisplay label="Reason" value={counterData.reason} />
            )}
        </div>
        {counterData?.bill_url && counterData?.bill_url.length > 0 && (
          <div>
            <label className={style.label_view}>Bill Files</label>
            <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
              {counterData?.bill_url?.map((url, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedFile(url)}
                  style={{
                    padding: "8px 12px",
                    background: "#146ef5",
                    color: "white",
                    border: selectedFile === url ? "1px solid white" : "none",
                    outline:
                      selectedFile === url ? "1px solid #146ef5" : "none",
                    borderRadius: "4px",
                  }}
                >
                  {index + 1}
                </button>
              ))}
            </div>
            {selectedFile &&
              (getExtension(selectedFile) === "docx" ||
              getExtension(selectedFile) === "doc" ||
              getExtension(selectedFile) === "xlsx" ||
              getExtension(selectedFile) === "xls" ||
              getExtension(selectedFile) === "txt" ||
              getExtension(selectedFile) === "ppt" ? (
                <DocViewer
                  documents={[
                    {
                      uri: selectedFile,
                      fileType: getExtension(selectedFile),
                    },
                  ]}
                  pluginRenderers={DocViewerRenderers}
                  style={{
                    height: "700px",
                    width: "700px",
                    overflow: "hidden",
                  }}
                />
              ) : getExtension(selectedFile) !== "pdf" ? (
                <>
                  <img
                    src={selectedFile}
                    title="Preview"
                    className={style.view_selected_image}
                  />
                </>
              ) : (
                <iframe
                  width={700}
                  height={700}
                  style={{
                    border: "none",
                    overflow: "hidden",
                  }}
                  src={selectedFile}
                  title="Preview"
                  className={style.view_selected_image}
                />
              ))}
          </div>
        )}
      </div>
    </>
  );
};
export default ViewBill;
