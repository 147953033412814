import { Heading } from "../../atoms/Heading";

const Privacy = () => {
  return (
    <>
      <div className="p-2">
        <div style={{ marginBottom: "20px" }}>
          <Heading className="HeadingStyle"> Privacy Policy</Heading>
          <p style={{ marginBottom: "10px" }}>
            Effective Date: 10-12-2024 <br />
            Last Updated: 10-12-2024
          </p>
          <p style={{ marginBottom: "10px" }}>
            We, E-Accountant ("we," "us"), value the trust placed in us by You
            and therefore adhere to the highest standards of privacy guidelines
            to protect the information You share with us. This Privacy Policy
            ("Privacy Policy") governs the use of Your Personal Information (as
            defined below) shared with or collected by E-Accountant from users
            of the E-Accountant platform, including the E-Accountant website and
            mobile applications ("E-Accountant").
          </p>
          <p style={{ marginBottom: "10px" }}>
            By accessing or using any content, services, or features of
            E-Accountant, You accept and agree to the collection, use, storage,
            processing, modification, access, deletion, availability, and/or
            transfer ("Processing") of Your personal information as described in
            this Privacy Policy. If You disagree with any part of this Policy,
            You should not access, browse, or download content from
            E-Accountant. By merely browsing or using the E-Accountant platform
            or its applications, You consent to the handling of Your personal
            information as outlined in this Privacy Policy.
          </p>
          <p style={{ marginBottom: "10px" }}>
            This Privacy Policy is incorporated by reference into and should be
            interpreted alongside the Terms and Conditions. Any capitalized
            terms not explicitly defined in this document will carry the
            meanings assigned to them in the Terms and Conditions.
          </p>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            E-Accountant Services
          </h3>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant offers web and mobile applications (collectively,
            “E-Accountant Platform”, “Platform”) designed to streamline expense
            management and financial processes for businesses and their clients.
            These Apps provide a centralized platform to enhance financial
            transparency, efficiency, and collaboration, with features that
            include:
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>
              <strong>Expense Tracking and Management:</strong> Submit, track,
              and reconcile business expenses, ensuring clarity for both
              businesses and clients.
            </li>
            <li>
              <strong>Payments and Reimbursements:</strong> Link corporate and
              personal accounts for reimbursement, track transactions, and
              manage payment workflows for both businesses and clients.
            </li>
            <li>
              <strong>Client Management:</strong> Enable businesses to onboard
              clients, manage client-specific expense categories, and generate
              detailed reports tailored to client requirements.
            </li>
            <li>
              <strong>Corporate Administrative Features:</strong> Access tools
              for real-time reporting, spend analysis, role management, and
              compliance tracking.
            </li>
            <li>
              <strong>Customer and Client Support:</strong> Access dedicated
              support for resolving queries and addressing specific client or
              user needs.
            </li>
          </ul>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            1. Collection and Purpose of Use of Personal Information
          </h3>
          <p style={{ marginBottom: "10px" }}>
            We gather information that you directly provide us, along with data
            collected through your use of the E-Accountant Platform and activity
            logs. We also collect data through cookies and similar technologies,
            as detailed below.
          </p>
          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            a. Data Provided by the User
          </h4>
          <p style={{ marginBottom: "10px" }}>
            We collect your personal details, including but not limited to your
            name, email address, contact number, business details such as GST
            numbers, primary contact information, and uploaded documents
            (invoices, receipts, etc.), and billing information (“Personal
            Information”).
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>
              <strong>Client-Specific Data:</strong> We may collect details
              specific to clients, such as GST numbers, business types, and
              primary contact information, to facilitate onboarding and platform
              use.
            </li>
            <li>
              <strong>Employee Data:</strong> Includes employee IDs,
              designations, and bill upload data.
            </li>
            <li>
              Additionally, when you use the E-Accountant mobile application, we
              may access your device’s media storage to upload receipts,
              invoices, or other supporting documents. However, this access is
              granted only after obtaining your explicit permission, and you can
              deny or revoke this access at any time.
            </li>
          </ul>
          <p style={{ marginBottom: "10px" }}>
            Your Personal Information is collected when you:
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>Register on the E-Accountant Platform.</li>
            <li>Upload invoices or bills for tracking and approval.</li>
            <li>
              Voluntarily share data with us, such as by contacting customer
              support or participating in surveys.
            </li>
          </ul>
          <p style={{ marginBottom: "10px" }}>
            When you make payments through the E-Accountant Platform, the
            transactions are processed by third-party payment gateway providers.
            These providers collect payment details such as credit/debit card
            information, net banking details, or UPI details. Please note that
            E-Accountant does not store your financial data. The payment
            gateways operate under their privacy policies, and we advise you to
            review these policies before completing transactions.
          </p>
          <p style={{ marginBottom: "10px" }}>
            If you choose to log in or connect to the E-Accountant Platform
            through third-party services, such as Google or other identity
            providers, we may collect profile information shared by those
            services, such as your name, email, and public profile.
          </p>

          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            b. Privacy and Personalization at E-Accountant
          </h4>
          <p style={{ marginBottom: "10px" }}>
            At E-Accountant, our goal is to enhance your experience by providing
            tailored tools, dashboards, and content that align with your
            business needs. By analyzing your interactions and data shared on
            the platform, we aim to offer features and services that are highly
            relevant to your operations, such as custom reporting tools and
            expense tracking insights. This personalization is achieved through
            strict privacy standards and transparent data usage practices,
            ensuring that your trust in our platform is maintained.
          </p>

          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            c. Inferred Information Through Usage and Log Data
          </h4>
          <p style={{ marginBottom: "10px" }}>
            We use your information to analyze how users interact with the
            platform, identify trends, and improve functionality. This includes
            tracking usage patterns, demographics, and the devices used to
            access the platform. Such analysis helps us improve platform
            security, offer targeted recommendations, and enhance overall
            performance.
          </p>
          <p style={{ marginBottom: "10px" }}>
            If you contact us via email, participate in surveys, or provide
            feedback, we may collect and retain this information for customer
            support, troubleshooting, or analytics purposes. Similarly,
            information shared by other users about your activities on the
            platform may also be stored to ensure compliance with applicable
            laws and resolve disputes.
          </p>
          <p style={{ marginBottom: "10px" }}>
            We use your IP address to manage platform performance, troubleshoot
            technical issues, and identify general geographic and demographic
            trends for personalization.
          </p>

          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            d. Cookies
          </h4>
          <p style={{ marginBottom: "10px" }}>
            Cookies are small data files stored on your device to enhance the
            functionality of the platform and improve your experience.
            E-Accountant uses both session and persistent cookies to enable
            seamless navigation and to remember user preferences. These cookies
            do not store any personal information about you.
          </p>
          <p style={{ marginBottom: "10px" }}>
            We may combine data collected through cookies and other tracking
            technologies with your Personal Information to provide more relevant
            services and improve platform usability. You can manage your cookie
            preferences through your browser settings, though disabling cookies
            may limit certain functionalities of the platform.
          </p>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            2. Purposes for Which E-Accountant Processes Your Personal Data
          </h3>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant processes personal data in connection with its services
            and business operations in accordance with applicable laws and
            regulations. The purposes for such processing are outlined below:
          </p>

          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            A. Managing Business Relationships
          </h4>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant processes personal data to establish, manage, and
            fulfill its contractual and business relationships with clients,
            users, partners, and other relevant stakeholders. This includes, but
            is not limited to:
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>
              Fulfilling pre-contractual and contractual obligations, such as
              managing subscriptions, processing service orders, and delivering
              requested services or products.
            </li>
            <li>
              Providing tools for the efficient management of business and
              client expenses, generating tailored reports, and reconciling
              financial data.
            </li>
            <li>
              Engaging in direct communication via email, phone, live chat, or
              other mediums to address inquiries, resolve issues, or investigate
              potential discrepancies.
            </li>
          </ul>

          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            B. Enhancing and Developing Services
          </h4>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant processes personal data to innovate, improve, and
            expand its platform offerings and services. Specific activities
            include:
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>
              Conducting internal research and analysis utilizing anonymized or
              aggregated datasets to refine platform functionality.
            </li>
            <li>
              Developing, testing, and deploying new features, tools, and
              services informed by user behavior and feedback.
            </li>
          </ul>

          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            C. Compliance with Legal and Regulatory Requirements
          </h4>
          <p style={{ marginBottom: "10px" }}>
            To fulfill its legal and regulatory obligations, E-Accountant
            processes personal data as follows:
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>
              Verifying and authenticating user identities for security and
              compliance purposes.
            </li>
            <li>
              Preventing and investigating fraudulent, malicious, or unlawful
              activities, and prosecuting those responsible for such acts.
            </li>
            <li>
              Meeting requirements under applicable tax, auditing, and financial
              reporting laws, as well as other regulatory mandates.
            </li>
          </ul>

          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            D. Protecting Data and Privacy Preferences
          </h4>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant respects the data protection and privacy rights of its
            users and processes personal data to:
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>
              Record and adhere to users’ preferences concerning marketing
              communications and promotional activities.
            </li>
            <li>
              Ensure compliance with user requests related to opt-in/opt-out
              preferences and applicable privacy laws.
            </li>
          </ul>

          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            E. Operating the E-Accountant Platform and Events
          </h4>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant processes personal data to ensure the efficient
            operation and administration of its platform, services, and events.
            This includes:
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>
              Administering web services, mobile applications, and online
              events, including user participation in webinars, tutorials, and
              training sessions.
            </li>
            <li>
              Enabling user registrations, managing session interactions, and
              tracking user engagement within events or activities.
            </li>
          </ul>

          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            F. Client and User Support
          </h4>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant processes personal data to:
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>
              Deliver client-specific solutions, such as customized reporting,
              notifications, and financial insights.
            </li>
            <li>
              Enhance user satisfaction by addressing feedback, conducting
              surveys, and providing training and support services.
            </li>
          </ul>

          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            G. Marketing and Advertising Activities
          </h4>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant processes personal data to facilitate marketing and
            promotional initiatives. This includes:
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>
              Informing users of new platform features, special offers, and
              services that align with their business needs.
            </li>
            <li>
              Delivering personalized advertisements, content, and targeted
              communications based on user interactions with the platform.
            </li>
          </ul>

          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            H. Customer Satisfaction and Insights
          </h4>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant may analyze combined datasets from multiple sources to
            ensure data accuracy and relevance. Such analysis supports the
            following objectives:
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>
              Personalizing user interactions to improve service delivery.
            </li>
            <li>
              Enhancing overall user experience by providing relevant content
              and insights tailored to individual business or operational needs.
            </li>
          </ul>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            3. User Consent
          </h3>
          <p style={{ marginBottom: "10px" }}>
            You acknowledge and agree that E-Accountant's processing of your
            personal information is legally justified by the platform's
            operational requirements. By using the E-Accountant Platform, you
            provide E-Accountant with permission to process your personal data,
            including any sensitive or legally protected information, as
            necessary to deliver the platform's services to you.
          </p>
          <p style={{ marginBottom: "10px" }}>
            You understand and accept that this processing of your personal
            information is essential for your access to and use of
            E-Accountant's features and functionalities.
          </p>
          <p style={{ marginBottom: "10px" }}>
            You retain the right to refuse or revoke your consent at any time by
            notifying us in writing at the email address provided in the Contact
            Us section of this policy. If you choose not to provide your
            personal information or decide to revoke your consent, E-Accountant
            reserves the right to withhold the services or features for which
            your information was originally required. This may result in the
            termination of your registration, restricted access to certain
            functionalities, or the complete suspension of your account on the
            E-Accountant Platform.
          </p>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            4. Disclosure and Transfer of Personal Information with Third
            Parties
          </h3>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant may share your personal information with affiliates,
            group companies, and third-party service providers under strict
            confidentiality agreements for the following purposes:
          </p>
          <ul style={{ marginBottom: "10px", paddingLeft: "20px" }}>
            <li>
              Platform Operations: Facilitating platform functionalities, such
              as payment processing, analytics, or client management services.
            </li>
            <li>
              Legal and Regulatory Compliance: Ensuring adherence to applicable
              laws and regulatory obligations.
            </li>
            <li>
              Marketing and Promotions: Enhancing marketing campaigns,
              personalized offers, and promotional activities.
            </li>
          </ul>
          <p style={{ marginBottom: "10px" }}>
            Recipients of your personal information may be located both within
            and outside India. E-Accountant ensures that all data transfers
            comply with applicable laws, and third parties or agents granted
            access to your personal information are contractually bound to
            protect it in line with the standards committed to by E-Accountant.
          </p>
          <p style={{ marginBottom: "10px" }}>
            By using the E-Accountant Platform, you explicitly consent to the
            sharing of your personal information for the purposes outlined in
            this Privacy Policy.
          </p>
          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Additional Disclosures
          </h4>
          <ul style={{ marginBottom: "10px", paddingLeft: "20px" }}>
            <li>
              <strong>Legal Compliance:</strong> We may disclose personal
              information when required by a court order, government agency, or
              investigating authority. Such disclosures may also occur if we
              believe, in good faith, that the action is necessary to comply
              with legal processes or to protect the rights, property, or safety
              of E-Accountant, its users, or the public.
            </li>
            <li>
              <strong>Business Changes:</strong> In the event of a merger,
              acquisition, reorganization, or restructuring, E-Accountant and
              its affiliates may share your personal information, in whole or in
              part, with the new business entity. Any transfer will remain
              subject to the commitments outlined in this Privacy Policy.
            </li>
          </ul>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            5. Disclosure and Transfer of Personal Information with Third
            Parties
          </h3>
          <p style={{ marginBottom: "10px" }}>
            We may share your personal information with our affiliates and group
            companies only for lawful purposes. Additionally, we may share your
            information with third parties, both within and outside of India,
            under strict confidentiality agreements. Such sharing of information
            may be necessary to enable your use of the E-Accountant Platform,
            facilitate financial transactions, fulfill our legal obligations,
            enforce our Terms of Use, support marketing and advertising efforts,
            or prevent, address, or investigate any fraudulent or illegal
            actions related to the E-Accountant Platform.
          </p>
          <p style={{ marginBottom: "10px" }}>
            You hereby provide your express consent to E-Accountant to share
            your personal information with third parties and affiliates for
            purposes such as marketing, advertising, and service delivery.
          </p>
          <p style={{ marginBottom: "10px" }}>
            Any third parties or agents who are granted access to your personal
            information for the purposes outlined in this Privacy Policy will be
            contractually bound to protect your personal information to the same
            standards as committed by E-Accountant.
          </p>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant may share your personal information as necessary to
            facilitate commercial transactions on the platform. Please note that
            recipients of your personal information may be located in countries
            outside India, where data protection laws may not offer the same
            level of protection as those in India. By agreeing to this policy,
            you explicitly consent to such use and transfer of your personal
            information.
          </p>
          <p style={{ marginBottom: "10px" }}>
            In compliance with legal requirements, we may disclose your personal
            information if requested by a court order, government agency, or
            investigating authority. Such disclosure may also occur if we
            believe, in good faith, that such action is necessary to comply with
            legal processes or to protect the rights, property, or safety of
            E-Accountant, its users, or the public.
          </p>
          <p style={{ marginBottom: "10px" }}>
            In the event that E-Accountant merges with, is acquired by, or
            undergoes reorganization or restructuring, we and our affiliates may
            share your personal information, in whole or in part, with the new
            business entity. Such transfer of information will remain subject to
            the commitments outlined in this Privacy Policy.
          </p>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            6. Third-Party Sites
          </h3>
          <p style={{ marginBottom: "10px" }}>
            The E-Accountant Platform may contain links to websites or services
            owned and operated by third parties. These external sites may
            collect and process your personal data if you choose to visit them.
            The collection, use, and handling of your information on these
            third-party sites will be governed by their respective privacy
            policies, not by E-Accountant.
          </p>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant does not control or have authority over these external
            websites and is not responsible for the accuracy, legality, or
            privacy practices of their content or services. If you choose to
            interact with or share your personal data with these third-party
            websites, you do so at your own risk.
          </p>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant cannot be held liable for any misuse, loss, or
            mishandling of your personal data by such third-party sites. We
            encourage you to review the privacy policies of any external
            websites before providing your personal information or engaging with
            their services.
          </p>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            7. Promotional and Marketing Activities
          </h3>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant may communicate with you through various channels,
            including SMS, email, messaging platforms, or other methods, at
            regular intervals. These communications are intended to inform you
            about new features, available offers, exclusive deals, or services
            that may be of interest to you. By using the E-Accountant Platform,
            you give your explicit consent to receive such promotional and
            marketing communications.
          </p>
          <p style={{ marginBottom: "10px" }}>
            If you prefer not to receive these communications, you have the
            option to opt out. To unsubscribe, send an email to the address
            provided in the Contact Us section with "Unsubscribe" as the subject
            line. Upon receiving your request, E-Accountant will take reasonable
            steps to remove your contact details from our promotional
            communications list.
          </p>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            8. Security
          </h3>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant implements commercially reasonable technical and
            organizational measures to protect Personal Information from
            unauthorized access, misuse, alteration, or loss. These measures are
            designed to safeguard data within our organization and ensure its
            confidentiality and integrity. However, no data transmission or
            storage system can be guaranteed to be completely secure.
          </p>
          <p style={{ marginBottom: "10px" }}>
            If you have reason to believe that your interaction with
            E-Accountant is no longer secure, we urge you to contact us
            immediately through the details provided in the Contact Us section.
          </p>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            9. Data Retention
          </h3>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant is committed to safeguarding the privacy of its users
            by implementing robust data management and deletion practices. In
            line with this Data Retention Policy, personal data will be erased
            in the following circumstances:
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>When you withdraw your consent for data processing.</li>
            <li>
              When the purpose for which the data was collected has been
              fulfilled or is no longer valid.
            </li>
          </ul>
          <p style={{ marginBottom: "10px" }}>
            If you delete your account, E-Accountant will retain your personal
            data for a period of 18 months post-account deletion. This retention
            period ensures the platform can address potential legal claims,
            disputes, or regulatory obligations. After this period, all personal
            data will be securely erased unless required to be retained by
            applicable laws.
          </p>
          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Client-Related Data and Bill Retention
          </h4>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant retains client-related data for operational and
            compliance purposes, ensuring the integrity of financial
            transactions and fulfilling contractual obligations. Bill and
            expense data may be retained for up to two years for analytics and
            reporting purposes. After this period, such data will either be
            securely deleted or anonymized to prevent identification while
            retaining aggregated insights.
          </p>
          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Two-Year Content Retention Guarantee
          </h4>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant offers a Two-Year Content Retention Guarantee, ensuring
            that User Content, as defined in the Terms of Use, remains securely
            accessible for up to two years from the date of submission. Users
            are responsible for erasing personal data from uploaded content once
            its purpose has been fulfilled.
          </p>
          <h4
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Regular Review and Compliance
          </h4>
          <p style={{ marginBottom: "10px" }}>
            To ensure compliance with data protection laws, E-Accountant
            conducts regular reviews of the necessity and relevance of retained
            data. Prompt action is taken to delete or anonymize data when
            retention periods end or when the purpose for processing is no
            longer valid. This practice upholds transparency, compliance, and
            security in managing user data.
          </p>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            10. User Requests
          </h3>
          <p style={{ marginBottom: "10px" }}>If you wish to:</p>
          <ul style={{ marginBottom: "10px" }}>
            <li>
              Receive confirmation of the processing of your personal
              information or a summary of the processing activities related to
              it.
            </li>
            <li>
              Access, update, or correct your personal information for any
              reason.
            </li>
            <li>
              Request the deletion or restriction of personal information that
              is no longer necessary for the purpose it was collected.
            </li>
          </ul>
          <p style={{ marginBottom: "10px" }}>
            You may send a request to us at the email address provided in the
            Contact Us section at the bottom of this page. Please note that any
            request for deletion or restriction of personal information will be
            addressed in compliance with applicable laws.
          </p>
          <p style={{ marginBottom: "10px" }}>
            If the processing was carried out through automated means, you also
            have the right to request a copy of your personal information that:
          </p>
          <ul style={{ marginBottom: "10px" }}>
            <li>You consented to provide us.</li>
            <li>
              Is necessary to perform a contract with you as part of your access
              to E-Accountant.
            </li>
            <li>Forms part of your user profile with us.</li>
          </ul>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            11. Security and Compliance with Laws
          </h3>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant takes every reasonable step to safeguard your personal
            information from unauthorized access, misuse, illegal activity,
            alteration, loss, or destruction. We adopt administrative,
            technical, and physical security measures that align with standard
            industry practices to maintain the confidentiality of your personal
            data.
          </p>
          <p style={{ marginBottom: "10px" }}>
            While we strive to implement robust security practices, it is
            important to note that no system can guarantee complete security. By
            using the E-Accountant platform, you acknowledge and accept these
            inherent risks.
          </p>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant ensures compliance with all relevant laws to protect
            your personal data and maintain the security of the platform.
          </p>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            12. Amendments
          </h3>
          <p style={{ marginBottom: "10px" }}>
            This Privacy Policy may be updated or amended from time to time
            without prior notice. E-Accountant reserves the right to revise the
            terms of this Privacy Policy to ensure compliance with applicable
            laws or to address changes in business practices.
          </p>
          <p style={{ marginBottom: "10px" }}>
            Amendments will become effective immediately upon publication, and
            the "Updated" date at the bottom of this Privacy Policy will be
            revised accordingly. It is your responsibility to periodically
            review this Privacy Policy for any changes. By continuing to use the
            E-Accountant Platform after any updates are made, you signify your
            acceptance of the revised terms.
          </p>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            13. Contact Us
          </h3>
          <p style={{ marginBottom: "10px" }}>
            If you have any questions, require additional information, or seek
            clarification regarding the use of your personal information or this
            Privacy Policy, you may contact us at:
          </p>
          <p style={{ marginBottom: "10px" }}>
            <strong>Email:</strong>{" "}
            <a href="mailto:info@wechartered.com">info@wechartered.com</a>
          </p>
          <p style={{ marginBottom: "10px" }}>
            <strong>Address:</strong> Unit No. 105, 1st Floor, IRIS-Tech Park,
            Sector-48, Sohna Road, Gurgaon, Haryana, India, 122018
          </p>
          <p style={{ marginBottom: "10px" }}>
            E-Accountant is a company based in India. By using or accessing the
            E-Accountant platform, you acknowledge that E-Accountant is not
            responsible for compliance with any local laws of your territory
            except for the applicable laws of India.
          </p>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            14. Updates to This Privacy Policy
          </h3>
          <p style={{ marginBottom: "10px" }}>
            We reserve the right to change this Privacy Policy at any time to
            reflect new services, changes in our Personal Information practices,
            or relevant laws. If we make material changes, we will notify you as
            required by applicable law. The “Last Updated” at the top of this
            Privacy Policy indicates when this Privacy Policy was last revised.
            Any changes will become effective when we post the revised Privacy
            Policy.
          </p>
        </div>
      </div>
    </>
  );
};
export default Privacy;
