import React, { useState, useRef } from "react";
import styles from "./index.module.scss";
import Upload from "../../icons/Upload";
import toast from "react-hot-toast";
import { errorStyles } from "../../../assets/styles/components/toast";
import Error from "../Error";

// const ImageSelector = ({
//   placeholder = "Select Files",
//   handleFile,
//   maxFileSize = 10, // Default max size is 1MB
//   touched,
//   errors,
//   customStyles,
//   multiple = false,
//   handleRemoveFile
// }) => {
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const fileInputRef = useRef(null);

//   const handleClick = () => {
//     fileInputRef.current.click();
//   };

//   // const handleChange = (event) => {
//   //   setSelectedFiles([]);
//   //   const files = Array.from(event.target.files); // Convert FileList to Array

//   //   const allowedTypes = [
//   //     "image/jpeg",
//   //     "image/png",
//   //     "image/jpg",
//   //     "image/gif",
//   //     "application/pdf",
//   //     // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   //     // "application/vnd.ms-excel",
//   //     "application/msword",
//   //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//   //   ];

//   //   const minWidth = 100; // Minimum width for images
//   //   const minHeight = 100; // Minimum height for images

//   //   const validFiles = [];

//   //   files.forEach((file) => {
//   //     if (file.size > maxFileSize * 1024 * 1024) {
//   //       toast.error(`${file.name} size exceeds ${maxFileSize} MB`, {
//   //         duration: 2000,
//   //         style: errorStyles,
//   //       });
//   //       return;
//   //     }

//   //     if (allowedTypes.includes(file.type)) {
//   //       if (file.type.startsWith("image/")) {
//   //         const reader = new FileReader();
//   //         reader.onload = () => {
//   //           const img = new Image();
//   //           img.src = reader.result;

//   //           img.onload = () => {
//   //             if (img.width >= minWidth && img.height >= minHeight) {
//   //               validFiles.push(file);
//   //               if (validFiles.length === files.length) {
//   //                 setSelectedFiles(validFiles);
//   //                 handleFile(validFiles);
//   //               }
//   //             } else {
//   //               toast.error(
//   //                 `${file.name} dimensions must be at least ${minWidth}x${minHeight}`,
//   //                 { duration: 2000, style: errorStyles }
//   //               );
//   //             }
//   //           };
//   //         };
//   //         reader.readAsDataURL(file);
//   //       } else {
//   //         validFiles.push(file);
//   //       }
//   //     } else {
//   //       toast.error(`${file.name} is not a supported file type`, {
//   //         duration: 2000,
//   //         style: errorStyles,
//   //       });
//   //     }
//   //   });

//   //   if (!files.some((file) => !allowedTypes.includes(file.type))) {
//   //     setSelectedFiles(validFiles);
//   //     handleFile(validFiles);
//   //   }
//   // };

//   // const handleChange = (event) => {
//   //   const newFiles = Array.from(event.target.files); // Convert FileList to Array

//   //   const allowedTypes = [
//   //     "image/jpeg",
//   //     "image/png",
//   //     "image/jpg",
//   //     "image/gif",
//   //     "application/pdf",
//   //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//   //     "application/vnd.ms-excel",
//   //     "application/msword",
//   //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//   //   ];

//   //   const minWidth = 100; // Minimum width for images
//   //   const minHeight = 100; // Minimum height for images

//   //   const validFiles = [];

//   //   newFiles.forEach((file) => {
//   //     if (file.size > maxFileSize * 1024 * 1024) {
//   //       toast.error(`${file.name} size exceeds ${maxFileSize} MB`, {
//   //         duration: 2000,
//   //         style: errorStyles,
//   //       });
//   //       return;
//   //     }

//   //     if (allowedTypes.includes(file.type)) {
//   //       if (file.type.startsWith("image/")) {
//   //         const reader = new FileReader();
//   //         reader.onload = () => {
//   //           const img = new Image();
//   //           img.src = reader.result;

//   //           img.onload = () => {
//   //             if (img.width >= minWidth && img.height >= minHeight) {
//   //               validFiles.push(file);
//   //               if (validFiles.length === newFiles.length) {
//   //                 setSelectedFiles((prevFiles) => [
//   //                   ...prevFiles,
//   //                   ...validFiles,
//   //                 ]);
//   //                 handleFile([...selectedFiles, ...validFiles]); // Combine new and existing files
//   //               }
//   //             } else {
//   //               toast.error(
//   //                 `${file.name} dimensions must be at least ${minWidth}x${minHeight}`,
//   //                 { duration: 2000, style: errorStyles }
//   //               );
//   //             }
//   //           };
//   //         };
//   //         reader.readAsDataURL(file);
//   //       } else {
//   //         validFiles.push(file);
//   //       }
//   //     } else {
//   //       toast.error(`${file.name} is not a supported file type`, {
//   //         duration: 2000,
//   //         style: errorStyles,
//   //       });
//   //     }
//   //   });

//   //   if (!newFiles.some((file) => !allowedTypes.includes(file.type))) {
//   //     setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]); // Add valid files to the existing ones
//   //     handleFile([...selectedFiles, ...validFiles]);
//   //   }
//   // };

//   const handleChange = (event) => {
//     const newFiles = Array.from(event.target.files);
//     const allowedTypes = [
//       "image/jpeg",
//       "image/png",
//       "image/jpg",
//       "image/gif",
//       "application/pdf",
//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//       "application/vnd.ms-excel",
//       "application/msword",
//       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//     ];
//     const minWidth = 100;
//     const minHeight = 100;
//     const validFiles = [];

//     newFiles.forEach((file) => {
//       if (file.size > maxFileSize * 1024 * 1024) {
//         toast.error(`${file.name} size exceeds ${maxFileSize} MB`, {
//           duration: 2000,
//           style: errorStyles,
//         });
//         return;
//       }

//       if (allowedTypes.includes(file.type)) {
//         if (file.type.startsWith("image/")) {
//           const reader = new FileReader();
//           reader.onload = () => {
//             const img = new Image();
//             img.src = reader.result;

//             img.onload = () => {
//               if (img.width >= minWidth && img.height >= minHeight) {
//                 validFiles.push(file);
//                 updateFileList([...selectedFiles, ...validFiles]);
//               } else {
//                 toast.error(
//                   `${file.name} dimensions must be at least ${minWidth}x${minHeight}`,
//                   { duration: 2000, style: errorStyles }
//                 );
//               }
//             };
//           };
//           reader.readAsDataURL(file);
//         } else {
//           validFiles.push(file);
//         }
//       } else {
//         toast.error(`${file.name} is not a supported file type`, {
//           duration: 2000,
//           style: errorStyles,
//         });
//       }
//     });

//     updateFileList([...selectedFiles, ...validFiles]);
//     event.target.value = "";
//   };

//   const updateFileList = (files) => {
//     const uniqueFiles = files.reduce((acc, file) => {
//       if (!acc.some((f) => f.name === file.name && f.size === file.size)) {
//         acc.push(file);
//       }
//       return acc;
//     }, []);
//     setSelectedFiles(uniqueFiles);
//     handleFile(uniqueFiles);
//   };

//   const handleRemoveFile = (index) => {
//     const updatedFiles = selectedFiles.filter((_, i) => i !== index);
//     setSelectedFiles(updatedFiles);
//     handleFile(updatedFiles);
//   };

//   return (
//     <>
//       <div className={styles.image_selector} style={customStyles}>
//         <div className={styles.custom_ui} onClick={handleClick}>
//           <Upload />
//           <div className={styles.placeholder}>{placeholder}</div>
//         </div>
//         {/* {selectedFiles.length > 0 && (
//           <div className={styles.file_list}>
//             {selectedFiles.map((file, index) => (
//               <div key={index} className={styles.file_name}>
//                 {file.name}
//               </div>
//             ))}
//           </div>
//         )} */}
//         <input
//           type="file"
//           accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx"
//           ref={fileInputRef}
//           style={{ display: "none" }}
//           multiple={multiple}
//           onChange={handleChange}
//         />
//       </div>
//       {touched && errors ? <Error error={errors} type="small" /> : null}
//     </>
//   );
// };

// export default ImageSelector

const ImageSelector = ({
  placeholder = "Select Files",
  handleFile,
  selectedFiles,
  handleRemoveFile,
  maxFileSize = 10, // Default max size is 10MB
  multiple = false,
}) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/gif",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const minWidth = 100; // Minimum width for images
    const minHeight = 100; // Minimum height for images
    const validFiles = [];

    newFiles.forEach((file) => {
      if (file.size > maxFileSize * 1024 * 1024) {
        toast.error(`${file.name} size exceeds ${maxFileSize} MB`, {
          duration: 2000,
          style: { color: "red" }, // Customize style if needed
        });
        return;
      }

      if (allowedTypes.includes(file.type)) {
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result;

            img.onload = () => {
              if (img.width >= minWidth && img.height >= minHeight) {
                validFiles.push(file);
                updateFileList([...selectedFiles, ...validFiles]);
              } else {
                toast.error(
                  `${file.name} dimensions must be at least ${minWidth}x${minHeight}`,
                  { duration: 2000, style: { color: "red" } }
                );
              }
            };
          };
          reader.readAsDataURL(file);
        } else {
          validFiles.push(file);
        }
      } else {
        toast.error(`${file.name} is not a supported file type`, {
          duration: 2000,
          style: { color: "red" },
        });
      }
    });

    updateFileList([...selectedFiles, ...validFiles]);
    event.target.value = ""; // Clear input after selecting files
  };

  const updateFileList = (files) => {
    const uniqueFiles = files.reduce((acc, file) => {
      if (!acc.some((f) => f.name === file.name && f.size === file.size)) {
        acc.push(file);
      }
      return acc;
    }, []);
    handleFile(uniqueFiles); // Pass updated list back to parent component
  };

  return (
    <div className={styles.image_selector}>
      <div className={styles.custom_ui} onClick={handleClick}>
        <Upload />
        <div className={styles.placeholder}>{placeholder}</div>
      </div>

      <input
        type="file"
        accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.xls,.xlsx"
        ref={fileInputRef}
        style={{ display: "none" }}
        multiple={multiple}
        onChange={handleChange}
      />

      <div className={styles.file_list} style={{ display: "none" }}>
        {selectedFiles.map((file, index) => (
          <div key={index} className={styles.file_name}>
            {file.name}
            <button
              type="button"
              onClick={() => handleRemoveFile(file)}
              className={styles.remove_button}
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSelector;
