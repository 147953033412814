import React, { useCallback, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { getData, patchData, postData } from "../../../../../Services";
import { statusData } from "../../../../../helpers/staticData";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import MyInput from "../../../../atoms/MyInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import { emailRegex } from "../../../../../helpers/expirationTime";
import TagInput from "../../../../atoms/TagInput";
import { useSelector } from "react-redux";
import WarningModal from "../../../../atoms/modals/warningModal";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import { useLocation, useNavigate } from "react-router-dom";

const ClientForm = ({
  onClose,
  initialValues,
  id,
  refetchData,
  setEditData,
}) => {
  const [cookies] = useCookies(["t", "bid", "iso"]);
  const [modalState, setModalState] = useState({
    isActive: false,
    category: null,
    sub_category: null,
    id: null,
  });
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.["Category Management"];

  const [warning, setWarning] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [anchorClicked, setAnchorClicked] = useState({
    isActive: false,
    event: null,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [navigatePath, setNavigatePath] = useState(null);

  const initialData = {
    category: initialValues?.category || "",
    sub_category: initialValues?.sub_category || [],
  };

  const validationSchema = Yup.object({
    category: Yup.string().required("Category is required"),
  });

  const handleSubmit = async (values, { resetForm }, { setValues }) => {
    if (modalState && modalState.id) {
      setModalState({
        ...modalState,
        isActive: true,
      });
    } else {
      const res = await postData({
        endpoint: "category/create",
        token: cookies.t,
        params: {
          category: capitalizeLetter(values.category),
          sub_category: values.sub_category,
          business_id: cookies?.bid,
        },
      });
      if (res?.data?.statusCode && res?.data) {
        toast.success("Expense Category Added Successfully", {
          style: confimationStyles,
          duration: 1000,
        });

        resetForm();

        setValues({
          category: "",
          sub_category: [],
        });
        refetchData();
      } else {
        toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
        onClose();
      }
    }
  };

  const handleEdit = async (values, { resetForm }, { setValues }) => {
    if (modalState && modalState.id) {
      setModalState({
        ...modalState,
        isActive: true,
      });
    } else {
      try {
        const res = await patchData({
          endpoint: "category/update",
          token: cookies.t,
          data: values,
          params: {
            id: initialValues._id,
            category: capitalizeLetter(values.category),
            sub_category: values.sub_category || [],
            business_id: cookies?.bid,
          },
        });

        if (res) {
          toast.success("Expense Category Updated Successfully", {
            style: confimationStyles,
            duration: 1000,
          });
          resetForm();
          setEditData();
          setValues({
            category: "",
            sub_category: [],
          });
          refetchData();
        } else {
          toast.error(res.message, {
            style: confimationStyles,
            duration: 1000,
          });
        }

        onClose();
      } catch (error) {}
    }
  };

  const handleEditByName = async (values, { resetForm }, { setValues }) => {
    let uniqueSubCategories;
    if (modalState && modalState?.category) {
      uniqueSubCategories = values.sub_category.filter(
        (item) => !modalState?.sub_category.includes(item)
      );
    }
    try {
      const res = await patchData({
        endpoint: "category/update",
        token: cookies.t,
        data: {},
        params: {
          id: modalState.id,
          category: capitalizeLetter(modalState.category),
          sub_category:
            [...modalState.sub_category, ...uniqueSubCategories] || [],
          business_id: cookies?.bid,
        },
      });

      if (res) {
        toast.success("Expense Category Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        resetForm();
        setEditData();
        setValues({
          category: "",
          sub_category: [],
        });
        refetchData();
        setModalState({
          isActive: false,
          category: null,
          sub_category: null,
          id: null,
        });
      } else {
        toast.error(res.message, { style: errorStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {}
  };

  const checkName = async (newVal) => {
    const response = await getData({
      endpoint: "category/checkName",
      token: cookies.t,
      params: { name: capitalizeLetter(newVal), id: cookies.bid },
    });
    if (response.status && response.data) {
      setModalState({
        isActive: false,
        category: response.data?.category,
        sub_category: response?.data?.sub_category,
        id: response?.data?.id,
      });
    } else {
      setModalState({
        isActive: false,
        category: null,
        sub_category: null,
        id: null,
      });
    }
  };

  const handleBeforeUnload = (e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = "";
      return "";
    }
  };

  const handlePopState = (e) => {
    if (isDirty) {
      e.preventDefault();
      setWarning(true);
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (isDirty && !isClicked) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", handlePopState);
      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("popstate", handlePopState);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [isDirty]);

  useEffect(() => {
    function handleClickOutSide(event) {
      const href = event.target.closest("[href]");
      if (href) {
        if (isDirty) {
          setWarning(true);
          setAnchorClicked({
            isActive: true,
            event: event,
          });
        }
        event.preventDefault();
      } else {
        console.log("Clicked outside.");
      }
    }
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [location, isDirty]);

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      //   onSubmit={initialValues ? () => handleEdit() : () => handleSubmit()}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
        isSubmitting,
        resetForm,
        setValues,
        setFieldTouched,
        touched,
      }) => {
        if (dirty !== isDirty) {
          setIsDirty(dirty);
        }
        return (
          <>
            <Form>
              <div className="row mt-3">
                <div className="col-3">
                  <MyInput
                    type="text"
                    id="category"
                    name="category"
                    totalWidth={true}
                    customholder="Enter Expense Category"
                    required
                    customBlur={(e) => {
                      if (initialValues?.category !== values.category) {
                        checkName(values.category);
                      }
                    }}
                    // blurError={emailError}
                  />
                </div>

                <TagInput
                  type="text"
                  name="sub_category"
                  customholder="Enter Sub Expense Categories"
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  InputHeight="small"
                  placeholder="Enter Sub Expense Categories"
                  info={"Type and Enter to add Sub Expense Categories"}
                  //   maxTags={5}
                  onChange={(tags) => {
                    setFieldValue("sub_category", tags);
                  }}
                  selectedTags={values.sub_category}
                  //   tagErrors={
                  //     Array.isArray(errors?.interest_party_email) &&
                  //     errors.interest_party_email.length > 0
                  //       ? errors.interest_party_email
                  //           .map((value, index) => (value ? index : -1))
                  //           .filter((index) => index !== -1)
                  //       : []
                  //   }
                  //   error={errors.sub_category}
                  styles={{ width: "23.9%" }}
                />
                {((roleData && roleData?.["add/edit"]) || true) && (
                  <div className={`col-3 mb-3`}>
                    {initialValues ? (
                      <button
                        type="button"
                        className="btn-smlg btn-primary w-50"
                        onClick={() =>
                          handleEdit(values, { resetForm }, { setValues })
                        }
                        // disabled={isSubmitting}
                      >
                        Update
                      </button>
                    ) : (
                      <div className="d-flex gap-4 ">
                        <button
                          type="button"
                          className="btn-smlg btn-outline-primary w-25 flex-1"
                          // disabled={isSubmitting}
                          onClick={() => resetForm()}
                        >
                          Reset
                        </button>

                        <button
                          type="button"
                          className="btn-smlg btn-primary w-25 flex-1"
                          // disabled={isSubmitting}
                          onClick={() =>
                            handleSubmit(values, { resetForm }, { setValues })
                          }
                        >
                          Create
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Form>
            <WarningModal
              isActive={warning}
              type=""
              title={"Changes Unsaved"}
              description="Are you need to leave the changes unsaved"
              onClose={
                anchorClicked
                  ? () => {
                      setWarning(false);
                      setAnchorClicked({
                        isActive: false,
                        event: null,
                      });
                    }
                  : () => {
                      setWarning(false);
                      window.history.pushState(
                        null,
                        null,
                        window.location.pathname
                      );
                    }
              }
              onClick={
                anchorClicked.isActive
                  ? () => {
                      const href = anchorClicked.event.target
                        .closest("[href]")
                        .getAttribute("href");
                      if (href) {
                        if (href === "https://wesoftek.com/") {
                          window.open(href, "_blank");
                        } else {
                          navigate(href, { replace: true });
                        }
                      }
                      setWarning(false);
                      setAnchorClicked({
                        isActive: false,
                        event: null,
                      });
                      setIsDirty(false);
                    }
                  : (e) => {
                      setIsClicked(true);
                      navigate(-1);
                      setWarning(false);
                      setIsDirty(false);
                    }
              }
            ></WarningModal>
          </>
        );
      }}
    </Formik>
  );
};

export default ClientForm;
