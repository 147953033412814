import { Navigate, Route, Routes } from "react-router-dom";
import Auth from "../Components/layout/AuthLayout";
import Dashboard from "../Components/layout/DashboardLayout";
import { Suspense } from "react";
import Login from "../Components/pages/Auth/Login";
import Verification from "../Components/pages/Auth/verifyOTP";
import ForgotPassword from "../Components/pages/Auth/forgotPassword";
import ResetOrCreatePass from "../Components/pages/Auth/createOrResetPassword";
import Add from "../Components/pages/Dashboards/Business/Add";
import UserManage from "../Components/pages/Dashboards/Settings/UserManagement";
import Employees from "../Components/pages/Dashboards/Business";
import UserManagement from "../Components/pages/Dashboards/Settings/UserManagement/tabs";
import AddRole from "../Components/pages/Dashboards/Settings/RoleManagement/Add";
import AuthGuard from "./AuthGuard";
import GuestGuard from "./GuestGuard";
import EditProfile from "../Components/pages/Dashboards/ProfileDetails/index";
import ChangePassword from "../Components/pages/Dashboards/ProfileDetails/change-password";
import Bills from "../Components/pages/Dashboards/Bill";
import AddBill from "../Components/pages/Dashboards/Bill/Add";
import ViewBills from "../Components/pages/Dashboards/Bill/View";
import ViewClientTabs from "../Components/pages/Dashboards/Invoices/client/tabs";
import Clients from "../Components/pages/Dashboards/Clients";
import AddClients from "../Components/pages/Dashboards/Clients/Add";
import ClientManagement from "../Components/pages/Dashboards/Settings/ClientManagement";
import ViewEmployeeTabs from "../Components/pages/Dashboards/Invoices/Employees/tabs";
import EmployeesTable from "../Components/pages/Dashboards/Invoices/Employees";
import ViewBillInvoice from "../Components/pages/Dashboards/Bill/InvoiceView";
import CategoryManagement from "../Components/pages/Dashboards/Settings/CategoryManagement";
import {
  MODE_BUSINESS,
  MODE_CLIENT,
  MODE_EMPLOYEE,
} from "../helpers/constants";
import NotAuthorized from "../Components/pages/Auth/NotAuthorized";
import Home from "../Components/pages/Dashboards/Home";
import EmpHome from "../Components/pages/Dashboards/EmpHome";
import { useCookies } from "react-cookie";
import AddBillInvoice from "../Components/pages/Dashboards/Invoices/client/Add";
import Reports from "../Components/pages/Dashboards/Reports";
import ClientReports from "../Components/pages/Dashboards/clientReports";
import Invites from "../Components/pages/Dashboards/MyInvites";
import TermsAndConditions from "../Components/pages/terms";
import Privacy from "../Components/pages/privacy";

const Authenticated = () => {
  const isAuthenticated = false;
  return isAuthenticated();
};

const GetMode = () => {
  const [cookies, setCookie] = useCookies(["t", "bid", "ibu", "mode", "iso"]);
  console.log("Mode :", cookies.mode);

  return cookies.mode ? atob(cookies.mode) : "";
};

const routes = [
  {
    path: "/",
    element: <Navigate to="/auth" />,
  },
  {
    path: "pages/terms",
    element: <TermsAndConditions />,
  },
  {
    path: "pages/privacy",
    element: <Privacy />,
  },
  {
    path: "auth/*",
    element: (
      <GuestGuard>
        <Auth />
      </GuestGuard>
    ),
  },
  {
    path: "dashboard/*",
    element: <Dashboard />,
    protected: true,
  },
];

export default routes;

export const authRoutes = (
  <Suspense>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify" element={<Verification />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/reset-password"
        element={<ResetOrCreatePass type="reset" />}
      />
      <Route
        path="/create-password"
        element={<ResetOrCreatePass type="create" />}
      />
    </Routes>
  </Suspense>
);

export const dashboardRoutes = (
  <Suspense>
    <Routes>
      <Route
        path={"/"}
        element={<AuthGuard type="Dashboard">{<></>}</AuthGuard>}
      />
      <Route
        path={"/home"}
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <Home />
          </AuthGuard>
        }
      />
      <Route
        path="/ehome"
        element={
          <AuthGuard mode={[MODE_EMPLOYEE, MODE_CLIENT]}>
            <EmpHome />
          </AuthGuard>
        }
      />
      <Route
        path="/employees"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <Employees />
          </AuthGuard>
        }
      />
      <Route
        path="/employees/add"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <Add type={"add"} />
          </AuthGuard>
        }
      />
      <Route
        path="/employees/edit/:id?"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <Add type={"edit"} />
          </AuthGuard>
        }
      />
      <Route
        path="/clients"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <Clients />
          </AuthGuard>
        }
      />
      <Route
        path="/clients/add"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <AddClients type={"add"} />
          </AuthGuard>
        }
      />
      <Route
        path="/clients/edit/:id?"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <AddClients type={"edit"} />
          </AuthGuard>
        }
      />
      <Route
        path="/bills"
        element={
          <AuthGuard mode={[MODE_EMPLOYEE, MODE_CLIENT]}>
            <Bills />
          </AuthGuard>
        }
      />
      <Route
        path="/bills/add"
        element={
          <AuthGuard mode={[MODE_EMPLOYEE, MODE_CLIENT]}>
            <AddBill type={"add"} />
          </AuthGuard>
        }
      />
      <Route
        path="/bills/edit/:id?"
        element={
          <AuthGuard mode={[MODE_EMPLOYEE, MODE_CLIENT]}>
            <AddBill type={"edit"} />
          </AuthGuard>
        }
      />
      <Route
        path="/bills/view/:id?"
        element={
          <AuthGuard mode={[MODE_EMPLOYEE, MODE_CLIENT]}>
            <ViewBills />
          </AuthGuard>
        }
      />
      <Route
        path="/setting/user-management"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <UserManagement />
          </AuthGuard>
        }
      />
      <Route
        path="/setting/user-management/add"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <AddRole type="add" />
          </AuthGuard>
        }
      />
      <Route
        path="/setting/user-management/edit/:id?"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <AddRole type="edit" />
          </AuthGuard>
        }
      />
      <Route
        path="/setting/category-management"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <CategoryManagement />
          </AuthGuard>
        }
      />
      <Route
        path="change-password"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <ChangePassword />
          </AuthGuard>
        }
      />
      <Route
        path="edit-profile"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <EditProfile />
          </AuthGuard>
        }
      />

      <Route
        path="myInvites"
        element={
          <AuthGuard mode={"All"}>
            <Invites />
          </AuthGuard>
        }
      />

      {/* <Route
        path="emyInvites"
        element={
          <AuthGuard mode={[MODE_EMPLOYEE, MODE_CLIENT]}>
            <Invites />
          </AuthGuard>
        }
      /> */}

      <Route
        path="emode/change-password"
        element={
          <AuthGuard mode={[MODE_EMPLOYEE, MODE_CLIENT]}>
            <ChangePassword />
          </AuthGuard>
        }
      />
      <Route
        path="emode/edit-profile"
        element={
          <AuthGuard mode={[MODE_EMPLOYEE, MODE_CLIENT]}>
            <EditProfile />
          </AuthGuard>
        }
      />
      <Route
        path="clientInvoice"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <ViewClientTabs />
          </AuthGuard>
        }
      />
      <Route
        path="clientInvoice/add"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <AddBillInvoice />
          </AuthGuard>
        }
      />
      <Route
        path="clientInvoice/edit/:id?"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <AddBillInvoice type="edit" />
          </AuthGuard>
        }
      />
      <Route
        path="clientManagement"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <ClientManagement />
          </AuthGuard>
        }
      />
      <Route
        path="employeeInvoice"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <ViewEmployeeTabs />
          </AuthGuard>
        }
      />
      <Route
        path="clientInvoice/viewInvoice/:type?/:id?"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <ViewBillInvoice />
          </AuthGuard>
        }
      />
      <Route
        path="employeeInvoice/viewInvoice/:type?/:id?"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <ViewBillInvoice />
          </AuthGuard>
        }
      />
      <Route
        path="employeeManagement"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <EmployeesTable />
          </AuthGuard>
        }
      />
      <Route
        path="ereports"
        element={
          <AuthGuard mode={[MODE_EMPLOYEE, MODE_CLIENT]}>
            <ClientReports />
          </AuthGuard>
        }
      />
      <Route
        path="reports"
        element={
          <AuthGuard mode={MODE_BUSINESS}>
            <Reports />
          </AuthGuard>
        }
      />
      <Route
        path="not-authorized"
        element={
          <AuthGuard>
            <NotAuthorized />
          </AuthGuard>
        }
      />
    </Routes>
  </Suspense>
);
