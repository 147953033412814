import React from "react";
import { Outlet } from "react-router-dom";
import Logo from "../../../assets/images/login3.jpeg";
import style from "./authlayout.module.scss";
import { authRoutes } from "../../../routes/routes";
import WesoftekLogoBlack from "../../icons/wesoftekLogoBlack";
import useScreenWidth from "../../../hooks/useScreenwidth";

const AuthLayout = ({ children }) => {
  const screenWidth = useScreenWidth();
  return (
    <div className={style.auth_screen}>
      <div className={style.auth_image}>
        <img
          src={Logo}
          className={style.logo_image}
          alt="company-logo"
          loading="eager"
        />
      </div>
      <div
        className={`${style.auth_content} `}
        style={{ position: "relative" }}
      >
        <div className={style.auth_child}>
          <Outlet />
          {authRoutes}
        </div>
        <footer
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 20px",
            backgroundColor: "#f1f1f1",
            borderTop: "1px solid #ccc",
            position: "absolute",
            width: "100%",
            bottom: 0,
          }}
        >
          <div>
            <a
              href="/pages/terms"
              style={{
                textDecoration: "none",
                marginRight: "5px",
                color: "#000",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {screenWidth < 600 ? "T&C" : "Terms & Conditions"}
            </a>
            <span style={{ marginRight: "5px" }}>|</span>
            <a
              href="/pages/privacy"
              style={{
                textDecoration: "none",
                color: "#000",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Privacy Policy
            </a>
          </div>
          <a
            style={{
              color: "#555",
              fontSize: "12px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: "3px",
            }}
            href="https://wesoftek.com/"
            target="_blank"
          >
            Powered by{" "}
            <div className="mb-2">
              <WesoftekLogoBlack />
            </div>
          </a>
        </footer>
      </div>
    </div>
  );
};

export default AuthLayout;
