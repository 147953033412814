import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import CircleAvatar from "../../atoms/CircleAvatar";
import styles from "./styles.module.scss";

import LogoutModal from "../../atoms/modals/LogoutModal";
import { decodeCookieValue } from "../../../helpers/cookieHelper";

import { useCookies } from "react-cookie";

import useScreenWidth from "../../../hooks/useScreenwidth";
import Broker from "../../icons/Broker";
import Payments from "../../icons/Payments";
import ReportsIcon from "../../icons/reports";
import Dashboard from "../../icons/dashboard";
import { getData } from "../../../Services";
import CompanyLogo from "../../icons/companyLogo";
import { expitationTime } from "../../../helpers/expirationTime";
import MyDropdown from "../../atoms/MyDropdown";

const filterRoutes = (routes, role) => {
  if (role === "Viewer") {
    return Object.fromEntries(
      Object.entries(routes).filter(
        ([key]) =>
          key !== "configurations" && key !== "logs" && key !== "invites/add"
      )
    );
  }
  return routes;
};

const Navbar = ({ mode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const screenWidth = useScreenWidth();
  const [cookies, setCookies] = useCookies([
    "bun",
    "profile",
    "both",
    "role",
    "va",
    "br",
    "acid",
  ]);
  const [businessData, setBusinessData] = useState([]);
  const [modesData, setModesData] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profile, setProfile] = useState([]);
  const [inviteCount, setInviteCount] = useState(0);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const hamburger = document.getElementById("hamburger_icon");
      const itemsContainer = document.getElementById("nav_items_container");

      if (
        hamburger &&
        !hamburger.contains(event.target) &&
        itemsContainer &&
        !itemsContainer.contains(event.target)
      ) {
        setIsActive(false);
      }
    };

    const handleScroll = () => {
      if (screenWidth < 1230) setIsActive(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [screenWidth]);

  const getUserInfo = async () => {
    try {
      const res = await getData({
        endpoint: "business/getBusinessByID",
        token: cookies.t,
        params: {
          id: cookies.bid,
        },
      });
      setProfile(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getAllBusiness = async () => {
    try {
      const res = await getData({
        endpoint: "businessDashboard/getBusinessDetails",
        token: cookies.t,
        params: {
          email: atob(cookies.email),
        },
      });
      if (res && res?.data) {
        const cookiebid = cookies?.bid;
        const modesData = res?.data
          ?.filter((item) => item?.value === cookiebid)
          ?.flatMap((item) =>
            item?.modes?.map((mode) => ({
              label: mode,
              value: mode,
            }))
          );

        if (modesData?.length === 0) {
          const isBusinessExist = res?.data?.filter(
            (item) => item?.value === cookiebid
          );
          if (
            isBusinessExist &&
            isBusinessExist?.length === 0 &&
            res?.data?.length > 0
          ) {
            setCookies("bid", res?.data?.[0]?.value, {
              path: "/",
              expires: expitationTime(),
            });
            // setCookies("mode", btoa(res?.data?.[0]?.modes?.[0]), {
            //   path: "/",
            //   expires: expitationTime(),
            // });
            const modesData = res?.data
              ?.filter((item) => item?.value === res?.data?.[0]?.value)
              ?.flatMap((item) =>
                item?.modes?.map((mode) => ({
                  label: mode,
                  value: mode,
                }))
              );
            setModesData(modesData);
          }
        } else {
          setModesData(modesData);
          setBusinessData(res?.data);
          const getModes = res?.data?.filter(
            (item) => item?.value === cookies?.bid
          )?.[0]?.modes;
          if (getModes && getModes?.length > 0) {
            if (!getModes.includes(atob(cookies?.mode))) {
              setCookies("mode", btoa(getModes?.[0]), {
                path: "/",
                expires: expitationTime(),
                // secure: true,
              });
            }
          }
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getInviteCount = async () => {
    try {
      const response = await getData({
        endpoint: "businessDashboard/getAllInvites",
        params: { email: atob(cookies.email) },
        token: cookies.t,
      });
      const data = response?.data;
      if (data) {
        setInviteCount(data?.length);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getUserInfo();
    getAllBusiness();
    getInviteCount();
  }, []);

  const handleMenuOpen = (setter) => (event) => {
    setter(event.currentTarget);
  };

  const handleMenuClose = (setter) => () => {
    setter(null);
  };

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  const removeActive = () => {
    setIsActive(false);
  };

  const isLinkActive = (path) => {
    return location.pathname.startsWith(path) ? styles.active_nav : "";
  };

  const employeeOldRoutes = {
    ehome: {
      name: "Dashboard",
      icon: <Dashboard />,
    },
    Bills: {
      name: "Bills",
      icon: <Payments />,
    },
    ereports: {
      name: "Reports",
      icon: <ReportsIcon />,
    },
    myInvites: {
      name: "My Invites",
      icon: <ReportsIcon />,
    },
  };

  const decodedValue = decodeCookieValue(cookies.va);

  let routeKeys = Object.keys(employeeOldRoutes);

  if (decodedValue === "true") {
    routeKeys = [...routeKeys.slice(0, 1), "employees", ...routeKeys.slice(1)];
  }

  const employeeRoutes = routeKeys.reduce((acc, key) => {
    if (key === "employees") {
      acc[key] = {
        name: "Employees",
        icon: <Broker />,
      };
    } else {
      acc[key] = employeeOldRoutes[key];
    }
    return acc;
  }, {});

  return (
    <div className={styles.navbar_container}>
      {isModalOpen && (
        <LogoutModal
          isActive={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <nav className={styles.navbar}>
        {/* Logo */}

        <div className="d-flex gap-1 align-items-center">
          <div
            className={`${styles.hamburger} ${isActive ? styles.active : ""}`}
            id="hamburger_icon"
            onClick={toggleActiveClass}
          >
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
            {inviteCount > 0 && <div className={styles.count_indicator}></div>}
          </div>

          <div
            className={styles.icon}
            onClick={() => navigate("/dashboard/ehome")}
          >
            {profile?.logo && profile?.logo !== "null" ? (
              <img
                src={profile?.logo}
                alt="Logo"
                className="open-header-img"
                onClick={() => navigate("/dashboard")}
              />
            ) : (
              <CompanyLogo />
            )}
          </div>
        </div>

        <ul
          className={` ${styles.navMenu} ${isActive ? styles.active : ""}`}
          id="nav_items_container"
        >
          <>
            {Object.entries(employeeRoutes).map(([path, { name, icon }]) => (
              <li key={path} onClick={removeActive}>
                <NavLink
                  to={path}
                  className={`${styles.navLink} ${isLinkActive(
                    `/dashboard/${path}`
                  )}`}
                >
                  {icon}
                  {name}
                  <span
                    className={
                      name === "My Invites" && inviteCount > 0
                        ? styles.count_sidebar
                        : ""
                    }
                  >
                    {name === "My Invites" && inviteCount > 0
                      ? inviteCount
                      : ""}
                  </span>
                </NavLink>
              </li>
            ))}
          </>
        </ul>
        <div className="d-flex gap-3">
          {modesData && modesData.length > 1 && (
            <MyDropdown
              data={modesData}
              selectedValue={cookies?.mode ? atob(cookies?.mode) : ""}
              onChange={(option) => {
                setCookies("mode", btoa(option), {
                  path: "/",
                  expires: expitationTime(),
                  // secure: true,
                });
                window.location.reload();
              }}
              // type={"Country"}
              placeholder={"Select Mode"}
              // classes={`Dropdowm_switching_console`}
              // openUpwards={true}
            />
          )}
          {businessData && businessData.length > 1 && (
            <MyDropdown
              data={businessData}
              selectedValue={cookies?.bid}
              placeholder={" Select Business"}
              onChange={(option) => {
                setCookies("bid", option, {
                  path: "/",
                  expires: expitationTime(),
                });
                let filterModes = businessData
                  ?.map((item) => {
                    if (item?.value === option) {
                      setCookies("role", btoa(item?.details?.[0]?.role_id), {
                        path: "/",
                        expires: expitationTime(),
                      });
                      setCookies(
                        "group_id",
                        item?.details?.[0]?.role_group_id,
                        {
                          path: "/",
                          expires: expitationTime(),
                        }
                      );
                      setCookies("iso", item?.details?.[0]?.is_owner, {
                        path: "/",
                        expires: expitationTime(),
                      });
                      if (!item?.modes?.includes(atob(cookies?.mode))) {
                        if (item?.modes?.length > 0) {
                          setCookies("mode", btoa(item?.modes?.[0]), {
                            path: "/",
                            expires: expitationTime(),
                          });
                        }
                      }
                    }
                  })
                  .flat();
                cookies?.mode && atob(cookies?.mode) === "Business"
                  ? navigate("/dashboard/home")
                  : navigate("/dashboard/ehome");

                window.location.reload();
              }}
              // type={"Country"}
              // classes={`Dropdowm_switching_console ${
              //   businessData.length > 1 ? "adjust-down-drop" : ""
              // }`}
              // openUpwards={fa}
            />
          )}
          <div className={styles.left_side}>
            <div onClick={handleMenuOpen(setProfileAnchorEl)}>
              <CircleAvatar name={decodeCookieValue(cookies.un)} />
            </div>
          </div>
        </div>

        <Menu
          anchorEl={profileAnchorEl}
          open={Boolean(profileAnchorEl)}
          onClose={handleMenuClose(setProfileAnchorEl)}
          PaperProps={{
            style: {
              width: "200px",
              borderRadius: "16px",
              marginTop: "20px",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              navigate(`/dashboard/emode/edit-profile`);
              setProfileAnchorEl(null);
            }}
          >
            Edit Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setProfileAnchorEl(null);
            }}
          >
            <span style={{ color: "#BE0F00" }}>Logout</span>
          </MenuItem>
        </Menu>
      </nav>
    </div>
  );
};

export default Navbar;
