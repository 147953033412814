import "./sidebar.scss";
import Dashboard from "../../icons/dashboard";
import CustomNavLink from "./CustomNavLink";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import useScreenWidth from "../../../hooks/useScreenwidth";
import CompleteLogo from "../../../assets/images/WEchartered_Logo.jpg";
import Configuration from "../../icons/Configuration";
import { useCookies } from "react-cookie";
import LeftArrow from "../../icons/LeftArrow";
import { Menu, MenuItem } from "@mui/material";
import { capitalizeLetter } from "../../../helpers/capatalizeLetters";
import UpwardArrow from "../../icons/UpwardArrow";
import Bottomarrow from "../../icons/Bottomarrow";
import CircleAvatar from "../../atoms/CircleAvatar";
import LogoutModal from "../../atoms/modals/LogoutModal";
import { decodeCookieValue } from "../../../helpers/cookieHelper";
import MyDropdown from "../../atoms/MyDropdown";
import {
  switchingClient,
  switchingConsoles,
  switchingEmployee,
} from "../../../helpers/staticData";
import { expitationTime } from "../../../helpers/expirationTime";
import CompanyLogo from "../../icons/companyLogo";
import ShortCompantLogo from "../../icons/shortCompanyLogo";
import { useSelector } from "react-redux";
import Broker from "../../icons/Broker";
import Payments from "../../icons/Payments";
import { getData } from "../../../Services";
import { padding } from "@mui/system";
import ReportsIcon from "../../icons/reports";
import WesoftekLogo from "../../icons/wesoftekLogo";

const Sidebar = ({ isActive, setIsActive, sidebarRef }) => {
  const screenWidth = useScreenWidth();
  let accessData = useSelector((state) => state?.accessData?.accessData);
  // accessData = accessData && accessData.length > 0 ? accessData : undefined;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [swvalue, setSwValue] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profile, setProfile] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [modesData, setModesData] = useState([]);
  const [cookies, setCookies] = useCookies(["un", "ibu", "mode", "iso"]);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [inviteCount, setInviteCount] = useState(0);

  const handleExpandMenu = (menuIndex) => {
    setExpandedMenu((prev) => (prev === menuIndex ? null : menuIndex));
  };
  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getUserInfo = async () => {
    try {
      const res = await getData({
        endpoint: "business/getBusinessByID",
        token: cookies.t,
        params: {
          id: cookies.bid,
        },
      });
      setProfile(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getAllBusiness = async () => {
    try {
      const res = await getData({
        endpoint: "businessDashboard/getBusinessDetails",
        token: cookies.t,
        params: {
          email: atob(cookies.email),
        },
      });
      if (res && res?.data) {
        const cookiebid = cookies?.bid;
        const modesData = res?.data
          ?.filter((item) => item?.value === cookiebid)
          ?.flatMap((item) =>
            item?.modes?.map((mode) => ({
              label: mode,
              value: mode,
            }))
          );

        if (modesData?.length === 0) {
          const isBusinessExist = res?.data?.filter(
            (item) => item?.value === cookiebid
          );
          if (
            isBusinessExist &&
            isBusinessExist?.length === 0 &&
            res?.data?.length > 0
          ) {
            setCookies("bid", res?.data?.[0]?.value, {
              path: "/",
              expires: expitationTime(),
            });
            // setCookies("mode", btoa(res?.data?.[0]?.modes?.[0]), {
            //   path: "/",
            //   expires: expitationTime(),
            // });
            const modesData = res?.data
              ?.filter((item) => item?.value === res?.data?.[0]?.value)
              ?.flatMap((item) =>
                item?.modes?.map((mode) => ({
                  label: mode,
                  value: mode,
                }))
              );
            setModesData(modesData);
          }
        } else {
          setModesData(modesData);
          setBusinessData(res?.data);
          const getModes = res?.data?.filter(
            (item) => item?.value === cookies?.bid
          )?.[0]?.modes;
          if (getModes && getModes?.length > 0) {
            if (!getModes.includes(atob(cookies?.mode))) {
              setCookies("mode", btoa(getModes?.[0]), {
                path: "/",
                expires: expitationTime(),
                // secure: true,
              });
            }
          }
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const getInviteCount = async () => {
    try {
      const response = await getData({
        endpoint: "businessDashboard/getAllInvites",
        params: { email: atob(cookies.email) },
        token: cookies.t,
      });
      const data = response?.data;
      if (data) {
        setInviteCount(data?.length);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getUserInfo();
    getAllBusiness();
    getInviteCount();
  }, []);
  const isOwner = cookies.iso;

  const links = [
    ...(cookies &&
    cookies?.mode &&
    (atob(cookies?.mode) === "Employee" || atob(cookies?.mode) === "Client")
      ? [
          {
            to: "/dashboard/ehome",
            label: "Dashboard",
            icon: <Dashboard />,
            alternatePath: "/dashboard",
          },
        ]
      : []),
    ...((accessData &&
      cookies &&
      cookies?.mode &&
      atob(cookies?.mode) === "Business" &&
      (accessData?.["Dashboard"]?.["view"] ||
        accessData?.["Dashboard"]?.["delete"] ||
        accessData?.["Dashboard"]?.["export"] ||
        accessData?.["Dashboard"]?.["add/edit"])) ||
    (isOwner && cookies && cookies?.mode && atob(cookies?.mode) === "Business")
      ? [
          {
            to: "/dashboard/home",
            label: "Dashboard",
            icon: <Dashboard />,
            alternatePath: "/dashboard",
          },
        ]
      : []),
    ...(((accessData &&
      (accessData?.["Client Users"]?.["view"] ||
        accessData?.["Client Users"]?.["delete"] ||
        accessData?.["Client Users"]?.["export"] ||
        accessData?.["Client Users"]?.["add/edit"] ||
        accessData?.["Employee Users"]?.["view"] ||
        accessData?.["Employee Users"]?.["delete"] ||
        accessData?.["Employee Users"]?.["export"] ||
        accessData?.["Employee Users"]?.["add/edit"])) ||
      isOwner) &&
    cookies.mode &&
    (atob(cookies.mode) === "Business" || swvalue === "Business")
      ? [
          {
            label: "Users",
            icon: <Broker />,
            alternatePath: "/dashboard/employees",
            subLinks: [
              ...((accessData &&
                (accessData?.["Client Users"]?.["view"] ||
                  accessData?.["Client Users"]?.["delete"] ||
                  accessData?.["Client Users"]?.["export"] ||
                  accessData?.["Client Users"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/clients",
                      label: "Client Users",
                      icon: <Dashboard />,
                      alternatePath: "/dashboard/clients",
                    },
                  ]
                : []),
              ...((accessData &&
                (accessData?.["Employee Users"]?.["view"] ||
                  accessData?.["Employee Users"]?.["delete"] ||
                  accessData?.["Employee Users"]?.["export"] ||
                  accessData?.["Employee Users"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/employees",
                      label: "Employee Users",
                      icon: <Dashboard />,
                      alternatePath: "/dashboard/employees",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),

    ...(((accessData &&
      (accessData?.["Client Invoices"]?.["view"] ||
        accessData?.["Client Invoices"]?.["delete"] ||
        accessData?.["Client Invoices"]?.["export"] ||
        accessData?.["Client Invoices"]?.["add/edit"] ||
        accessData?.["Employee Invoices"]?.["view"] ||
        accessData?.["Employee Invoices"]?.["delete"] ||
        accessData?.["Employee Invoices"]?.["export"] ||
        accessData?.["Employee Invoices"]?.["add/edit"])) ||
      isOwner) &&
    cookies.mode &&
    (atob(cookies.mode) === "Business" || swvalue === "Business")
      ? [
          {
            label: "Invoices",
            icon: <Payments />,
            alternatePath: "/dashboard/employees",
            subLinks: [
              ...((accessData &&
                (accessData?.["Client Invoices"]?.["view"] ||
                  accessData?.["Client Invoices"]?.["delete"] ||
                  accessData?.["Client Invoices"]?.["export"] ||
                  accessData?.["Client Invoices"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/clientInvoice",
                      label: "Client Invoices",
                    },
                  ]
                : []),
              ...((accessData &&
                (accessData?.["Employee Invoices"]?.["view"] ||
                  accessData?.["Employee Invoices"]?.["delete"] ||
                  accessData?.["Employee Invoices"]?.["export"] ||
                  accessData?.["Employee Invoices"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/employeeInvoice",
                      label: "Employee Invoices",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),

    ...(cookies.mode &&
    (atob(cookies.mode) === "Employee" || atob(cookies.mode) === "Client") &&
    (swvalue === "Employee" || swvalue === "Client")
      ? [
          {
            to: "/dashboard/bills",
            label: "Bills",
            icon: <Payments />,
            alternatePath: "/dashboard/bills",
          },
        ]
      : []),
    ...(cookies &&
    cookies?.mode &&
    (atob(cookies?.mode) === "Employee" || atob(cookies?.mode) === "Client")
      ? [
          {
            to: "/dashboard/ereports",
            label: "Reports",
            icon: <ReportsIcon />,
            alternatePath: "/dashboard",
          },
        ]
      : []),
    ...((cookies &&
      cookies?.mode &&
      atob(cookies?.mode) === "Business" &&
      ((accessData &&
        (accessData?.["Employee Invoices"]?.["view"] ||
          accessData?.["Employee Invoices"]?.["delete"] ||
          accessData?.["Employee Invoices"]?.["export"] ||
          accessData?.["Employee Invoices"]?.["add/edit"])) ||
        (accessData &&
          (accessData?.["Client Invoices"]?.["view"] ||
            accessData?.["Client Invoices"]?.["delete"] ||
            accessData?.["Client Invoices"]?.["export"] ||
            accessData?.["Client Invoices"]?.["add/edit"])))) ||
    (isOwner && cookies && cookies?.mode && atob(cookies?.mode) === "Business")
      ? [
          {
            to: "/dashboard/reports",
            label: "Reports",
            icon: <ReportsIcon />,
            alternatePath: "/dashboard",
          },
        ]
      : []),
    {
      to: "/dashboard/myInvites",
      label: `My Invites`,
      icon: <ReportsIcon />,
      alternatePath: "/dashboard",
    },

    ...(((accessData &&
      (accessData?.["Category Management"]?.["view"] ||
        accessData?.["Category Management"]?.["delete"] ||
        accessData?.["Category Management"]?.["export"] ||
        accessData?.["Category Management"]?.["add/edit"] ||
        accessData?.["Client Management"]?.["view"] ||
        accessData?.["Client Management"]?.["delete"] ||
        accessData?.["Client Management"]?.["export"] ||
        accessData?.["Client Management"]?.["add/edit"] ||
        accessData?.["User Management"]?.["view"] ||
        accessData?.["User Management"]?.["delete"] ||
        accessData?.["User Management"]?.["export"] ||
        accessData?.["User Management"]?.["add/edit"])) ||
      isOwner) &&
    cookies.mode &&
    (atob(cookies.mode) === "Business" || swvalue === "Business")
      ? [
          {
            label: "Settings",
            icon: <Configuration />,
            alternatePath: "/dashboard/setting",
            subLinks: [
              ...((accessData &&
                (accessData?.["User Management"]?.["view"] ||
                  accessData?.["User Management"]?.["delete"] ||
                  accessData?.["User Management"]?.["export"] ||
                  accessData?.["User Management"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/setting/user-management",
                      label: "User Management",
                    },
                  ]
                : []),
              ...((accessData &&
                (accessData?.["Client Management"]?.["view"] ||
                  accessData?.["Client Management"]?.["delete"] ||
                  accessData?.["Client Management"]?.["export"] ||
                  accessData?.["Client Management"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/clientManagement",
                      label: "Client Management",
                    },
                  ]
                : []),
              ...((accessData &&
                (accessData?.["Expense Categories"]?.["view"] ||
                  accessData?.["Expense Categories"]?.["delete"] ||
                  accessData?.["Expense Categories"]?.["export"] ||
                  accessData?.["Expense Categories"]?.["add/edit"])) ||
              isOwner
                ? [
                    {
                      to: "/dashboard/setting/category-management",
                      label: "Expense Categories",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ];

  // const memoizedSetIsActive = useCallback((newIsActive) => {
  //  setIsActive (newIsActive);
  // }, []);

  // useEffect(() => {
  //   memoizedSetIsActive(screenWidth >= 1233);
  // }, [screenWidth, memoizedSetIsActive]);
  const handleSideBar = () => {
    // if (screenWidth < 1233) setIsActive(false);
  };
  useEffect(() => {
    if (cookies.mode) {
      setSwValue(atob(cookies.mode));
    }
  }, [cookies.mode]);
  console.log("Accres Data :", accessData);

  return (
    <>
      <div className={`sidebar ${!isActive ? "closed" : ""}`} ref={sidebarRef}>
        <div
          className="header"
          style={{
            padding:
              profile?.logo && profile?.logo !== "null"
                ? "10px 15px"
                : "1.5rem 0 1.5rem 1.5rem",
          }}
        >
          {/* {isActive ? (
            // <img
            //   src={CompleteLogo}
            //   alt="Logo"
            //   className="open-header-img"
            //   onClick={() => navigate("/dashboard")}
            // />
            // <Logo1 />
            // <img src={Logo1} alt="Logo" />
            <CompanyLogo />
          ) : (
            // <> </>
            <ShortCompantLogo />
            // <img
            //   src={CompleteLogo}
            //   alt="Logo"
            //   className="closed-header-img"
            //   onClick={() => navigate("/dashboard")}
            // />
          )} */}

          {profile?.logo && profile?.logo !== "null" ? (
            <img
              src={profile?.logo}
              alt="Logo"
              className="open-header-img"
              onClick={() => navigate("/dashboard")}
            />
          ) : (
            <CompanyLogo />
          )}
        </div>
        <div className="ul">
          <ul>
            {links.map((link, index) => (
              <CustomNavLink
                key={index}
                to={link.to}
                label={link.label}
                icon={link.icon}
                count={inviteCount}
                handleSideBar={handleSideBar}
                isSideBarActive={isActive}
                setIsSideBarActive={setIsActive}
                isExpanded={expandedMenu === index}
                onExpand={() => handleExpandMenu(index)}
              >
                {link.subLinks?.map((subLink, subIndex) => (
                  <CustomNavLink
                    key={subIndex}
                    to={subLink.to}
                    label={subLink.label}
                    handleSideBar={handleSideBar}
                    isSideBarActive={isActive}
                  />
                ))}
              </CustomNavLink>
            ))}
          </ul>
          <div className="d-flex flex-column gap-2">
            {modesData && modesData.length > 1 && (
              <MyDropdown
                data={modesData}
                selectedValue={cookies?.mode ? atob(cookies?.mode) : ""}
                onChange={(option) => {
                  setSwValue(option);
                  setCookies("mode", btoa(option), {
                    path: "/",
                    expires: expitationTime(),
                    // secure: true,
                  });
                  window.location.reload();
                }}
                // type={"Country"}
                placeholder={"Select Mode"}
                classes={`Dropdowm_switching_console`}
                openUpwards={true}
              />
            )}
            {businessData && businessData.length > 1 && (
              <MyDropdown
                data={businessData}
                selectedValue={cookies?.bid}
                placeholder={" Select Business"}
                onChange={(option) => {
                  setCookies("bid", option, {
                    path: "/",
                    expires: expitationTime(),
                  });
                  let filterModes = businessData
                    ?.map((item) => {
                      if (item?.value === option) {
                        setCookies("role", btoa(item?.details?.[0]?.role_id), {
                          path: "/",
                          expires: expitationTime(),
                        });
                        setCookies(
                          "group_id",
                          item?.details?.[0]?.role_group_id,
                          {
                            path: "/",
                            expires: expitationTime(),
                          }
                        );
                        setCookies("iso", item?.details?.[0]?.is_owner, {
                          path: "/",
                          expires: expitationTime(),
                        });
                        if (!item?.modes?.includes(atob(cookies?.mode))) {
                          if (item?.modes?.length > 0) {
                            setCookies("mode", btoa(item?.modes?.[0]), {
                              path: "/",
                              expires: expitationTime(),
                            });
                          }
                        }
                      }
                    })
                    .flat();
                  cookies?.mode && atob(cookies?.mode) === "Business"
                    ? navigate("/dashboard/home")
                    : navigate("/dashboard/ehome");

                  window.location.reload();
                }}
                // type={"Country"}
                classes={`Dropdowm_switching_console ${
                  businessData.length > 1 ? "adjust-down-drop" : ""
                }`}
                openUpwards={true}
              />
            )}

            <div
              className="profile mb-0"
              // style={{ bottom: isActive && cookies?.ibu ? "70px" : "0" }}
              onClick={handleProfileMenu}
            >
              <div className="d-flex gap-2 align-items-center">
                <CircleAvatar
                  color="#DEE3E7"
                  iconColor="black"
                  name={capitalizeLetter(decodeCookieValue(cookies.un))}
                />
                {isActive && (
                  <div>
                    <div
                      className="profile-span"
                      title={capitalizeLetter(decodeCookieValue(cookies.un))}
                    >
                      {capitalizeLetter(decodeCookieValue(cookies.un))}
                    </div>
                  </div>
                )}
              </div>

              {isActive && (anchorEl ? <UpwardArrow /> : <Bottomarrow />)}
            </div>
            <a
              className="powered-by"
              href="https://wesoftek.com/"
              target="_blank"
            >
              <div className="powered-by-span">Powered By</div> <WesoftekLogo />
            </a>
          </div>
        </div>

        {/* {isActive && cookies?.ibu && (
          <MyDropdown
            data={modesData}
            selectedValue={cookies?.mode ? atob(cookies?.mode) : ""}
            onChange={(option) => {
              setSwValue(option);
              setCookies("mode", btoa(option), {
                path: "/",
                expires: expitationTime(),
                // secure: true,
              });
            }}
            type={"Country"}
            classes={`Dropdowm_switching_console ${
              businessData.length > 1
                ? "adjust-up-drop-no"
                : "adjust-up-drop-yes"
            }`}
            openUpwards={true}
          />
        )}
        {businessData.length > 1 && (
          <MyDropdown
            data={businessData}
            selectedValue={cookies?.bid}
            onChange={(option) => {
              console.log("Option :", option);

              setCookies("bid", option, {
                path: "/",
                expires: expitationTime(),
              });
              let filterModes = businessData
                ?.map((item) => {
                  if (item?.value === option) {
                    if (!item?.modes?.includes(atob(cookies?.mode))) {
                      if (item?.modes?.length > 0) {
                        setCookies("mode", btoa(item?.modes?.[0]), {
                          path: "/",
                          expires: expitationTime(),
                        });
                      }
                    }
                  }
                })
                .flat();
              window.location.reload();
            }}
            type={"Country"}
            classes={`Dropdowm_switching_console ${
              businessData.length > 1 ? "adjust-down-drop" : ""
            }`}
            openUpwards={true}
          />
        )}

        <div
          className="profile mb-2"
          style={{ bottom: isActive && cookies?.ibu ? "70px" : "0" }}
          onClick={handleProfileMenu}
        >
          <div className="d-flex gap-2 align-items-center">
            <CircleAvatar
              color="#DEE3E7"
              iconColor="black"
              name={capitalizeLetter(decodeCookieValue(cookies.un))}
            />
            {isActive && (
              <div>
                <div
                  className="profile-span"
                  title={capitalizeLetter(decodeCookieValue(cookies.un))}
                >
                  {capitalizeLetter(decodeCookieValue(cookies.un))}
                </div>
              </div>
            )}
          </div>

          {isActive && (anchorEl ? <UpwardArrow /> : <Bottomarrow />)}
        </div> */}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              minWidth: "175px",
              borderRadius: "6px",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
              marginBottom: "40px",
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "bottom",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "bottom",
          }}
        >
          <MenuItem
            onClick={() => {
              navigate(
                cookies.mode && atob(cookies.mode) === "Business"
                  ? "/dashboard/edit-profile"
                  : "/dashboard/emode/edit-profile"
              );
              setAnchorEl(null);
            }}
            style={{ fontWeight: 500 }}
          >
            <span>Edit Profile</span>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setAnchorEl(null);
            }}
            style={{ fontWeight: 500 }}
          >
            <span style={{ color: "#BE0F00" }}>Logout</span>
          </MenuItem>
        </Menu>

        {isModalOpen && (
          <LogoutModal
            isActive={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </>
  );
};
export default Sidebar;
