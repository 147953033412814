const RejectIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path
        d="m23.121,6.151L17.849.878c-.567-.566-1.321-.878-2.121-.878h-7.456c-.801,0-1.554.312-2.121.879L.879,6.151c-.567.567-.879,1.32-.879,2.121v7.456c0,.801.312,1.554.879,2.121l5.272,5.272c.567.567,1.32.879,2.121.879h7.456c.8,0,1.554-.312,2.122-.879l5.271-5.272c.566-.567.879-1.32.879-2.121v-7.456c0-.801-.313-1.554-.879-2.121Zm-1.121,9.577c0,.263-.106.521-.293.707l-5.271,5.271c-.19.189-.442.294-.709.294h-7.456c-.263,0-.521-.107-.707-.293l-5.272-5.272c-.186-.187-.293-.444-.293-.707v-7.456c0-.263.107-.521.293-.707L7.565,2.293c.187-.186.444-.293.707-.293h7.456c.267,0,.519.104.708.293l5.271,5.272c.187.187.293.444.293.707v7.456Zm-5.561-6.753l-3.043,3.043,3.043,3.043-1.414,1.414-3.043-3.043-3.043,3.043-1.414-1.414,3.043-3.043-3.043-3.043,1.414-1.414,3.043,3.043,3.043-3.043,1.414,1.414Z"
        fill="#3B424A"
      />
    </svg>
  );
};
export default RejectIcon;
