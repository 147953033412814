const QueryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path
        d="m23.158,9.879h0l-2.158-2.158v-1.721c0-1.654-1.346-3-3-3h-1.721l-2.157-2.157c-1.134-1.133-3.11-1.133-4.243,0l-2.158,2.157h-1.721c-1.654,0-3,1.346-3,3v1.721l-2.158,2.158c-1.168,1.17-1.168,3.072,0,4.242l2.158,2.158v1.721c0,1.654,1.346,3,3,3h1.721l2.157,2.157c.567.566,1.32.879,2.122.879s1.555-.312,2.121-.879l2.158-2.157h1.721c1.654,0,3-1.346,3-3v-1.721l2.158-2.158c1.168-1.17,1.168-3.072,0-4.242Zm-1.415,2.828l-2.451,2.451c-.188.188-.293.441-.293.707v2.135c0,.552-.449,1-1,1h-2.135c-.265,0-.52.105-.707.293l-2.451,2.45c-.377.379-1.036.379-1.414,0l-2.451-2.45c-.188-.188-.442-.293-.707-.293h-2.135c-.551,0-1-.448-1-1v-2.135c0-.266-.105-.52-.293-.707l-2.45-2.451c-.39-.39-.39-1.024,0-1.414l2.451-2.451c.188-.188.293-.441.293-.707v-2.135c0-.552.449-1,1-1h2.135c.265,0,.52-.105.707-.293l2.451-2.45c.377-.379,1.036-.379,1.414,0l2.451,2.45c.188.188.442.293.707.293h2.135c.551,0,1,.448,1,1v2.135c0,.266.105.52.293.707l2.45,2.451c.39.39.39,1.024,0,1.414Zm-6.791-3.242c.223,1.272-.382,2.543-1.506,3.164-.188.103-.447.563-.447.876v.495c0,.553-.448,1-1,1s-1-.447-1-1v-.495c0-1.033.637-2.163,1.481-2.628.29-.159.595-.535.502-1.066-.069-.392-.402-.725-.793-.793-.306-.057-.602.021-.832.216-.228.19-.358.47-.358.767,0,.553-.448,1-1,1s-1-.447-1-1c0-.889.391-1.727,1.072-2.299.68-.572,1.578-.814,2.463-.653,1.209.211,2.204,1.205,2.417,2.417Zm-1.453,8.035c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Z"
        fill="#3B424A"
      />
    </svg>
  );
};

export default QueryIcon;
