const WesoftekLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="20"
      viewBox="0 0 200 40"
      fill="none"
    >
      <path
        d="M25.371 20.0444L21.8843 31.6542L18.342 20.0444H13.2975L9.79231 31.6542L6.26856 20.0444H0L6.67657 39.4065H12.3146L15.8383 28.5385L19.325 39.4065H24.963L31.6395 20.0444H25.371Z"
        fill="white"
      />
      <path
        d="M42.7486 39.9443C45.104 39.9443 47.1069 39.3879 48.7761 38.2752C50.4452 37.1624 51.5765 35.9013 52.1885 34.5103L46.7175 32.9154C45.9942 34.2321 44.6959 34.8812 42.8413 34.8812C40.078 34.8812 38.4645 33.7685 38.0008 31.543H52.3184C52.4111 30.968 52.4667 30.3004 52.4667 29.54C52.4667 26.6468 51.558 24.2544 49.7405 22.3627C47.923 20.471 45.4563 19.5251 42.3406 19.5251C39.2805 19.5251 36.8139 20.4895 34.9222 22.4183C33.0305 24.3471 32.0846 26.7952 32.0846 29.7625C32.0846 32.7855 33.0676 35.2336 35.0335 37.1253C36.9993 39.017 39.5587 39.9629 42.7301 39.9629L42.7486 39.9443ZM38.0194 27.9079C38.1677 27.0177 38.6128 26.2388 39.3732 25.5897C40.1336 24.9406 41.1166 24.6067 42.3035 24.6067C43.4905 24.6067 44.4734 24.922 45.2523 25.5711C46.0313 26.2202 46.4578 26.9992 46.5505 27.9079H38.0194Z"
        fill="white"
      />
      <path
        d="M63.7798 39.9443C66.3206 39.9443 68.3978 39.3323 70.0113 38.1268C71.6248 36.9213 72.4223 35.382 72.4223 33.5088C72.4223 32.6186 72.2739 31.8397 71.9772 31.172C71.6804 30.5044 71.2353 29.9665 70.6418 29.54C70.0484 29.1134 69.4549 28.7796 68.8614 28.52C68.268 28.2603 67.5261 28.0007 66.6359 27.7596L62.1663 26.517C61.4245 26.2573 61.0535 25.8679 61.0535 25.3671C61.0535 25.0148 61.2576 24.7366 61.6656 24.514C62.0736 24.2915 62.5558 24.1802 63.1122 24.1802C64.5773 24.1802 65.7457 24.7922 66.6174 25.9977L71.9401 24.4769C71.3095 22.9561 70.2153 21.7507 68.6389 20.8604C67.0625 19.9702 65.2449 19.5251 63.1678 19.5251C60.9608 19.5251 59.0691 20.1001 57.4927 21.2499C55.9163 22.3998 55.1188 23.8278 55.1188 25.5155C55.1188 28.1305 56.7509 29.9295 60.015 30.9124L64.6515 32.2848C65.857 32.6372 66.469 33.1379 66.469 33.7685C66.469 34.1579 66.2464 34.4918 65.8013 34.77C65.3562 35.0482 64.7628 35.1965 64.0395 35.1965C62.9452 35.1965 61.9994 34.9369 61.2205 34.4176C60.4415 33.8983 59.8851 33.2121 59.5513 32.3775L54.1359 33.9725C54.6366 35.6231 55.7865 37.0326 57.5669 38.2195C59.3473 39.4065 61.4245 39.9999 63.8169 39.9999L63.7798 39.9443Z"
        fill="white"
      />
      <path
        d="M84.9037 39.9443C87.9082 39.9443 90.3934 38.9614 92.3592 37.014C94.3251 35.0667 95.3081 32.6372 95.3081 29.7254C95.3081 26.8137 94.3251 24.3842 92.3592 22.4369C90.3934 20.4895 87.9082 19.5251 84.9037 19.5251C81.8993 19.5251 79.4141 20.5081 77.4482 22.4554C75.4824 24.4027 74.4994 26.8323 74.4994 29.744C74.4994 32.6557 75.4824 35.0852 77.4482 37.0326C79.4141 38.9799 81.8993 39.9629 84.9037 39.9629V39.9443ZM84.9037 34.4918C83.5313 34.4918 82.4371 34.0467 81.6211 33.1565C80.8051 32.2663 80.3971 31.1349 80.3971 29.744C80.3971 28.353 80.8051 27.2217 81.6211 26.3315C82.4371 25.4413 83.5313 24.9962 84.9037 24.9962C86.2762 24.9962 87.3704 25.4413 88.1864 26.3315C89.0024 27.2217 89.4104 28.353 89.4104 29.744C89.4104 31.1349 89.0024 32.2848 88.1864 33.175C87.3704 34.0652 86.2762 34.5103 84.9037 34.5103V34.4918Z"
        fill="white"
      />
      <path
        d="M106.287 18.672C106.287 17.9673 106.473 17.4109 106.844 17.0029C107.215 16.5949 107.715 16.3909 108.346 16.3909C109.143 16.3723 109.867 16.5021 110.516 16.7803L111.962 11.9584C110.72 11.402 109.199 11.1238 107.4 11.1238C105.1 11.1238 103.32 11.7914 102.077 13.1453C100.835 14.4992 100.204 16.3352 100.204 18.6535V20.0259H96.6805V25.33H100.204V39.3879H106.287V25.33H111.61V20.0259H106.287V18.672Z"
        fill="white"
      />
      <path
        d="M125.464 33.9354C124.833 34.2692 124.147 34.4361 123.405 34.4361C122.07 34.4361 121.402 33.7685 121.402 32.4146V25.3486H126.614V20.0444H121.402V13.9613H115.319V20.0444H112.315V25.3486H115.319V32.4146C115.319 34.6958 115.894 36.4762 117.044 37.7744C118.194 39.0726 119.9 39.7218 122.181 39.7218C123.758 39.7218 125.334 39.4065 126.929 38.7759L125.464 33.9354Z"
        fill="white"
      />
      <path
        d="M138.669 39.9443C141.024 39.9443 143.027 39.3879 144.696 38.2752C146.365 37.1624 147.497 35.9013 148.109 34.5103L142.638 32.9154C141.914 34.2321 140.616 34.8812 138.761 34.8812C135.998 34.8812 134.385 33.7685 133.921 31.543H148.238C148.331 30.968 148.387 30.3004 148.387 29.54C148.387 26.6468 147.478 24.2544 145.661 22.3627C143.843 20.471 141.376 19.5251 138.261 19.5251C135.201 19.5251 132.734 20.4895 130.842 22.4183C128.951 24.3471 128.005 26.7952 128.005 29.7625C128.005 32.7855 128.988 35.2336 130.954 37.1253C132.919 39.017 135.479 39.9629 138.65 39.9629L138.669 39.9443ZM133.939 27.9079C134.088 27.0177 134.533 26.2388 135.293 25.5897C136.054 24.9406 137.037 24.6067 138.224 24.6067C139.411 24.6067 140.393 24.922 141.172 25.5711C141.951 26.2202 142.378 26.9992 142.471 27.9079H133.939Z"
        fill="white"
      />
      <path
        d="M164.522 39.4065H171.365L163.539 28.3159L170.716 20.0444H163.724L157.919 27.129V11.6802H151.836V39.4065H157.919V34.8071L159.774 32.6928L164.522 39.4065Z"
        fill="white"
      />
      <g clip-path="url(#clip0_1_17)">
        <path d="M199.404 0V13.043L185.398 24.6885V12.1113" fill="#0D2EE7" />
        <path
          d="M199.403 0H185.397L171.391 11.6455L185.397 12.1113"
          fill="#3054EC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_17">
          <rect
            width="28.0119"
            height="24.6885"
            fill="white"
            transform="translate(171.391)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default WesoftekLogo;
