import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik, FormikHelpers, Field } from "formik";
import PasswordField from "../../../atoms/PasswordField";
import Error from "../../../atoms/Error";
import PasswordStrengthIndicator from "../../../atoms/PasswordStrengthIndicator";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import { postData } from "../../../../Services";
import * as yup from "yup";

const PassForm = ({ uid, expiry, token, type, mode, businessUserId }) => {
  const navigate = useNavigate();

  const [isPasswordValid, setIsPasswordValid] = useState(false);

  // Form
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "Please enter the correct format"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), ""], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const dataToSend = {
      password: values.password,
      user_id: uid,
      expiry: expiry,
      token: token,
    };

    try {
      let resultData;
      if (mode === "Business") {
        const response = await postData({
          endpoint: "businessAuth/createPassword",
          data: {}, // Add actual data if necessary
          params: { ...dataToSend, business_user_id: businessUserId },
        });
        resultData = response.data;
      } else {
        const response = await postData({
          endpoint: "employeeAuth/createPassword",
          data: {}, // Add actual data if necessary
          params: {
            ...dataToSend,
            mode: mode,
            business_user_id: businessUserId,
          },
        });
        resultData = response.data;
      }

      if (resultData.status) {
        navigate("/auth/login");
        toast.success(
          `Password ${
            resultData.type === "Reset" ? "Reset" : "Created"
          } Successfully`,
          {
            duration: 1000,
            style: confimationStyles,
          }
        );
      }
    } catch (error) {
      console.log("error :", error);
      toast.error(error?.error?.response?.data?.message, {
        style: errorStyles,
        duration: 1000,
      });
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        touched,
        errors,
        values,
        isValid,
        dirty,
        handleBlur,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-group mt-4">
            <>
              <PasswordField
                label="Password"
                id="password"
                name="password"
                value={values.password}
                onChange={(e) => setFieldValue("password", e.target.value)}
                onBlur={handleBlur}
                placeholder="Enter Password"
                className={`${
                  (touched.password || isSubmitting) && errors.password
                    ? "error-input"
                    : ""
                }`}
              />
              {(touched.password || isSubmitting) && errors.password && (
                <div className="error-input-message">{errors.password}</div>
              )}
            </>
          </div>

          <div className="form-group mt-2">
            <PasswordField
              label="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={(e) => setFieldValue("confirmPassword", e.target.value)}
              onBlur={handleBlur}
              placeholder="Enter Password"
              className={`${
                (touched.confirmPassword || isSubmitting) &&
                errors.confirmPassword
                  ? "error-input"
                  : ""
              }`}
            />
            {(touched.confirmPassword || isSubmitting) &&
              errors.confirmPassword && (
                <div className="error-input-message">
                  {errors.confirmPassword}
                </div>
              )}
          </div>

          <button
            className="btn btn-primary w-100 mt-2 mb-4"
            disabled={!isValid || !dirty || isSubmitting}
            type="submit"
          >
            {type === "reset" ? "Reset" : "Create"} Password
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <PasswordStrengthIndicator
            password={values.password}
            isPasswordValid={isPasswordValid}
            touched={touched.password}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PassForm;
